import Vue from 'vue'
import config from '@/config'

const SET_FOLDERS = 'SET_FOLDERS'
const SET_LOADING = 'SET_LOADING'

const moduleFolders = {
  namespaced: true,
  state: {
    folders: [],
    loading: false
  },
  mutations: {
    [SET_FOLDERS] (state, folders) {
      Vue.set(state, 'folders', folders)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    getFolders ({ commit }) {
      commit(SET_LOADING, true)
      const url = config.API_URL + '/folders'
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_FOLDERS, response.data.objects)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
  },
  getters: {
    folders: state => state.folders,
    loading: state => state.loading
  }
}

export default moduleFolders
