<template>
  <div class="home-actions">
    <div
      v-if="hasCreationPermission && activeRoute === 'lists'"
    >
      <!-- DELETE BUTTON -->
      <el-button
        v-if="selected.length"
        type="primary"
        size="medium"
        class="home-actions__button pull-right"
        @click="deleteAction"
      >
        {{ $t('BUTTON.DELETE') }}
      </el-button>
      <!-- NEW LIST BUTTON -->
      <el-button
        v-else
        type="primary"
        size="medium"
        class="home-actions__button pull-right"
        @click="setupAction"
      >
        {{ $t('BUTTON.NEW_LIST') }}
      </el-button>
    </div>

    <Filters
      :search="search"
      :search-placeholder="searchPlaceholder"
      :selected-filters="selectedFilters"
      @update-search="updateSearch"
      @unselect-filter="unselectFilter"
      @unselect-all="unselectAllFilters"
    >
      <!-- GROUPS -->
      <FilterCascade
        name="groups"
        :color="filterGroupsColor"
        :values="dataGroups.selected"
        :options="dataGroups.options"
        @updated="updateSelectedGroups"
      />
      <!-- Authors -->
      <FilterCascade
        name="authors"
        :color="filterAuthorsColor"
        :values="dataAuthors.selected"
        :options="dataAuthors.options"
        @updated="updateSelectedAuthors"
      />

      <!-- TYPES -->
      <FilterCascade
        v-if="displayListTypeFilter"
        name="types"
        :color="filterTypesColor"
        :values="dataTypes.selected"
        :options="dataTypes.options"
        :filterable="false"
        @updated="updateSelectedTypes"
      />
    </Filters>
  </div>
</template>

<script>
import { omit } from 'lodash-es'
import config from '@/config'
import { getFullName } from '@/filters'
import { debounce } from '@/directives'
import {
  filterGroups,
  filterAuthors,
  filterTypes
} from '@/mixins'
import { Filters, FilterCascade } from '@/components/Common/Form'
export default {
  name: 'HomeActions',
  components: { Filters, FilterCascade },
  directives: {
    debounce: debounce
  },
  mixins: [filterGroups, filterAuthors, filterTypes],
  props: {
    tabIndex: { type: String, required: true },
    onDelete: { type: Function, default: () => {} },
    onSetup: { type: Function, default: () => {} },
    secondaryAction: { type: Function, required: false, default: function () {} },
    secondaryActionLabel: { type: String, required: false, default: null },
    selected: { type: Array, default: () => [] },
    onFiltersChange: { type: Function, required: true },
    disabled: { type: Boolean, default: false },
    displayListTypeFilter: { type: Boolean, default: true },
    searchPlaceholder: {type: String, required: true}
  },
  data () {
    return {
      dataGroups: {
        options: [],
        selected: []
      },
      dataAuthors: {
        options: [],
        selected: []
      },
      dataTypes: {
        options: [],
        selected: []
      },
    }
  },
  computed: {
    selectedGroups: {
      get: function () {
        return this.$store.getters['lists/groupFilter']
      },
      set: function (groups) {
        this.$store.dispatch('lists/setGroupFilter', groups)
      }
    },
    selectedAuthors: {
      get () {
        return this.$store.getters['lists/authorFilter']
      },
      set (selectedAuthors) {
        this.$store.dispatch('lists/setAuthorFilter', selectedAuthors)
      }
    },
    selectedTypes: {
      get () {
        return this.$store.getters['lists/typeFilter']
      },
      set (types) {
        this.$store.dispatch('lists/setTypeFilter', types)
      }
    },
    search: {
      get () {
        return this.$store.getters['lists/search']
      },
      set (search) {
        this.$store.dispatch('lists/setSearch', search)
      }
    },
    contactListTypes() {
      const types = omit(config.LIST_TYPES, 'SUBMISSION')
      return Object.keys(types).map(t => ({ id: types[t], name: this.$t(`LISTS.TYPES_REF.${t}`) }) )
    },
    groups () {
      return this.$store.getters['groups/groups'] || []
    },
    groupsLoading () {
      return this.$store.getters['groups/loading']
    },
    users () {
      return this.$store.getters['users/users'] || []
    },
    selectedFilters () {
      return [
        ...this.dataGroups.selected.map((tag, index) => ({
          type: 'groups', color: this.filterGroupsColor, tag, index
        })),
        ...this.dataAuthors.selected.map((tag, index) => ({
          type: 'authors', color: this.filterAuthorsColor, tag, index
        })),
        ...this.dataTypes.selected.map((tag, index) => ({
          type: 'types', color: this.filterTypesColor, tag, index
        }))
      ]
    },
    hasCreationPermission () {
      return this.$store.getters['auth/hasPermission']('contactly:role:lists:w') ||
        this.$store.getters['auth/hasPermission']('contactly:role:lists:w-self')
    },
    activeRoute () {
      return this.$route.name
    }
  },
  mounted () {
    const selectedGroup = this.groups.length === 1 ? this.groups : []
    this.dataGroups = this.initSelectedGroups(this.groups, selectedGroup)
    this.dataAuthors = this.initSelectedAuthors(this.users)
    this.dataTypes = this.initSelectedTypes(this.contactListTypes)
    // init store based on query params
    this.initStoreFilters()
  },
  methods: {
    getFullName: getFullName,
    initStoreFilters () {
      this.$store.dispatch('lists/setTypeFilter', this.formatTypesCascader(this.dataTypes.selected))
      this.$store.dispatch('lists/setAuthorFilter', this.formatAuthorsCascader(this.dataAuthors.selected))
      this.$store.dispatch('lists/setGroupFilter', this.formatGroupsCascader(this.dataGroups.selected))
      if (this.$route.query.name) {
        this.$store.dispatch('lists/setSearch', this.$route.query.name)
      }
    },
    deleteAction: function () {
      return this.onDelete()
    },
    setupAction: function () {
      return this.onSetup()
    },
    updateSelectedGroups (values) {
      this.dataGroups.selected = values
      this.selectedGroups = this.formatGroupsCascader(values)
      this.$emit('filters-updated')
    },
    updateSelectedAuthors (values) {
      this.dataAuthors.selected = values
      this.selectedAuthors = this.formatAuthorsCascader(values)
      this.$emit('filters-updated')
    },
    updateSelectedTypes (values) {
      this.dataTypes.selected = values
      this.selectedTypes = this.formatTypesCascader(values)
      this.$emit('filters-updated')
    },
    updateSearch: function (s) {
      this.search = s
      this.$emit('search-updated')
    },
    unselectAllFilters () {
      this.dataGroups.selected = []
      this.dataAuthors.selected = []
      this.dataTypes.selected = []
      this.selectedGroups = []
      this.selectedAuthors = []
      this.selectedTypes = []
      this.$router.push({ path: this.$route.path }).catch(() => {})
      this.$emit('filters-updated')
    },
    unselectFilter (filter) {
      switch (filter.type) {
        case 'groups': {
          this.updateSelectedGroups(
            this.dataGroups.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
        case 'authors': {
          this.updateSelectedAuthors(
            this.dataAuthors.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
        case 'types': {
          this.updateSelectedTypes(
            this.dataTypes.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.home-actions {
  margin-bottom: 20px;
  .home-actions__filters,
  .home-actions__selected {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);

    & > div:last-child {
      flex-grow: 1;
      text-align: right;
    }
  }

  .home-actions__filters {
    ::v-deep .el-input-group__prepend {
      background-color: $--color-white;
      color: $--select-input-color;

      .el-select .el-input {
        width: 150px;

        .el-input__inner {
          padding-left: 10px;
        }
      }
    }

    ::v-deep .select--light {
      max-width: 200px;
      input {
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: right;
        white-space: nowrap;
      }
      &:first-child {
        margin-right: 40px;
      }
    }
  }

  .home-actions__selected {
    font-size: $--font-size-extra-small;
    text-transform: uppercase;

    ::v-deep .el-badge {
      margin-right: 5px;

      .el-badge__content {
        display: inline;
        border-radius: 2px !important;
      }
    }
  }
  .home-actions__button {
    padding: 13px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .home-actions {
    .home-actions__filters--right {
      display: none;
    }

    .home-container__button,
    .home-container__tabs {
      display: none;
    }

    .home-actions__filters,
    .home-actions__selected {
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      & > div:last-child {
        flex-grow: 1;
        text-align: right;
      }
    }

    ::v-deep .home-actions__filters {
      flex-direction: column-reverse;
      & > div:first-child {
        flex-grow: 1;
        text-align: center;
        padding: 10px;
      }
      & > div:last-child {
        text-align: center;
      }
      .select--light.type {
        margin-right: 0;
        margin-bottom: 20px;
        .el-input {
          &__inner {
            text-align: left;
            color: $--color-primary;
            border-bottom: 1px solid $--color-primary;
            padding: 5px;
            &::placeholder {
              color: $--color-primary;
            }
          }
          .el-icon-arrow-up {
            color: $--color-primary;
            font-weight: bold;
          }
        }
      }
      .select--light.author {
        display: none;
      }
    }
    .home-actions__button {
      display: none;
    }
  }
}
</style>
