import { orderBy as _orderBy } from 'lodash-es'
import { filterAuthorsColor } from '@/assets/styles/numberly-filters.scss'

function formatAuthorToOption(author) {
  return {
    label: `${author.first_name} ${author.last_name}`,
    value: { id: author.id, name: `${author.first_name} ${author.last_name}` }
  }
}
const filterAuthors = {
  data () {
    return {
      filterAuthorsColor: filterAuthorsColor
    }
  },
  methods: {
    /**
     * Get selected authors ids and init the author filter cascade
     */
    initSelectedAuthors (allAuthors, defaultSelectedAuthors = []) {
      let selectedAuthors = []
      let optionAuthors = allAuthors.map(formatAuthorToOption)
      let authorIds = defaultSelectedAuthors.map(g => g.id)

      if (this.$route.query.author) {
        authorIds = this.$route.query.author.split(',')
      }
      optionAuthors = _orderBy(optionAuthors, [author => author.label.toLowerCase()]).filter(opt => opt.label !== ' ')

      if (authorIds.length) {
        selectedAuthors = optionAuthors
            .filter(opt => authorIds.includes(opt.value.id))
            .map(g => [g.value])
      }

      return { options: optionAuthors, selected: selectedAuthors }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatAuthorsCascader (cascaderAuthorsSelection) {
      return cascaderAuthorsSelection.flat() || []
    }
  }
}

export default filterAuthors
