import { orderBy as _orderBy } from 'lodash-es'
import { filterTypesColor } from '@/assets/styles/numberly-filters.scss'

function formatTypeToOption(type) {
  return {
    label: type.name,
    value: { id: type.id, name: type.name }
  }
}

const filterTypes = {
  data () {
    return {
      filterTypesColor: filterTypesColor
    }
  },
  methods: {
    /**
     * Get selected types ids and init the type filter cascade
     */
    initSelectedTypes (allTypes, defaultSelectedTypes = []) {
      let selectedTypes = []
      let optionTypes = allTypes.map(formatTypeToOption)

      let typeIds = defaultSelectedTypes.map(g => g.id)
      if (this.$route.query.type) {
        typeIds = this.$route.query.type.split(',')
      }
      optionTypes = _orderBy(optionTypes, [type => type.label.toLowerCase()])

      if (typeIds.length) {
        selectedTypes = optionTypes
            .filter(opt => typeIds.includes(opt.value.id.toString()))
            .map(g => [g.value])
      }
      return { options: optionTypes, selected: selectedTypes }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatTypesCascader (cascaderTypesSelection) {
      return cascaderTypesSelection.flat() || []
    }
  }
}

export default filterTypes
