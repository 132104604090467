<template>
  <span>
    <span
      v-if="typeId === listTypes.ACTIVE"
      class="list__type-slot"
    >
      <i class="material-icons text-tertiary">sync</i>
      {{ $t('LISTS.TYPES_REF.ACTIVE') }}
    </span>
    <span
      v-else-if="typeId === listTypes.STATIC"
      class="list__type-slot"
    >
      <i class="material-icons text-secondary">list_alt</i>
      {{ $t('LISTS.TYPES_REF.STATIC') }}
    </span>
    <span
      v-else
      class="list__type-slot"
    >
      <i class="material-icons text-secondary">list</i>
      {{ $t('LISTS.TYPES_REF.SUBMISSION') }}
    </span>
  </span>
</template>

<script>
import config from '@/config'

export default {
  name: 'ListType',
  components: { },
  props: {
    typeId: { type: Number, required: true }
  },
  data () {
    return {
      listTypes: config.LIST_TYPES
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.list__type-slot {
  display: inline-flex;
  align-items: center;

  > i.material-icons {
    font-size: 18px;
    margin-right: 5px;
    color: $--color-primary;
  }
}
</style>
