<template>
  <div>
    <div class="contactability-card">
      <div class="contactability-card__header">
        <div class="contactability-card__header__description">
          <div class="contactability-card__header__description__title">
            {{ title }}
          </div>
          <div class="contactability-card__header__description__subtitle">
            {{ subtitle }}
          </div>
        </div>
        <div class="contactability-card__header__search">
          <el-input
            v-model="search"
            clearable
            :placeholder="placeholder"
            @input="onSearch"
            @clear="clearSearch"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
            />
          </el-input>
        </div>
      </div>
      <!-- table content -->
      <div class="contactability-card__table">
        <el-table
          v-if="data.length > 0 || loading"
          v-loading="loading"
          :data="data"
          fit
          :row-class-name="tableRowStyle"
          @sort-change="onSortChange"
        >
          <!-- Expanded row -->
          <el-table-column
            v-if="expandable"
            type="expand"
            show-overflow-tooltip
          >
            <template slot-scope="props">
              <div
                v-if="props.row.history.length"
                class="contactability-card__table__expand"
              >
                <div class="contactability-card__table__expand__col-name">
                  <div>
                    <el-tooltip
                      effect="dark"
                      placement="top"
                      :content="$t('CONTACT_DETAILS.CONTACTABILITY.LAST_3_OPTINS_TOOLTIP')"
                    >
                      <span>{{ $t("CONTACT_DETAILS.CONTACTABILITY.LAST_3_OPTINS") }}</span>
                    </el-tooltip>
                  </div>
                </div>
                <div class="contactability-card__table__expand__col-optin">
                  <div
                    v-for="(item, index) in props.row.history.slice(0, 3)"
                    :key="index"
                  >
                    <i
                      v-if="item.is_optin"
                      class="material-icons"
                    >done</i>
                    <i
                      v-else
                      class="material-icons"
                    >clear</i>
                  </div>
                </div>
                <div class="contactability-card__table__expand__col-date">
                  <div
                    v-for="(item, index) in props.row.history.slice(0, 3)"
                    :key="index"
                  >
                    {{ item.update_date | moment('LLL') }}
                  </div>
                </div>
              </div>
              <div
                v-else
                class="contactability-card__table__expand"
              >
                <div class="contactability-card__table__expand__no-data">
                  {{ $t("CONTACT_DETAILS.CONTACTABILITY.NO_HISTRY_DATA") }}
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- table data -->
          <el-table-column
            v-for="col in columns"
            :key="col.column"
            :label="col.label ? $t(`CONTACT_DETAILS.CONTACTABILITY.${col.label}`): null"
            :prop="col.column"
            :width="col.width"
            :sortable="col.sortable"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- NOTE with element-ui 2.15.2, the first element should exist in dom otherwise nothing is displayed -->
              <span v-show="col.column==='name'">
                {{ scope.row.name }}
              </span>
              <span v-if="col.column === 'update_date'">
                {{ scope.row.update_date | moment('LLL') }}
              </span>
              <span v-if="col.column === 'optin_name'">
                {{ scope.row.optin_name }}
              </span>
              <div v-if="col.column === 'is_optin'">
                <i
                  v-if="scope.row.is_optin"
                  class="material-icons contactability-card__table__icon-success"
                >done</i>
                <i
                  v-else
                  class="material-icons contactability-card__table__icon-fail"
                >clear</i>
              </div>
              <ListType
                v-if="col.column === 'type_id'"
                :type-id="scope.row.type_id"
              />
              <el-button
                v-if="col.column === 'go_to_list'"
                type="text"
                class="text-secondary"
                @click="goToList(scope.row.id)"
              >
                {{ $t('BUTTON.SEE_LIST') }}
              </el-button>
              <ul
                v-if="col.column === 'unsubscribe_actions'"
                class="unsubscribe-actions"
              >
                <li
                  v-if="hasAdminPermission && hasOptinManagementModule"
                  class="unsubscribe-actions__action"
                >
                  <el-tooltip
                    placement="bottom"
                    :content="scope.row.is_optin ? $t('BUTTON.UNSUBSCRIBE') : $t('BUTTON.SUBSCRIBE')"
                  >
                    <el-button
                      class="table-action-button"
                      @click.stop="subscribe({
                        optinId: scope.row.id,
                        optin: scope.row.is_optin,
                        name: scope.row.name
                      })"
                    >
                      <i class="material-icons">power_settings_new</i>
                    </el-button>
                  </el-tooltip>
                </li>
                <li class="unsubscribe-actions__action">
                  <el-tooltip
                    placement="bottom"
                    :content="$t('BUTTON.SEE_DETAILS')"
                  >
                    <el-button
                      class="table-action-button"
                      @click="subscribe({
                        optin: scope.row.is_optin,
                        optinId: scope.row.id,
                        name: scope.row.name,
                        isLogs: true
                      })"
                    >
                      <i class="material-icons">find_in_page</i>
                    </el-button>
                  </el-tooltip>
                </li>
              </ul>
              <div v-if="col.column === 'group'">
                <el-tooltip
                  effect="dark"
                  :content="scope.row.group.name"
                  placement="top"
                >
                  <AvatarHolder
                    :avatar-url="scope.row.group.logo_url"
                    :display-name="scope.row.group.name"
                    :width="25"
                    :height="25"
                    :font-size="8"
                  />
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <NoData
          v-else-if="data.length === 0 && !loading"
          type="alternative"
          :text="noDataText"
        />
        <el-pagination
          v-if="data.length > 0"
          layout="prev, pager, next"
          class="text-center"
          :total="total"
          :page-size="limit"
          :current-page.sync="page"
          @current-change="refreshData"
        />
      </div>
    </div>
    <ModalSubscribe
      :width="subscribeModal.isLogs ? '900px' : '500px'"
      :dialog-visible.sync="subscribeModal.show"
      :is-optin="subscribeModal.isOptin"
      :optin-id="subscribeModal.optinId"
      :is-logs="subscribeModal.isLogs"
      :title="subscribeModal.title"
      :name="subscribeModal.name"
      :contact-id="contact.id"
      :channel="channel"
      @optin-updated="optinUpdated"
    />
  </div>
</template>

<script>
import { NoData, AvatarHolder, ListType } from '@/components'
import ModalSubscribe from '@/components/Parts/ModalSubscribe'

export default {
  name: 'ContactabilityTable',
  components: {
    NoData,
    AvatarHolder,
    ModalSubscribe,
    ListType
  },
  props: {
    columns: { type: Array, default: () => [] },
    expandable: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    total: { type: Number, default: 0 },
    placeholder : { type: String, default: '' },
    title : { type: String, default: '' },
    subtitle : { type: String, default: '' },
    noDataText : { type: String, default: '' },
    channel: { type: String, default: '' }
  },
  data() {
    return {
      page: 1,
      limit: 10,
      search: '',
      orderBy: 'name',
      subscribeModal: {
        title: this.$t('SUBSCRIBE.TITLE'),
        channel: this.channel,
        show: false,
        isOptin: false,
        isLogs: false,
        optinId: null
      }
    }
  },
  computed: {
    hasOptinManagementModule () {
      const user = this.$store.getters['auth/user']
      return user.client.configs.contactly_optin_management
    },
    hasAdminPermission () {
      return this.$store.getters['auth/hasPermission']('contactly:user:optin:w')
    },
    contact () {
      return this.$store.getters['contacts/contact']
    }
  },
  methods: {
    clearSearch () {
      this.search = ''
    },
    optinUpdated (optId) {
      const optin = this.data.find(opt => opt.id === optId)
      optin.history.unshift({
        is_optin: optin.is_optin,
        update_date: optin.update_date
      })
      optin.is_optin = !optin.is_optin
      optin.update_date = moment().format()
      this.subscribeModal.show = false
      setTimeout(() => {
        this.refreshData()
      }, 600)
    },
    onSearch () {
      this.page = 1
      this.refreshData()
    },
    subscribe ({ optin, name, isLogs, optinId }) {
      this.subscribeModal.isOptin = optin
      this.subscribeModal.name = name
      this.subscribeModal.show = true
      this.subscribeModal.optinId = optinId
      this.subscribeModal.isLogs = isLogs
      this.subscribeModal.title = isLogs
        ? this.$t('SUBSCRIBE.TITLE_LOGS', { to: name })
        : optin
          ? this.$t('SUBSCRIBE.TITLE_UNSUB')
          : this.$t('SUBSCRIBE.TITLE_SUB')
    },
    goToList (listId) {
      const search = this.contact.email ? this.contact.email : this.contact.mobile
      this.$router.push({ name: 'rows', params: { listId: listId, search: search }})
    },
    refreshData () {
      const params = {
        page: this.page,
        search: this.search,
        orderBy: this.orderBy,
        limit: this.limit
      }
      this.$emit('refresh-data', params)
    },
    onSortChange (orderBy) {
      this.orderBy = orderBy.order === 'ascending' ? orderBy.prop : '-' + orderBy.prop
      this.refreshData()
    },
    tableRowStyle (object) {
      if (this.expandable && object.row.history.length === 0) {
        return 'no-expand'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.contactability-card {
  background: $--color-white;
  border: 2px solid $--color-light-gray;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  margin-top: 20px;

  .contactability-card__header {
    display: flex;
    .el-input {
      width: 300px;
      margin: auto;
    }
    .contactability-card__header__description {
      width: calc(100% - 300px);
      .contactability-card__header__description__title {
        font-size: 24px;
        padding: 10px 0;
      }
      .contactability-card__header__description__subtitle {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
    .contactability-card__header__search {
      text-align: right;
      padding: 10px 0;

    }
  }
  .material-icons {
    position: relative;
    margin-left: 5px;
    font-size: 15px;
    top: 4px;
  }
  .el-table__row {
    .unsubscribe-actions {
      opacity: 0;
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      &__action {
        margin-right: 10px;
        .el-button {
          border-radius: 2px;
          padding: 6px 10px;
          min-width: auto;
          width: auto;
          .material-icons {
            font-size: 18px;
            margin: 0;
            top: 0;
          }
        }
      }
    }
    &:hover .unsubscribe-actions {
      opacity: 1;
    }
  }

  .el-pagination {
    padding: 12px;
  }
  .contactability-card__table {
    border: solid 1px $--color-medium-gray;
    ::v-deep .el-table {
      padding: 10px 20px 0 20px;
      .no-expand .el-icon {
        display: none;
      }
      .no-expand .el-table__expand-icon {
        pointer-events: none;
      }

      .el-table__header, .el-table__body {
        width: 100% !important;
      }
    }
    .contactability-card__table__icon-success {
      color: $--icon-color-positive;
    }
    .contactability-card__table__icon-fail {
      color: $--icon-color-negative;
    }
    ::v-deep .el-table__expanded-cell {
      padding-right: 0;
      padding-bottom: 0;
    }
    .contactability-card__table__expand {
      display: flex;
      color: $--color-gray;
      padding: 0 10px;

      .contactability-card__table__expand__col-name {
        width: 53%;
        padding-right: 10px;
        div {
          padding-bottom: 20px;
        }
      }
      .contactability-card__table__expand__col-optin {
        width: 15%;
        div {
          padding-bottom: 20px;
        }
      }
      .contactability-card__table__expand__col-date {
        ::v-deep div {
          padding-bottom: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        overflow: hidden;
      }
      .contactability-card__table__expand__no-data {
        width: 100%;
        margin: auto;
      }
    }
  }
}
@media only screen and (max-width: $--mobile-width) {
  .contactability-card {
    margin-top: 30px;
    .contactability-card__header {
      display: block;
      .el-input {
        width: 100%;
      }
      .contactability-card__header__description {
        width: 100%;
      }
      .contactability-card__header__search {
        text-align: left;
        margin-bottom: 5px;
      }
    }
    ::v-deep .contactability-card__table__expand__col-name {
      min-width: 500px;
    }
    ::v-deep .contactability-card__table__expand__col-optin {
      min-width: 200px;
    }
  }
}
</style>
