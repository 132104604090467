<template>
  <div
    class="contact-details"
  >
    <!-- CONTACT DETAILS -->
    <div class="contact-details__wrapper">
      <!-- LEFT SIDEBAR -->
      <div class="contact-details__left">
        <ContactDetailsMain
          v-loading="loading"
        />
      </div>
      <!-- MAIN PANEL -->
      <div class="contact-details__views">
        <div class="contact-details__nav">
          <!-- Group selector -->
          <el-select
            v-model="selectedGroup"
            class="contact-details__nav__search"
            filterable
            clearable
            reserve-keyword
            :placeholder="$t('FILTER.GROUPS')"
            :loading="groupsLoading"
            :no-data-text="$t('MISC.NO_DATA')"
            :popper-append-to-body="false"
            @change="getContactKpis"
          >
            <el-option
              v-for="group in groups"
              :key="group.id"
              :label="group.name"
              :value="group.id"
            />
          </el-select>
          <el-menu
            mode="horizontal"
            class="contact-details__tabs"
            :class="{ active: true }"
            :default-active="activeIndex"
            @select="handleSelect"
          >
            <el-menu-item
              :index="availableRoutes[0]"
              :disabled="loading"
            >
              {{ $t('CONTACT_DETAILS.NAV.CONTACTABILITY') }}
            </el-menu-item>
            <el-menu-item
              v-if="hasCrmDataModule"
              :index="availableRoutes[1]"
              :disabled="loading"
            >
              {{ $t('CONTACT_DETAILS.NAV.DATA') }}
            </el-menu-item>

            <el-menu-item
              :index="availableRoutes[2]"
              :disabled="loading"
            >
              {{ $t('CONTACT_DETAILS.NAV.CAMPAIGNS') }}
            </el-menu-item>
          </el-menu>
        </div>
        <!-- CONTENT -->
        <div v-if="contact">
          <transition>
            <router-view :group-id="selectedGroup" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ContactDetailsMain
} from '@/components'

export default {
  name: 'ContactDetails',
  components: {
    ContactDetailsMain
  },
  data () {
    return {
      contact: {},
      availableRoutes: ['contactability', 'data', 'campaigns'],
      selectedGroup: null
    }
  },
  computed: {
    loading: function () {
      return this.$store.getters['contacts/loading']
    },
    hasCrmDataModule () {
      const user = this.$store.getters['auth/user']
      return user.client.configs.contactly_module_crm_data
    },
    activeIndex () {
      return this.availableRoutes.indexOf(this.$route.name) > -1 ?
        this.$route.name :
        this.availableRoutes[0]
    },
    groups () {
      return this.$store.getters['groups/groups'] || []
    },
    groupsLoading () {
      return this.$store.getters['groups/loading']
    },
  },
  created () {
    this.getContact()
    this.getContactKpis()
  },
  methods: {
    getContact () {
      const params = {
        channel: this.$route.params.channel,
        id: this.$route.params.contactId
      }
      this.$store.dispatch(`contacts/getContact`, params)
      .then((res) => {
        this.contact = res
      })
      .catch(err => {
        if ([404, 403, 400].includes(err.status)) {
          this.$message({
            showClose: true,
            center: true,
            duration: 0,
            message: this.$t('MISC.NO_SUCH_PAGE'),
          })
          this.$router.push({ path: '/' })
        } else {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        }
      })
    },
    getContactKpis() {
      const params = {
        channel: this.$route.params.channel,
        id: this.$route.params.contactId,
        groupId: this.selectedGroup
      }

      this.$store.dispatch(`contacts/getKpis`, params)
      .then((res) => {
        this.contactKpis = res
      })
      .catch(err => {
        if (err.status === 500) {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        }

      })
    },
    handleSelect(key) {
      if (key != this.$route.name ) {
        this.$router.push({ name: key })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.contact-details {
  overflow-x: hidden;
  color: $--text-dark;
  font-size: 12px;
  &__empty {
    background: $--color-white;
    padding: 2rem;
    border: 1px solid $--color-medium-gray;
  }
  &__wrapper {
    @include flexbox;
    @include align-items(stretch);
    min-height: calc(100% - #{$--header-height});
    width: 100%;
    height: auto;
  }
  &__left {
    left: 0;
    bottom:0;
    position: absolute;
    top: $--header-height;
    height: 100%;
    background: $--color-white;
    width: 350px;
    border-right: 1px solid $--color-light-gray;
  }
  &__views {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(stretch);
    position: relative;
    left: 340px;
    padding: 0 3rem 3rem;
    width: calc(100% - 340px);
    div:last-child {
      @include flex(1)
    }

  }
  &__nav {
    margin: 2rem 0 1rem 0;
    position: relative;
    min-height: 50px;
    .el-menu {
      margin-bottom: 0;
      .el-menu-item {
        &:hover, &:focus {
          background-color: transparent;
        }
      }
    }
    &__search {
      position: absolute;
      z-index: 1;
      top: 30px;
      right: 0;
      transform: translateY(-50%);
    }
  }


  &__tabs {
    width: 100%;
    &__dot {
      position: absolute;
      right: 10px;
      bottom: 4px;
      color: $--color-primary;
      font-size: 18px;
    }
  }
}

  /* For mobile: */
@media only screen and (max-width: $--mobile-width) {
  .contact-details {
    &__wrapper {
      display: block;
    }
    &__left {
      top: 0;
      position: relative;
      width: 100%;
      max-width: none;
      float: none;
      height: auto;
    }
    &__views {
      padding: 1rem;
      margin-bottom: 5rem;
      position: relative;
      left: 0;
      width: 100%;
    }

    &__nav {
      margin:  auto;
      .el-menu {
        margin-bottom: 2rem;
      }
      &__search {
      display: flex;
      position: relative;
      width: 180px;
      margin: 0 auto 1rem;
    }
    }
  }
}
</style>
