import Vue from 'vue'
import config from '@/config'

const SET_LIST_ACTIVITIES = 'SET_LIST_ACTIVITIES'
const SET_LOADING = 'SET_LOADING'

const moduleLists = {
  namespaced: true,
  state: {
    activities: null,
    loading: null
  },
  mutations: {
    [SET_LIST_ACTIVITIES] (state, activities) {
      Vue.set(state, 'activities', activities)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    getListActivities ({ commit }, params) {
      commit(SET_LOADING, true)
      const url = `${config.API_URL}/activity`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: params })
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_LIST_ACTIVITIES, response.data.objects)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
          .finally(() => {
            commit(SET_LOADING, true)
          })
      })
    },
    getActivity ({ commit }, {activityId, params} ) {
      commit(SET_LOADING, true)
      const url = `${config.API_URL}/activity/${activityId}`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {params: params})
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
          .finally(() => {
            commit(SET_LOADING, true)
          })
      })
    }
  },
  getters: {
    activities: state => state.activities,
    loading: state => state.loading
  }
}

export default moduleLists
