import { orderBy as _orderBy } from 'lodash-es'
import { filterChannelStatusColor } from '@/assets/styles/numberly-filters.scss'


function filterChannelStatusToOption(channel) {
  return {
    label: channel.name,
    value: {
      id: channel.id,
      name: channel.name
    },
    count: channel.status.length,
    children: channel.status.map(childrenStatus => ({
      label: childrenStatus.name,
      value: {
        id: childrenStatus.id,
        name: childrenStatus.name
      }
    }))
  }
}


const filterChannelStatus = {
  data () {
    return {
      filterChannelStatusColor: filterChannelStatusColor
    }
  },
  methods: {
    /**
     * Get selected EmailStatus ids and init the status filter cascade
     */
    initSelectedChannelStatus (allChannelStatus, defaultSelectedChannelStatus = []) {
      const mappedChannels = allChannelStatus.map(filterChannelStatusToOption)
      const optionChannelStatus = _orderBy(mappedChannels, [channel => channel.label.toLowerCase()])
      let selectedChannelStatus = []
      let channelStatusIds = defaultSelectedChannelStatus.map(g => g.id)

      if (this.$route.query.status) {
        channelStatusIds = this.$route.query.status && this.$route.query.status.split(',')
      }
      
      if (channelStatusIds.length) {
        for (var idx=0; idx < optionChannelStatus.length; idx++ ) {
          let channelStatus = optionChannelStatus[idx].children.filter(elem => channelStatusIds.includes(elem.value.id))
          channelStatus.forEach(status => selectedChannelStatus.push([optionChannelStatus[idx].value, status.value]))
        }
      }
      return { options: optionChannelStatus, selected: selectedChannelStatus }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatChannelStatusCascader (cascaderEmailStatusSelection) {
      return cascaderEmailStatusSelection.flat() || []
    }
  }
}

export default filterChannelStatus
