<template>
  <!-- Optins section -->
  <div class="contact-data">
    <div class="contact-data__title">
      {{ $t("CONTACT_DETAILS.DATA.TITLE", { channel: channel }) }}
    </div>
    <div class="contact-data__subtitle">
      {{ $t("CONTACT_DETAILS.DATA.SUBTITLE") }}
    </div>
    <div
      v-if="leftData.length > 0 || loading"
      v-loading="loading"
      class="contact-data__table"
    >
      <!-- Left table -->
      <el-table
        border
        :data="leftData"
        :show-header="false"
        :cell-style="cellStyle"
      >
        <el-table-column
          prop="label"
          width="150"
        />
        <el-table-column
          prop="value"
        >
          <template slot-scope="scope">
            <span v-if="isDate(scope.row.value)">
              {{ scope.row.value | moment('LLL') }}
            </span>
            <span v-else>
              {{ scope.row.value }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- Right table -->
      <el-table
        border
        :data="rightData"
        :show-header="false"
        :cell-style="cellStyle"
      >
        <el-table-column
          prop="label"
          width="150"
        />
        <el-table-column
          prop="value"
        >
          <template slot-scope="scope">
            <span v-if="isDate(scope.row.value)">
              {{ scope.row.value | moment('LLL') }}
            </span>
            <span v-else>
              {{ scope.row.value }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <NoData
      v-else-if="!loading"
      type="alternative"
      :text="$t('CONTACT_DETAILS.DATA.NO_DATA')"
    />
  </div>
</template>

<script>
import { orderBy } from 'lodash-es'
import { NoData } from '@/components'

export default {
  name: 'ContactData',
  components: { NoData },
    props: {
      groupId: { type: String, default: null },
  },
  data() {
    return {
      leftData: [],
      rightData: [],
    }
  },
  computed: {
    loading: function () {
      return this.$store.getters['contacts/dataLoading']
    },
    channel () {
      return this.capitalizeFirstLetter(this.$route.params.channel)
    },
  },
  created () {
    this.getData()
  },
  methods: {
    isDate (date) {
      if (!date) { return false }
      return moment(date.toString().replaceAll("Z",""), "YYYY-MM-DDTHH:mm:ss", true).isValid()
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    cellStyle (cell) {
      const isLabelColumn = cell.columnIndex % 2 == 0
      return isLabelColumn ? 'background-color: #F5F7FA; font-weight: 600; height: 50px': ''
    },
    getData () {
      const params = {
        channel: this.$route.params.channel,
        id: this.$route.params.contactId,
      }
      this.$store.dispatch('contacts/getData', params)
      .then((res) => {
        const orderedRes = orderBy(res, ['order'], ['asc'])
        const halfLength = Math.ceil(orderedRes.length / 2)

        this.leftData = orderedRes.slice(0, halfLength)
        this.rightData = orderedRes.slice(halfLength, orderedRes.length)

        // add empty row for visualization purpose
        if (this.rightData.length < this.leftData.length) {
          this.rightData.push({label: '', order: orderedRes.length + 1, value: ''})
        }
      })
      .catch(err => {
        if (err.status === 500) {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.contact-data {
  background: $--color-white;
  border: 2px solid $--color-light-gray;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  .contact-data__title {
    font-size: 24px;
    padding: 10px 0;
  }
  .contact-data__subtitle {
    font-size: 14px;
  }
  .contact-data__table {
    display: flex;
    margin-top: 20px;
    ::v-deep .el-table {
      .cell {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
@media only screen and (max-width: $--mobile-width) {
  .contact-data {
    margin-top: 30px;

    .contact-data__table {
      display: block;
    }
  }
}
</style>
