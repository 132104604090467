<template>
  <!-- STEP 3 EDITING COLUMNS-->
  <div
    v-if="step === 3.0"
    class="setup-columns"
  >
    <!-- NEED HELP MODAL -->
    <NeedHelpModal
      :dialog-visible.sync="showHelpModal"
      width="500px"
      :title="$t('SETUP.COLUMNS.NEED_HELP_TITLE')"
      :sections="columnsHelpSections"
    />
    <div class="setup-columns__title">
      {{ $t('SETUP.COLUMNS.TITLE') }}
    </div>
    <div class="setup-columns__description">
      <div>{{ $t('SETUP.COLUMNS.DESCRIPTION_1') }}</div>

      <div>{{ $t('SETUP.COLUMNS.DESCRIPTION_2') }}</div>
    </div>
    <el-alert
      v-show="missingPrimaryKeys"
      class="setup-columns__alert"
      :title="$t('SETUP.COLUMNS.MISSING_PRIMARY_KEYS')"
      type="error"
      show-icon
      center
      :closable="false"
    />
    <div v-if="list.errors">
      <div
        v-for="error in list.errors"
        :key="error.error"
      >
        <el-alert
          v-if="error.display_step === step"
          class="setup-columns__alert"
          :title="$t('SETUP.COLUMNS.' + error.error, error.additional_info)"
          type="error"
          show-icon
          center
          :closable="false"
        />
      </div>
    </div>

    <SetupColumnsList
      ref="setup-columns-list"
      :fields="list.fields"
      :list-id="list.id"
      :form-change="formChange"
      @loading="e => $emit('update:loading', e)"
    />
    <el-button
      type="text"
      class="need-help"
      @click="showHelpModal = true"
    >
      <i class="material-icons">info_outlined</i>
      <span class="text">{{ $t('SETUP.COLUMNS.HELP') }}</span>
    </el-button>
  </div>

  <!-- STEP 3.1 LOADING -->
  <div
    v-else-if="step === 3.1"
    class="setup-loading"
  >
    <LoadingFile class="setup-loading__logo" />
    <div class="setup-loading__title">
      {{ $t('SETUP.COLUMNS.PROCESSING_FILE_TITLE') }}
    </div>
    <div class="setup-loading__description">
      {{ $t('SETUP.COLUMNS.PROCESSING_FILE_DESCRIPTION') }}
    </div>
  </div>

  <!-- STEP 3.2 REPORTS -->
  <div
    v-else-if="insertionPreview && step === 3.2"
    class="setup-columns-preview"
  >
    <!-- NEED HELP MODAL -->
    <NeedHelpModal
      :dialog-visible.sync="showReportsHelpModal"
      width="500px"
      :title="$t('SETUP.COLUMNS.PREVIEW_IMPORT_NEED_HELP_TITLE')"
      :sections="insertionHelpSections"
    />
    <div class="setup-columns-preview__title">
      {{ $t('SETUP.COLUMNS.PREVIEW_TITLE') }}
    </div>
    <el-alert
      v-if="insertionPreview.analysed_sample < 1"
      class="setup-columns-preview__alert"
      type="warning"
      show-icon
      :title="$t('SETUP.COLUMNS.PREVIEW_IMPORT_ANALYSED_SAMPLE', { nb: insertionPreview.nb_duplicated + insertionPreview.nb_contacts})"
      :closable="false"
    />
    <div class="setup-columns-preview__reports">
      {{ $t('SETUP.COLUMNS.PREVIEW_IMPORT_CONTACT_LABEL') }}
      <span class="pull-right"> {{ insertionPreview.nb_contacts }} </span>
    </div>
    <el-alert
      v-if="insertionPreview.nb_duplicated"
      class="setup-columns-preview__alert"
      type="info"
      show-icon
      :title="$t('SETUP.COLUMNS.PREVIEW_IMPORT_DUPLICATED', { duplicated: insertionPreview.nb_duplicated})"
      :closable="false"
    />
    <el-button
      type="text"
      class="need-help"
      @click="showReportsHelpModal = true"
    >
      <i class="material-icons">info_outlined</i>
      <span class="text">{{ $t('SETUP.COLUMNS.HELP_IMPORT') }}</span>
    </el-button>
  </div>
</template>

<script>
import {
  SetupColumnsList,
  NeedHelpModal,
  LoadingFile
} from '..'
import config from '@/config'
export default {
  name: 'SetupEditColumns',
  components: {
    SetupColumnsList,
    NeedHelpModal,
    LoadingFile
  },
  props: {
    step: { type: Number, required: true },
    list: { type: Object, required: true },
    changeDetected: { type: Boolean, default: false }
  },
  data () {
    return {
      missingPrimaryKeys: false,
      loadingReportsInterval: null,
      insertionPreview: null,
      listTypes: config.LIST_TYPES,
      showHelpModal: false,
      showReportsHelpModal: false,
      columnsHelpSections: [{
        'content': this.$t('SETUP.COLUMNS.NEED_HELP_INFORMATION')
      }],
      insertionHelpSections: [{
        'content': this.$t('SETUP.COLUMNS.PREVIEW_IMPORT_NEED_HELP_INFORMATION')
      }]
    }
  },
  created () {
    if (this.step == 3.1 || this.step == 3.2) {
      this.loadPeriodicallyReports()
    }
  },
  beforeDestroy () {
    clearInterval(this.loadingReportsInterval)
  },
  methods: {
    confirmSubStep0 () {
      var columnsRef = this.$refs['setup-columns-list'].$refs
      var validatePromises = []
      for (var ref in columnsRef) {
        if (!ref.startsWith('setup-field-')) { continue }
        let ref_value = columnsRef[ref][0]
        validatePromises.push(ref_value.validate())
      }
      return validatePromises
    },
    confirmSubStep2() {
      let data = {
          'listId': this.list.id,
          'data': {'step': 4 }
        }
      this.$store.dispatch('lists/patchList', data)
      .then(() => {
        this.$emit('update:step', 4)
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
    },
    loadPeriodicallyReports () {
      this.loadReports()
      // ping every 30 seconds
      this.loadingReportsInterval = setInterval(function () {
        this.loadReports()
      }.bind(this), 30000)
    },
    loadReports () {
      this.$store.dispatch('lists/getInsertionPreview', this.list.id)
      .then((res) => {
        this.insertionPreview = res
        if (res.redirect_step != 3.1) {
          this.$emit('update:step', res.redirect_step)
          clearInterval(this.loadingReportsInterval)
        }
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
        clearInterval(this.loadingReportsInterval)
      })
    },
    handleNext (triggerSaving) {
      this.$emit('update:loading', true)
      switch(this.step) {
        case(3.0): {
          if (triggerSaving) {
            var promises = this.confirmSubStep0()
            const reducer = (accumulator, currentValue) => accumulator && currentValue
            // valid all the column form
            Promise.all(promises)
            .then((values) => {
              var allFormValid = values.reduce(reducer)
              // check if there is at least one primary key
              this.missingPrimaryKeys = !this.list.fields.some((field) => { return field.primary_key})

              // if everything is valid patch the list
              if (allFormValid && !this.missingPrimaryKeys) {
                let data = {
                  'listId': this.list.id,
                  'data': {'fields': this.list.fields, 'step': 3}
                }
                // next case
                this.$store.dispatch('lists/validateColumns', data)
                .then(() => {
                  this.$emit('update:step', 3.1)
                  this.loadPeriodicallyReports()
                })
                .catch(() => {
                  this.$notify({
                    title: this.$t('ERRORS.UNEXPECTED'),
                    message: this.$t('ERRORS.UNEXPECTED_DESC'),
                    type: 'error'
                  })
                })
              }
              this.$emit('update:loading', false)
            })
          } else {
            this.$emit('update:loading', false)
            this.$emit('update:step', 3.2)
            this.loadPeriodicallyReports()
          }
          break
        }
        case(3.2):
          if (triggerSaving) {
            this.confirmSubStep2()
          } else {
            this.$emit('update:step', 4)
          }
          this.$emit('update:loading', false)
          break
        default:
          return
      }
    },
    handlePrevious () {
      if (this.step == 3) {
        this.$emit('update:step', 2)
      }
      if (this.step == 3.2) {
        this.$emit('update:step', 3.0)
      }
    },
    save: function (triggerSaving) {
      if (triggerSaving) {
        return new Promise((resolve, reject) => {
          var promises = this.confirmSubStep0()
          const reducer = (accumulator, currentValue) => accumulator && currentValue
          // valid all the column form
          Promise.all(promises)
          .then((values) => {
            var allFormValid = values.reduce(reducer)
            // check if there is at least one primary key
            this.missingPrimaryKeys = !this.list.fields.some((field) => { return field.primary_key})

            // if everything is valid patch the list
            if (allFormValid && !this.missingPrimaryKeys) {
              let data = {
                'listId': this.list.id,
                'data': {'fields': this.list.fields, 'step': 3}
              }
              // next case
              this.$store.dispatch('lists/patchList', data)
              .then(() => {
                resolve(true)
              })
              .catch((error) => {
                reject(error)
              })
            } else {
              resolve(false)
            }
          })
        })
      }
    },
    formChange: function(field) {
      // country and mobile fields should be iso
      if (field.name_in_db == 'MOBILE') {
        let countryField = this.list.fields.find((f) => f.name_in_db === 'COUNTRY_CODE')
        if (countryField) {
          countryField.mandatory = field.mandatory
          countryField.primary_key = field.primary_key
        }
      }
      this.$emit('update:change-detected', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--setup-columns-width: 1000px;
$--setup-columns-preview-width: 500px;
.setup-columns {
  margin: 24px auto;
  width: $--setup-columns-width;
  font-size: 12px;

  &__title {
    word-break: break-word;
    color: $--color-gray;
    font-weight: 400;
  }

  &__description {
    word-break: break-word;
    margin: 12px 0px;
    color: $--color-gray;
  }

  &__alert {
    margin: 18px 0px;
    word-break: break-word;
  }

}
.setup-columns-preview {
  margin: 50px auto 24px;
  width: $--setup-columns-preview-width;

  &__title {
    color: $--text-dark;
    font-weight: 600;
    margin: 48px 0px 24px 0px;
    font-size: 16px;
  }

  &__reports {
    border-top: 1px solid $--color-medium-gray;
    border-bottom: 1px solid $--color-medium-gray;
    padding: 12px 0px;
    margin: 18px 0px;
    font-size: 12px;
  }

  &__alert {
    margin: 18px 0px;
    word-break: break-word;

    ::v-deep .el-alert__title {
      font-size: 12px !important;
    }
  }
}
</style>
