<template>
  <el-dialog
    custom-class="setup-list"
    :append-to-body="true"
    :fullscreen="true"
    :show-close="false"
    :visible="showModal"
    center
    @open="initStep"
    @close="onClose"
  >
    <!-- RESET LIST MODAL -->
    <Modal
      :dialog-visible.sync="showResetListModalOnSave"
      width="500px"
      :title="$t('SETUP.MODAL_RESET_TITLE')"
      :subtitle="$t('SETUP.MODAL_RESET_DESCRIPTION')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="updatingList || creatingList"
      type="danger"
      :action="handleExit"
      :action-args="'save'"
    />
    <Modal
      :dialog-visible.sync="showResetListModalOnNext"
      width="500px"
      :title="$t('SETUP.MODAL_RESET_TITLE')"
      :subtitle="$t('SETUP.MODAL_RESET_DESCRIPTION')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="updatingList || creatingList"
      type="danger"
      :action="next"
    />
    <!-- EXIT WITHOUT SAVING MODAL -->
    <Modal
      :dialog-visible.sync="showExitModalOnExit"
      width="500px"
      :title="$t('SETUP.MODAL_EXIT_TITLE')"
      :subtitle="$t('SETUP.MODAL_EXIT_DESCRIPTION')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="updatingList || creatingList"
      type="danger"
      :action="handleExit"
      :action-args="'exit'"
    />
    <Modal
      :dialog-visible.sync="showExitModalOnPrevious"
      width="500px"
      :title="$t('SETUP.MODAL_EXIT_TITLE')"
      :subtitle="$t('SETUP.MODAL_EXIT_DESCRIPTION')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="updatingList || creatingList"
      type="danger"
      :action="previous"
    />

    <!-- TITLE -->
    <div
      slot="title"
    >
      <el-button
        v-show="[1, 3.1, 3.2, 4, 5].indexOf(listStep) > -1"
        size="medium"
        :loading="gettingList"
        class="setup-list__exit-btn"
        @click="handleExit('exit')"
      >
        {{ $t('BUTTON.EXIT') }}
      </el-button>
      <el-dropdown
        v-show="([2, 3].indexOf(listStep) > -1) || (listStep === 4 && isActiveList)"
        class="setup-list__exit-btn"
        @command="handleExit"
      >
        <el-button
          size="medium"
          :loading="gettingList"
        >
          {{ $t('BUTTON.EXIT') }}
          <i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="save">
            {{ $t('BUTTON.SAVE_AND_EXIT') }}
          </el-dropdown-item>
          <el-dropdown-item command="exit">
            {{ $t('BUTTON.EXIT') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="modal-title">
        {{ list.name }}
      </div>
      <div class="modal-subtitle">
        {{ isActiveList ? $t('LISTS.TYPES_REF_FULL.ACTIVE') : $t('LISTS.TYPES_REF_FULL.STATIC') }}
      </div>
    </div>

    <!-- BODY -->
    <div
      class="setup-list__body"
    >
      <el-steps
        class="setup-list__body__steps"
        finish-status="success"
        :active="activeStep"
        align-center
      >
        <el-step
          :title="isActiveList ? $t('SETUP.ACTIVE_FILE_STEP') : $t('SETUP.STATIC_FILE_STEP')"
        />
        <el-step
          :title="$t('SETUP.FIELDS_STEP')"
        />
        <el-step
          :title="$t('SETUP.COLUMNS_STEP')"
        />
        <el-step
          v-if="isActiveList"
          :title="$t('SETUP.ACTIVE_SETUP_STEP')"
        />
        <el-step
          :title="$t('SETUP.ACTIVE_SUMMARY_STEP')"
        />
      </el-steps>

      <SetupActiveFile
        v-if="isActiveList && (listStep === 1)"
        ref="step-1"
        :list="list"
        :step.sync="listStep"
        :change-detected.sync="changeDetected"
      />
      <SetupStaticFile
        v-if="!isActiveList && 1 <= listStep && listStep < 2"
        ref="step-1"
        :list="list"
        :step.sync="listStep"
        :change-detected.sync="changeDetected"
      />
      <SetupFields
        v-if="listStep === 2"
        ref="step-2"
        :step.sync="listStep"
        :loading.sync="loadingSubmit"
        :change-detected.sync="changeDetected"
        :list="list"
      />
      <SetupEditColumns
        v-if="3 <= listStep && listStep < 4"
        ref="step-3"
        :step.sync="listStep"
        :loading.sync="loadingSubmit"
        :change-detected.sync="changeDetected"
        :list="list"
      />
      <SetupImport
        v-if="isActiveList && (listStep === 4)"
        ref="step-4"
        :list="list"
        :step.sync="listStep"
        :change-detected.sync="changeDetected"
      />
      <SetupSummary
        v-if="(listStep === 5 && isActiveList) || (listStep === 4 && !isActiveList)"
        :ref="isActiveList ? 'step-5' : 'step-4'"
        :list="list"
        :step.sync="listStep"
        :expiration-date-error="expirationDateError"
        @show-expiration-date="setExpirationDateShown"
        @set-expiration-date="setExpirationDate"
      />
    </div>
    <!-- FOOTER -->
    <span slot="footer">
      <el-button
        v-show="[2, 3, 3.2, 4, 5].indexOf(listStep) > -1"
        :disabled="updatingList || creatingList"
        size="medium"
        @click="previous()"
      >
        {{ $t('BUTTON.PREVIOUS') }}
      </el-button>
      <el-button
        v-show="[1, 2, 3, 3.2].indexOf(listStep) > -1 || (listStep === 4 && isActiveList)"
        :loading="updatingList || loadingSubmit"
        size="medium"
        type="primary"
        @click="next()"
      >
        {{ $t('BUTTON.CONFIRM') }}
      </el-button>
      <el-button
        v-show="listStep === 5 || (listStep === 4 && !isActiveList)"
        :loading="creatingList"
        size="medium"
        type="primary"
        @click="createAndActivateList()"
      >
        {{ isActiveList ? $t('BUTTON.ACTIVATE_LIST') : $t('BUTTON.CREATE_LIST') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  Modal,
  SetupActiveFile,
  SetupStaticFile,
  SetupFields,
  SetupEditColumns,
  SetupImport,
  SetupSummary,
} from '..'
import config from '@/config'
export default {
  name: 'ModalSetupList',
  components: { Modal, SetupActiveFile, SetupStaticFile, SetupFields,
                SetupEditColumns, SetupImport, SetupSummary },
  props: {
    showModal: { type: Boolean, required: true },
    list: { type: Object, required: true },
    updatingList: { type: Boolean, required: true }
  },
  data () {
    return {
      listTypes: config.LIST_TYPES,
      creatingList: false,
      gettingList: false,
      listStep: 1,
      changeDetected: false,
      showResetListModalOnNext: false,
      expirationDateError: false,
      expirationDateShown: false,
      showResetListModalOnSave: false,
      showExitModalOnExit: false,
      showExitModalOnPrevious: false,
      loadingSubmit: false
    }
  },
  computed: {
    isActiveList () {
      return this.list.type_id === this.listTypes.ACTIVE
    },
    activeStep () {
      return Math.floor(this.listStep - 1)
    }
  },
  methods: {
    next() {
      // show resetListModal if the user resets a crucial step
      if (this.changeDetected && this.list.step != this.listStep &&
          !this.showResetListModalOnNext && this.listStep != 4) {
        this.showResetListModalOnNext = true
        return
      }

      // trigger save if a change has been detected or the current step is the list's step
      var triggerSaving = this.changeDetected || this.list.step == this.listStep
      let ref = "step-" + Math.trunc(this.listStep)
      var promise = this.$refs[ref].handleNext(triggerSaving)
      this.showResetListModalOnNext = false
      if (promise) {
        promise.then((valid) => {
          this.changeDetected = !valid
        })
      } else {
        this.changeDetected = false
      }
      return
    },
    previous() {
      if (this.changeDetected && !this.showExitModalOnPrevious) {
        this.showExitModalOnPrevious = true
        return
      }
      let ref = "step-" + Math.trunc(this.listStep)
      this.changeDetected = false
      this.showExitModalOnPrevious = false
      this.$refs[ref].handlePrevious()
      return
    },
    createAndActivateList() {     
      let data = {
        'listId': this.list.id,
        'data': {
          'import': true
        }
      }
      if (this.expirationDateShown && !this.list.expiration_date) {
        this.expirationDateError = true
      } else if (!this.isActiveList && (!this.expirationDateShown || this.list.expiration_date)) {
        data.data.expiration_date = this.list.expiration_date
      }
      this.creatingList = true
      this.$store.dispatch('lists/activate', data)
      .then(() => {
        this.creatingList = false
        this.handleExit('exit')
      })
      .catch(() => {
        this.creatingList = false
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
    },
    handleExit: function (command) {
      switch (command) {
        case 'exit':
          if (this.changeDetected && !this.showExitModalOnExit) {
            this.showExitModalOnExit = true
            return
          }
          this.gettingList = true
          this.showExitModalOnExit = false
          this.changeDetected = false
          this.$store.dispatch('lists/getList', this.list.id)
          .then(() => {
            this.gettingList = false
            this.$emit('closeModal', `/lists/${this.list.id}/details`)
          })
          .finally(() => this.gettingList = false)
          break
        case 'save': {
          // show resetListModal if the user resets a step
          if (this.changeDetected && this.list.step != this.listStep && !this.showResetListModalOnSave) {
            this.showResetListModalOnSave = true
            return
          }
          var triggerSaving = this.changeDetected || this.list.step == this.listStep
          let ref = "step-" + Math.trunc(this.listStep)
          this.showResetListModalOnSave = false
          this.$refs[ref].save(triggerSaving)
          .then(() => {
            this.changeDetected = false
            this.handleExit('exit')
          })
          .catch((error) => {
            if (error) {
              this.$notify({
                title: this.$t('ERRORS.UNEXPECTED'),
                message: this.$t('ERRORS.UNEXPECTED_DESC'),
                type: 'error'
              })
            }
          })
          break
        }
      }
    },
    initStep: function() {
      this.changeDetected = false
      if (this.list.step) {
        this.listStep = this.list.step
      } else {
        this.listStep = 1
      }
      // fixes a bug with el-dialog
      // (body's scrollbar may be displayed, even if lock-scroll property is true)
      document.body.classList.add('hide-scroll')
    },
    onClose: function() {
      // adapt to the CSS transition's duration
      setTimeout(
        () => document.body.classList.remove('hide-scroll'),
        300
      )
    },
    setExpirationDateShown(shown) {
      this.expirationDateShown = shown
      if (this.expirationDateShown) {
        this.expirationDateError = false
        this.$store.commit('lists/SET_LIST_EXPIRATION_DATE', null)
      }
    },
    setExpirationDate(date) {
      this.expirationDateError = false
      this.$store.commit('lists/SET_LIST_EXPIRATION_DATE', date)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/element-variables.scss";
@import "@/assets/styles/variables.scss";
$--form-width: 1000px;

::v-deep .el-dropdown-menu {
  &__item:hover {
    background-color: $--color-primary-light-9 !important;
  }
}

::v-deep .setup-list {
  background-color: $--color-bg-light-gray;
  .el-form-item__label {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: $--color-text-regular;
    word-break: break-word;
  }

  .setup-list__body__content {
    margin-top:70px;
    display:flex;
    justify-content: center;
  }

  .el-dialog {
    &__header {
      padding-top: 48px;
    }
    &__footer {
      padding-top: 0;
    }
  }


  .title {
    margin: 10px;
    font-size: 36px;
    font-weight: 300;
  }
  .subtitle {
    margin: 10px;
    font-size: 21px;
    font-weight: 400;
  }

  .setup-list__body__steps .el-step__title {
    font-size: 12px !important;
  }

  .step-paragraph {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: $--color-dark-gray;
  }

  .step-subtitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  &__exit-btn {
    position: absolute;
    top: 36px;
    right: 44px;
    &.is-loading {
      position: absolute;
    }
  }

  &__body {
    margin: 0 auto;
    width: $--form-width;

    &__steps {
      margin-top: 12px;
      margin-bottom: 36px;
    }
  }

  .el-dialog__footer {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      margin: 0px 12px;
    }
  }
}

::v-deep .setup-loading {
  text-align: center;
  &__logo {
    margin: 24px 0px 12px 0px;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    margin: 44px 0px 24px 0px;
  }
  &__description {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
