<template>
  <div>
    <ModalSetupList
      v-if="list"
      key="setup-list"
      :list="list"
      :updating-list="updatingList"
      :show-modal="showModal"
      @closeModal="closeModal"
    />
    <ModalCreateList
      v-else
      key="create-list"
      :show-modal="showModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import {
  ModalCreateList,
  ModalSetupList
} from '..'
export default {
  name: 'SetupList',
  components: { ModalCreateList, ModalSetupList },
  props: {
    showModal: { type: Boolean, required: true },
    list: { type: Object, default: null },
    updatingList: { type: Boolean, default: false }
  },
  
  methods: {
    closeModal: function (path) {
      if (path.endsWith('/details')) {
        this.$router.push({ path: path }).catch(() => {})
        // wait for the router to be pushed
        debounce(() => {
          this.$emit('update:showModal', false)
        }, 100)()
      } else {
        this.$emit('update:showModal', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
