<template>
  <div>
    <!-- FILTERS AND ACTION BUTTONS -->
    <HomeActions
      tab-index="folders"
      :secondary-action="createFolder"
      :secondary-action-label="$t('BUTTON.NEW_FOLDER')"
      :on-filters-change="onFiltersChange"
      :filters="filters"
    />
  </div>
</template>

<script>
import { HomeActions } from '..'

export default {
  name: 'HomeFolders',
  components: { HomeActions },
  data () {
    return {
      filters: {
        group: [],
        name: null
      }
    }
  },
  created () {
    // extract query params
    this.extractQueryParams()
    // load folders
    this.getFolders()
  },
  methods: {
    createFolder () {
      return
    },
    onFiltersChange () {
      // append filters to query string
      let queryString = Object.assign({}, this.$route.query, this.filters)
      this.$router.push({ query: queryString })
      // if params have changed, reloading folders will be triggered by beforeRouteUpdate
    },
    getFolders () {
      return
    },
    extractQueryParams () {
      // extract filters from query string
      let queryParams = this.$route.query
      for (var key in this.filters) {
        if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
          this.filters[key] = queryParams[key]
        } else {
          this.filters[key] = null
        }
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.path != from.path || to.query != from.query) {
      // extract query params
      this.extractQueryParams()
      // reload folders
      this.getFolders()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
