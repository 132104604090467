<!-- eslint-disable vue/no-v-html -->
<template>
  <el-dialog
    custom-class="dialog-modal"
    :visible="dialogVisible"
    :width="width"
    :append-to-body="true"
    :show-close="true"
    @close="closeModal"
  >
    <div
      slot="title"
      class="dialog-modal__title"
    >
      <img
        alt="open-book-logo"
        :src="openBookLogo"
      >
      <div>
        {{ title }}
      </div>
    </div>
    <div class="dialog-modal__information">
      <!-- intro sentence -->
      <span>
        {{ $t('SETUP.COLUMN_REQUIREMENTS_INTRO') }}
      </span>
      <!-- link -->
      <el-button
        type="text"
        @click="showColumns()"
      >
        {{ $t('SETUP.COLUMN_REQUIREMENTS_LINK') }}
      </el-button>
      <!-- list -->
      <p v-html="$t('SETUP.COLUMN_REQUIREMENTS_ITEMS')" />
    </div>
  </el-dialog>
</template>

<script>
import openBook from '@/assets/img/open-book.svg'

export default {
  name: 'ModalColumnRequirements',
  props: {
    dialogVisible: { type: Boolean, default: false },
    width: { type: String, default: '30%'},
    title: { type: String, required: true },
  },
  data() {
    return {
      openBookLogo: openBook
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:dialogVisible', false)
    },
    showColumns () {
      this.$emit('showListColumns', true)
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
::v-deep .dialog-modal {
  padding: 24px 24px;
  .dialog-modal__title {
    text-align: center;
    color: $--color-primary;
    font-weight: 600;
  }
  .dialog-modal__information {
    word-break: break-word;
    .el-button {
      text-decoration: underline;
      color: $--color-text-regular;
      font-size: 14px;
    }
    ul {
      margin-top: 30px;
      text-align: justify;
    }

    ul li {
      margin: 10px 0;
    }
  }
}
</style>
