<template>
  <el-footer
    v-if="validStates.indexOf(activeRoute) > -1"
    class="app-footer"
    height="60"
  >
    <el-button-group>
      <el-button
        class="app-footer__item"
        :class="{'active': activeRoute === 'contacts' }"
        @click="$router.push('contacts', () => {})"
      >
        <i class="material-icons">person_search</i>
        {{ $t('CONTACT_CARDS.TITLE') }}
      </el-button>
      <el-button
        class="app-footer__item"
        :class="{'active': activeRoute === 'lists' }"
        @click="$router.push('lists', () => {})"
      >
        <i class="material-icons">list</i>
        {{ $t('CONTACT_LISTS.TITLE_MOBILE') }}
      </el-button>

      <el-button
        class="app-footer__item"
        :class="{'active': activeRoute === 'submission-lists' }"
        @click="$router.push('submission-lists', () => {})"
      >
        <i class="material-icons">assignment_turned_in</i>
        {{ $t('SUBMISSION_LISTS.TITLE_MOBILE') }}
      </el-button>
      <!-- <el-button
        class="app-footer__item"
        :class="{'active': activeRoute === 'imports' }"
        disabled
        @click="$router.push('imports', () => {})"
      >
        <i class="material-icons">flag</i>
        {{ $t('IMPORTS.TITLE_MOBILE') }}
      </el-button>
      <el-button
        class="app-footer__item"
        :class="{'active': activeRoute === 'exports' }"
        disabled
        @click="$router.push('exports', () => {})"
      >
        <i class="material-icons">cloud_download</i>
        {{ $t('EXPORTS.TITLE_MOBILE') }}
      </el-button> -->
    </el-button-group>
  </el-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  components: { },
  data () {
    return {
      state: null,
      validStates: ['lists', 'imports', 'exports', 'submission-lists', 'contacts']
    }
  },
  computed: {
    activeRoute () {
      return this.$route.name
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.el-footer.app-footer {
  display: none;
  width: 0;
}

@media only screen and (max-width: $--mobile-width) {
  .el-footer.app-footer {
    display: inline-block;
    width: 100%;
    height: $--footer-height;
    position: fixed;
    bottom: 0;
    z-index: 999;
    text-align: center;
    background-color: white;
    padding: 0px;
    overflow: hidden;

    .el-button-group {
      width: 100%;

      .el-button.app-footer__item {
        border-width: 1px 0 0 0;
        width: 33.33%;
        background-color: $--color-white;

        i.material-icons {
          display: block;
        }

        &:active,
        &:hover,
        &:focus {
          border-color: $--button-default-border-color;
          background-color: $--color-white;
        }

        &:active:not(.is-disabled),
        &:hover:not(.is-disabled),
        &:focus:not(.is-disabled) {
          color: $--button-default-font-color;
        }

        &.active {
          color: $--color-primary;
        }
      }
    }
  }
}
</style>
