<template>
  <div class="loading-spinner">
    <div v-loading="true" />
    <p
      v-if="title"
      class="text-center"
    >
      {{ title }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'LoadingSpinner',
  props: {
    title: { type: String, default: null },
    loading: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.loading-spinner {
  margin-top: 20px;
  p {
    color: $--color-primary;
    font-size: 12px;
  }
  ::v-deep .el-loading-mask {
    z-index: 1;
    background: none;
    position: relative;
    height: 60px;
  }
}
</style>
