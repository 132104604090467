import Vue from 'vue'
import config from '@/config'
import axios from 'axios'

const SET_CONTACT = 'SET_CONTACT'
const SET_KPIS = 'SET_KPIS'
const SET_LOADING = 'SET_LOADING'
const SET_STATUS_LOADING = 'SET_STATUS_LOADING'
const SET_BLACKLIST_LOADING = 'SET_BLACKLIST_LOADING'
const SET_OPTINS_LOADING = 'SET_OPTINS_LOADING'
const SET_LISTS_LOADING = 'SET_LISTS_LOADING'
const SET_DATA_LOADING = 'SET_DATA_LOADING'
const SET_KPIS_LOADING = 'SET_KPIS_LOADING'
const SET_CAMPAIGNS_LOADING = 'SET_CAMPAIGNS_LOADING'


const moduleLists = {
  namespaced: true,
  state: {
    contact: {},
    kpis: {},
    loading: false,
    blacklistLoading: false,
    statusLoading: false,
    optinsLoading: false,
    listsLoading: false,
    dataLoading: false,
    kpisLoading: false,
    campaignsLoading: false
  },
  mutations: {
    [SET_CONTACT] (state, contact) {
      Vue.set(state, 'contact', contact)
    },
    [SET_KPIS] (state, kpis) {
      Vue.set(state, 'kpis', kpis)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    },
    [SET_STATUS_LOADING] (state, statusLoading) {
      Vue.set(state, 'statusLoading', statusLoading)
    },
    [SET_BLACKLIST_LOADING] (state, blacklistLoading) {
      Vue.set(state, 'blacklistLoading', blacklistLoading)
    },
    [SET_OPTINS_LOADING] (state, optinsLoading) {
      Vue.set(state, 'optinsLoading', optinsLoading)
    },
    [SET_LISTS_LOADING] (state, listsLoading) {
      Vue.set(state, 'listsLoading', listsLoading)
    },
    [SET_DATA_LOADING] (state, dataLoading) {
      Vue.set(state, 'dataLoading', dataLoading)
    },
    [SET_KPIS_LOADING] (state, kpisLoading) {
      Vue.set(state, 'kpisLoading', kpisLoading)
    },
    [SET_CAMPAIGNS_LOADING] (state, campaignsLoading) {
      Vue.set(state, 'campaignsLoading', campaignsLoading)
    }
  },
  actions: {
    searchContact (_, { channel, search }) {
      const url = `${config.API_URL}/${channel}_contacts/search`
      const params = {}
      params[channel] = search
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: params })
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              reject(error.response ? error.response : error)
            }
          })
      })
    },
    getContact ({ commit }, { channel, id }) {
      commit(SET_LOADING, true)
      const url = `${config.API_URL}/${channel}_contacts/${id}`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_CONTACT, response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            if (!axios.isCancel(error)) {
              reject(error.response ? error.response : error)
            }
          })
      })
    },
    async subscribe({ dispatch }, { channel, id, optId, details }) {
      await dispatch('updateOptinStatus', {
        channel, id, optId, 
        action: 'activate', 
        payload: { details }
      })
    },
    async unsubscribe({ dispatch }, { channel, id, optId, details }) {
      await dispatch('updateOptinStatus', {
        channel, id, optId, 
        action: 'deactivate', 
        payload: { details }
      })
    },
    updateOptinStatus({ commit }, { channel, id, optId, action, payload }) {
      const url = `${config.API_URL}/${channel}_contacts/${id}/optins/${optId}/${action}`
      commit(SET_STATUS_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, payload)
          .then(response => {
            commit(SET_STATUS_LOADING, false)
            if (response.status >= 300) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_STATUS_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    async unhardbounce({ dispatch }, { channel, id }) {
      await dispatch('updateStatus', {
        channel, id, payload: { 
          status: 'hardbounce', 
          value: false 
        }
      })
    },
    async blacklistContact({ dispatch }, { channel, id }) {
      await dispatch('updateStatus', {
        channel, id, payload: { 
          status: 'blacklist', 
          value: true 
        }
      })
    },
    async unblacklistContact({ dispatch }, { channel, id }) {
      await dispatch('updateStatus', {
        channel, id, payload: { 
          status: 'blacklist', 
          value: false 
        }
      })
    },
    updateStatus({ commit }, { channel, id, payload }) {
      const url = `${config.API_URL}/${channel}_contacts/${id}/status`
      commit(SET_STATUS_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.patch(url, payload)
          .then(response => {
            commit(SET_STATUS_LOADING, false)
            if (response.status >= 300) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_STATUS_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getOptinLogs({ commit }, { channel, id, optId, page, limit }) {
      const url = `${config.API_URL}/${channel}_contacts/${id}/optins/${optId}/user_actions`
      commit(SET_LISTS_LOADING, true)

      const params = {}
      if (page) { params['p'] = page }
      if (limit) { params['l'] = limit }

      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params })
          .then(response => {
            commit(SET_LISTS_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LISTS_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getOptins({ commit }, { channel, id, page, limit, orderBy, search, groupId, name }) {
      const url = `${config.API_URL}/${channel}_contacts/${id}/optins`
      commit(SET_OPTINS_LOADING, true)

      const params = {}
      if (page) { params['p'] = page }
      if (search && search.length > 0) { params['s'] = search }
      if (groupId && groupId.length > 0) { params['group_id'] = groupId }
      if (limit) { params['l'] = limit }
      if (orderBy) { params['ob'] = orderBy }
      if (name) { params['name'] = name }

      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params })
          .then(response => {
            commit(SET_OPTINS_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_OPTINS_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getLists({ commit }, { channel, id, page, limit, orderBy, search, groupId, name }) {
      const url = `${config.API_URL}/${channel}_contacts/${id}/lists`
      commit(SET_LISTS_LOADING, true)

      const params = {}
      if (page) { params['p'] = page }
      if (search && search.length > 0) { params['s'] = search }
      if (groupId && groupId.length > 0) { params['group_id'] = groupId }
      if (limit) { params['l'] = limit }
      if (orderBy) { params['ob'] = orderBy }
      if (name) { params['name'] = name }

      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params })
          .then(response => {
            commit(SET_LISTS_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LISTS_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getData({ commit }, { channel, id}) {
      const url = `${config.API_URL}/${channel}_contacts/${id}/data`
      commit(SET_DATA_LOADING, true)

      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            commit(SET_DATA_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_DATA_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getKpis ({ commit }, { channel, id, groupId }) {
      commit(SET_KPIS_LOADING, true)
      const url = `${config.API_URL}/${channel}_contacts/${id}/kpis`
      const params = {}
      if (groupId) { params.group_id = groupId}
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params })
          .then(response => {
            commit(SET_KPIS_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_KPIS, response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_KPIS_LOADING, false)
            if (!axios.isCancel(error)) {
              reject(error.response ? error.response : error)
            }
          })
      })
    },
    getCampaigns({ commit }, { channel, id, page, limit, search, groupId, fromDate }) {
      commit(SET_CAMPAIGNS_LOADING, true)
      const url = `${config.API_URL}/${channel}_contacts/${id}/campaigns`
      const params = {}
      if (groupId && groupId.length > 0) { params['group_id'] = groupId }
      if (limit) { params['l'] = limit }
      if (page) { params['p'] = page }
      if (fromDate) { params['from_date'] = fromDate }
      if (search && search.length > 0) { 
        params['s'] = search 
        params['sf'] = 'name,id'
      }
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params })
          .then(response => {
            commit(SET_CAMPAIGNS_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_CAMPAIGNS_LOADING, false)
            if (!axios.isCancel(error)) {
              reject(error.response ? error.response : error)
            }
          })
      })
    },
  },
  getters: {
    contact: state => state.contact,
    kpis: state => state.kpis,
    loading: state => state.loading,
    blacklistLoading: state => state.blacklistLoading,
    statusLoading: state => state.statusLoading,
    optinsLoading: state => state.optinsLoading,
    listsLoading: state => state.listsLoading,
    dataLoading: state => state.dataLoading,
    kpisLoading: state => state.kpisLoading,
    campaignsLoading: state => state.campaignsLoading
  }
}

export default moduleLists
