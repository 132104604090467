<template>
  <div class="max-content-body home-page">
    <div class="home-container">
      <div
        v-if="activeRoute === 'contacts'"
        class="home-container__title contacts"
      >
        <h1>
          {{ $t('CONTACT_CARDS.SUBTITLE') }}
        </h1>
        <span
          class="home-container__description"
        >
          {{ $t('CONTACT_CARDS.DESCRIPTION') }}
        </span>
      </div>
      <div
        v-else-if="activeRoute === 'lists'"
        class="home-container__title"
      >
        <h1>
          {{ $t('CONTACT_LISTS.TITLE') }}
        </h1>
        <span
          class="home-container__description"
        >
          {{ $t('CONTACT_LISTS.DESCRIPTION') }}
        </span>
      </div>
      <div
        v-else-if="activeRoute === 'submission-lists'"
        class="home-container__title"
      >
        <h1>
          {{ $t('SUBMISSION_LISTS.TITLE') }}
        </h1>
        <span
          class="home-container__description"
        >
          {{ $t('SUBMISSION_LISTS.DESCRIPTION') }}
        </span>
      </div>

      <!-- CONTENT -->
      <transition>
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContainerHome',
  data () {
    return {
      availableTabRoutes: ['lists', 'folders'],
    }
  },
  computed: {
    activeIndex () {
      return this.availableTabRoutes.indexOf(this.$route.name) > -1 ?
        this.$route.name :
        this.availableTabRoutes[0]
    },
    hasCreationPermission () {
      return this.$store.getters['auth/hasPermission']('contactly:role:lists:w') ||
        this.$store.getters['auth/hasPermission']('contactly:role:lists:w-self')
    },
    activeRoute () {
      return this.$route.name
    }
  },
  methods: {
    handleSelect(key) {
      if (key != this.$route.name ) {
        this.$router.push({ name: key })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.home-page {
  margin-top: 20px;
  .home-container__title.contacts {
    text-align: center;
  }
  .home-container__title {
    margin-bottom: 30px; // TODO: remove when folders feature will be active
  }
  .home-container__description {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 60px; // TODO: remove when folders feature will be active
  }
  .home-container__button {
    padding: 13px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .home-page {
    .home-container__title {
      text-align: center;
      margin-bottom: 20px; // TODO: remove when folders feature will be active
    }

  }
}
</style>
