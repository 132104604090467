<template>
  <el-dialog
    :custom-class="customClass"
    :visible="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="!isConfirmationModal"
    :show-close="!isConfirmationModal"
    :close-on-press-escape="!isConfirmationModal"
    @close="closeModal"
  >
    <div
      slot="title"
      class="dialog-modal__title"
    >
      <div>
        {{ modalTitle }}
      </div>
    </div>
    <!-- Contact status Information -->
    <div v-if="!isConfirmationModal">
      <div class="dialog-modal__information">
        <span class="dialog-modal__information__intro">
          {{ $t('CONTACT_CARDS.MODALS.CONTACTABILITY.INTRO') }}
        </span>

        <div v-if="!isReachable">
          {{ $t('CONTACT_CARDS.MODALS.CONTACTABILITY.DETAILS') }}
        </div>
        <div class="dialog-modal__information__status">
          <span>
            {{ $t('CONTACT_CARDS.MODALS.CONTACTABILITY.CURRENT_STATUS') }}
          </span>
          <div class="dialog-modal__information__status__tags">
            <StatusTag
              v-if="isReachable"
            />
            <StatusTag
              v-for="tag in statusTags"
              v-else
              :key="tag"
              :status="tag"
            />
          </div>
        </div>
      </div>
      <!--  Action section for admins -->
      <div
        v-if="!isConfirmationModal && hasAdminContactCardPermission"
        class="dialog-modal__action"
      >
        <span v-if="logs.length">
          {{ $t('CONTACT_CARDS.MODALS.CONTACTABILITY.LOGS_INTRO') }}
        </span>
        <ul
          v-if="logs.length" 
          class="dialog-modal__action__logs"
        >
          <li 
            v-for="(log, index) in logs.slice(0, 3)"
            :key="index"
            class="log"
          >
            <el-alert
              v-if="log.action_type === 'blacklist'"
              :title="$t('CONTACT_CARDS.MODALS.CONTACTABILITY.BLACKLIST_INFO', { 
                date: getDate(log.action_date)
              })"
              :closable="false"
              type="info"
              show-icon
            />
            <el-alert
              v-if="log.action_type === 'unblacklist'"
              :title="$t('CONTACT_CARDS.MODALS.CONTACTABILITY.UNBLACKLIST_INFO', { 
                date: getDate(log.action_date)
              })"
              :closable="false"
              type="info"
              show-icon
            />
            <el-alert
              v-if="log.action_type === 'dehardification'"
              :title="$t('CONTACT_CARDS.MODALS.CONTACTABILITY.DEHARDIF_INFO', { 
                date: getDate(log.action_date)
              })"
              :closable="false"
              type="info"
              show-icon
            />
          </li>
        </ul>

        <div class="dialog-modal__action__btn">
          <el-button
            v-if="isHardbounce"
            size="medium"
            @click="switchModal('hardbounce')"
          >
            {{ $t('BUTTON.REMOVE_NPAI') }}
          </el-button>
          <el-button
            size="medium"
            @click="switchModal('blacklist')"
          >
            {{ actionButtonText }}
          </el-button>
        </div>
      </div>
    </div>
    <!-- Confirmationn modal -->
    <div
      v-if="isConfirmationModal"
      class="dialog-modal__information"
    >
      <span class="dialog-modal__information__intro">
        {{ confirmationText }}
      </span>
      <el-alert
        v-if="confirmationType === 'hardbounce' || (confirmationType === 'blacklist' && !isBlacklisted)"
        :title="$t(confirmationWarning)"
        :closable="false"
        type="warning"
        show-icon
      />
    </div>
    <div
      v-if="isConfirmationModal"
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="switchModal">
        {{ $t('BUTTON.CANCEL') }}
      </el-button>
      <el-button
        type="danger"
        :loading="statusLoading"
        @click="actions"
      >
        {{ $t('BUTTON.CONFIRM') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import { StatusTag }  from '@/components'
import config from '@/config'

export default {
  name: 'ModalContactabilityStatus',
  components: { StatusTag },
  props: {
    dialogVisible: { 
      type: Boolean, 
      default: false 
    },
    isConfirmationModal: {
      type: Boolean, 
      default: false 
    },
    contact: {
      type: Object,
      required: true
    },
    channel: { 
      type: String, 
      required: true 
    }
  },
  data() {
    return {
      confirmationType: null
    }
  },
  computed: {
    customClass () {
      return `dialog-modal ${this.isConfirmationModal ? 'confirm-modal' : ''}`
    },
    hasAdminContactCardPermission () {
      return this.$store.getters['auth/hasPermission']('contactly:user:status:w')
    },
    statusLoading: function () {
      return this.$store.getters['contacts/statusLoading']
    },
    clientName () {
      return this.$store.getters['auth/user'].client.name
    },
    campaignChannel () {
      return this.channel === 'email' ? 'email': 'SMS'
    },
    statusKeys () {
      return this.contact.status ? Object.keys(this.contact.status).map(r => parseInt(r)).sort() : []
    },
    actionButtonText () {
      return this.isBlacklisted 
        ? this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.UNBLACKLIST') 
        : this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.BLACKLIST')
    },
    isBlacklisted () {
      return this.statusKeys.indexOf(config.CONTACT_CARD_STATUS.BLACKLIST) > -1
    },
    logs () {
      return this.contact.change_history || []
    },
    isReachable () {
      return this.statusKeys.length == 0
    },
    statusTags () {
      return this.statusKeys
    },
    blacklistDate () {
      if (this.isBlacklisted) {
        return Vue.moment(this.status[config.CONTACT_CARD_STATUS.BLACKLIST]).format('LL')
      }
      return Vue.moment(this.contact.unblacklist_date || '').format('LL')
    },
    modalTitle () {
      switch (true) {
        case (this.isConfirmationModal && this.confirmationType === 'blacklist' && this.isBlacklisted): {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.UNBLACKLIST_CONFIRMATION_TITLE')
        }
        case (this.isConfirmationModal && this.confirmationType === 'blacklist' && !this.isBlacklisted): {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.BLACKLIST_CONFIRMATION_TITLE')
        }
        case (this.isConfirmationModal && this.confirmationType === 'hardbounce'): {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.DEHARDIF_CONFIRMATION_TITLE')
        }
        default: {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.TITLE')
        }
      }
    },
    confirmationText () {
      switch (true) {
        case (this.isConfirmationModal && this.confirmationType === 'blacklist' && this.isBlacklisted): {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.UNBLACKLIST_CONFIRMATION_SUBTITLE',
          { channel: this.campaignChannel, clientName: this.clientName })
        }
        case (this.isConfirmationModal && this.confirmationType === 'blacklist' && !this.isBlacklisted): {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.BLACKLIST_CONFIRMATION_SUBTITLE',
          { channel: this.campaignChannel, clientName: this.clientName })
        }
        case (this.isConfirmationModal && this.confirmationType === 'hardbounce' && this.channel === 'mobile'): {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.DEHARDIF_SMS_CONFIRMATION_SUBTITLE')
        }
        default: {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.DEHARDIF_EMAIL_CONFIRMATION_SUBTITLE')
        }
      }
    },
    confirmationWarning () {
      switch (true) {
        case (this.isConfirmationModal && this.confirmationType === 'blacklist' && !this.isBlacklisted): {
          return this.$t(
            'CONTACT_CARDS.MODALS.CONTACTABILITY.BLACKLIST_CONFIRMATION_WARNING',
            { channel: this.campaignChannel, clientName: this.clientName }
          )
        }
        default: {
          return this.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.DEHARDIF_CONFIRMATION_WARNING')
        }
      }
    },
    isHardbounce () {
      return this.statusTags.includes(config.CONTACT_CARD_STATUS.HARDBOUNCE)
    }
  },
  methods: {
    getDate (date) {
      return Vue.moment(date).format('LL')
    },
    closeModal () {
      this.$emit('update:dialogVisible', false)
    },
    actions () {
      const isBlacklistAction = this.isConfirmationModal && this.confirmationType === 'blacklist'
      switch (true) {
        case (isBlacklistAction && this.isBlacklisted): {
          this.$emit('action', 'UNBLACKLIST')
          break
        }
        case (isBlacklistAction && !this.isBlacklisted): {
          this.$emit('action', 'BLACKLIST')
          break
        }
        default: {
          this.$emit('action', 'HARDBOUNCE')
        }
      }
    },
    switchModal (type) {
      this.confirmationType = type || null
      this.$emit('switchModal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

::v-deep .dialog-modal {
  &.confirm-modal {
    max-width: 500px;
  }
  overflow-y: auto;
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__header {
    padding-bottom: 0;
  }

  .dialog-modal__title {
    color: $--color-primary;
    font-weight: 600;
  }
  .dialog-modal__information {
    word-break: break-word;
    font-size: 12px;
    padding: 20px;
    .el-alert {
      margin-top: 10px;
    }
    .dialog-modal__information__status {
      margin-top: 30px;
      display: flex;
      span {
        padding-top: 5px;
        padding-right: 5px;
      }
      .dialog-modal__information__status__tags {
        display: flex;
      }
    }
  }
  .dialog-modal__action {
    font-size: 12px;
    padding: 20px 20px;
    border-top: 1px solid $--color-light-gray;
    &__logs {
      list-style: none;
      padding: 0 0 30px 0;
      .el-alert {
        margin-bottom: 10px;
        border-radius: 3px;
      }
    }
    .dialog-modal__action__btn {
      margin: auto;
      display: flex;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: $--mobile-width) {
  ::v-deep .dialog-modal {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0;
    height: 100%;
    overflow: auto;
    .dialog-modal__information {
      .dialog-modal__information__status {
        margin-top: 30px;
        display: block;
        .dialog-modal__information__status__tags {
          margin-top: 10px;
        }
      }
    }

  }
}
</style>
