<template>
  <div class="home__lists">
    <!-- FILTERS AND ACTION BUTTONS -->
    <HomeActions
      tab-index="list-submissions"
      :on-filters-change="onFiltersChange"
      :display-list-type-filter="false"
      :search-placeholder="$t('FILTER.SEARCH_SUBMISSION_LIST')"
      @search-updated="onFiltersChange"
      @filters-updated="onFiltersChange"
    />

    <!-- LISTS: DESKTOP VIEW -->
    <div class="table-container">
      <!-- TABLE -->
      <el-table
        ref="listsTable"
        v-loading="loading"
        :data="lists"
        :default-sort="orderBy"
        @sort-change="onSortChange"
        @row-click="onRowClick"
      >
        <div slot="empty">
          <NoData
            :type="filtersActive ? 'alternative' : 'default'"
          />
        </div>
        <el-table-column
          width="40"
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.group"
              effect="dark"
              :content="scope.row.group.name"
              placement="top"
            >
              <AvatarHolder
                :avatar-url="scope.row.group.logo_url"
                :display-name="scope.row.group.name"
                :width="25"
                :height="25"
                :font-size="8"
              />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.NAME')"
          width="340"
        >
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="scope.row.name"
              placement="top"
            >
              <span
                class="table-container__list-name"
              >
                {{ scope.row.name }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.SUBMISSIONS')"
          width="230"
          property="contacts_count"
        >
          <template slot-scope="scope">
            <span class="text-tertiary">
              {{ scope.row.contacts_count || 0 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.LAST_UPDATE')"
          :sortable="true"
          property="updated_at"
          width="270"
        >
          <template slot-scope="scope">
            {{ scope.row.updated_at | moment('calendar', null, momentSetup) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.AUTHOR')"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip
              :key="scope.row.id"
              effect="dark"
              :content="getFullName(scope.row.author)"
              placement="top"
            >
              <AvatarHolder
                :avatar-url="scope.row.author ? scope.row.author.avatar_url : noAuthor"
                :display-name="scope.row.author ? getFullName(scope.row.author) : null"
                :width="25"
                :height="25"
                :font-size="8"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- PAGINATION -->
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :current-page.sync="page"
        class="text-center"
        @current-change="getLists"
      />
    </div>

    <!-- LISTS: MOBILE VIEW -->
    <div
      class="simple-view-container"
    >
      <HomeListItem
        v-for="(l, idx) in lists"
        :key="l.id"
        :item="l"
        :index="idx"
      />
      <NoData
        v-if="lists.length === 0 && !loading"
        :type="filtersActive ? 'alternative' : 'default'"
      />
      <!-- LOADING -->
      <LoadingSpinner
        v-if="loading"
        :title="$t('LISTS.FETCHING_LISTS')"
      />
    </div>
  </div>
</template>

<script>
import { findKey } from 'lodash-es'
import {
  AvatarHolder,
  HomeActions,
  HomeListItem,
  NoData,
  LoadingSpinner
} from '..'
import { getInitials, getFullName } from '@/filters'
import config from '@/config'
import NoAuthor from '@/assets/img/no-author.svg'

export default {
  name: 'HomeSubmissionList',
  components: {
    AvatarHolder,
    HomeActions,
    HomeListItem,
    NoData,
  LoadingSpinner
  },
  data () {
    return {
      loading: false,
      filters: {
        group: [],
        name: null,
        author: null
      },
      lists: [],
      page: 1,
      total: 0,
      currentTotal: 0,
      limit: 10,
      orderBy: null,
      isMobile: false,
      reset: false,
      listTypes: config.LIST_TYPES,
      noAuthor: NoAuthor
    }
  },
  computed: {
    filtersActive () {
      let keyWithValue = findKey(this.filters, function(val) {
        return val !== null && val !== undefined && val !== ''
      })
      return keyWithValue ? true : false
    },
    momentSetup () {
      return {
        sameDay: '[' + this.$t('MISC.TODAY') + '], HH:mm',
        lastDay: '[' + this.$t('MISC.YESTERDAY') + '], HH:mm',
        sameYear: 'D MMM, HH:mm',
        sameElse: 'D MMM YYYY, HH:mm'
      }
    }
  },
  created () {
    // Listening to window resizing to detect mobile or desktop
    window.addEventListener('resize', this.resizingFunction, false)
    window.addEventListener('scroll', this.scrollFunction, false)

    // Detect if mobile
    if (window.innerWidth <= 1024) {
      this.isMobile = true
      this.limit = 20
    }

    // extract query params
    this.extractQueryParams()
    // load folders
    this.getLists()
  },
  destroyed () {
    window.removeEventListener('resize', this.resizingFunction, false)
    window.removeEventListener('scroll', this.scrollFunction, false)
  },
  methods: {
    getInitials: getInitials,
    getFullName: getFullName,
    onRowClick (item) {
      // go to the details page
      this.$router.push({ path: `/lists/${item.id}/details` })
    },
    onSortChange (orderBy) {
      // set orderBy
      let ob = ''
      if (!orderBy.order) {
        this.orderBy = {}
      } else {
        this.orderBy = orderBy
        ob = orderBy.prop + ',' + orderBy.order
      }
      // append orderBy to query string
      let queryString = Object.assign({}, this.$route.query, {'ob': ob})
      this.$router.push({ query: queryString }).catch(() => {})
      // if params have changed, reloading lists will be triggered by beforeRouteUpdate
    },
    onFiltersChange () {
      this.reset = true
      // reinit page to 1
      this.page = 1
      // reset lists if mobile
      if (this.isMobile) {
        this.lists = []
      }
      // append filters to query string
      this.$store.dispatch('lists/getQueryParams').then((query) => {
        this.$router.push({ query }).catch(() => {})
      })
      // if params have changed, reloading lists will be triggered by beforeRouteUpdate
    },
    getLists () {
      this.loading = true
      // format orderBy
      let ob = null
      if (this.orderBy) {
        ob = this.orderBy.prop
        if (this.orderBy.order === 'descending') {
          ob = '-' + ob
        }
      }

      // format request data
      let data = {
        'page': this.page,
        'search': this.filters.name,
        'groupId': this.filters.group,
        'orderBy': ob,
        'limit': this.limit,
        'authorId': this.filters.author,
        'typeId': this.listTypes.SUBMISSION
      }
      // dispatch request
      this.$store.dispatch('lists/getLists', data)
      .then((res) => {
        // If mobile version and not loading and no filters name, infinite scroll --> add res to current lists
        if (this.isMobile && !this.filters.name && !this.reset) {
          this.lists = this.lists.concat(res.objects)
          this.currentTotal = this.lists.length
        } else {
          this.lists = res.objects
        }
        this.total = res.meta.total
      })
      .catch(() => {
        this.lists = []
        this.total = 0
      })
      .finally(() => {
        this.loading = false
        // reset to false
        this.reset = false
      })
    },
    extractQueryParams () {
      // extract orderBy from query
      let orderBy = (this.$route.query.ob || '').split(',')
      if (orderBy.length === 2) {
        this.orderBy = { prop: orderBy[0], order: orderBy[1] }
      } else {
        this.orderBy = {}
      }
      // extract other filters as well
      let queryParams = this.$route.query
      for (var key in this.filters) {
        if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
          this.filters[key] = queryParams[key]
        } else {
          this.filters[key] = null
        }
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.path != from.path || to.query != from.query) {
      // extract query params
      this.extractQueryParams()
      // reload folders
      this.getLists()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.home__lists {
  .avatar-holder {
    line-height: 20px;
    display: inline-flex;
    vertical-align: middle;
  }

  .list__type-slot {
    display: inline-flex;
    vertical-align: middle;
    line-height: 20px;

    > i.material-icons {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  ::v-deep .el-table__header {
    .cell {
      font-weight: 600
    }
  }

  ::v-deep .el-table__row {
    cursor: pointer;
  }

  ::v-deep .el-table .cell {
    text-overflow: unset;
  }

  ::v-deep .el-table__empty-text {
    color: inherit;
    line-height: inherit;
  }
  .simple-view-container {
    display: none;
  }
  .simple-view-container {
    background-color: $--color-white;
    border: 1px solid $--border-color-base;
    margin: 5px;
    padding: 10px;
  }
}


.table-container {
  &__list-name {
    overflow: hidden;
    height: 20px;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .home__lists {
    .table-container {
      display: none;
    }

    .simple-view-container {
      display: block;
    }
  }
}

</style>
