<template>
  <el-dialog
    custom-class="import-contacts"
    :append-to-body="true"
    :fullscreen="true"
    :show-close="false"
    :visible="showModal"
    center
    @close="closeModal"
  >
    <!-- TITLE -->
    <div
      slot="title"
    >
      <el-button
        size="medium"
        class="import-contacts__exit-btn"
        @click="closeModal()"
      >
        {{ $t('BUTTON.EXIT') }}
      </el-button>
      <h2 class="title">
        {{ $t('IMPORT_CONTACTS.TITLE') }}
      </h2>
      <div class="subtitle">
        {{ list.name }}
      </div>
    </div>

    <!-- BODY -->
    <SetupStaticFile
      ref="importContacts"
      :list="listObj"
      :step.sync="listStep"
      action="importContacts"
      :edition="true"
    />

    <!-- FOOTER -->
    <span slot="footer">
      <el-button
        v-show="step === 1"
        size="medium"
        type="primary"
        @click="next()"
      >
        {{ $t('BUTTON.NEXT') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { SetupStaticFile } from '..'
export default {
  name: 'ModalImportContacts',
  components: { SetupStaticFile },
  props: {
    showModal: { type: Boolean, required: true },
    list: { type: Object, required: true }
  },
  data: function () {
    return {
      step: 1,
      listObj: null
    }
  },
  computed: {
    listStep: {
      get: function () {
        return this.step
      },
      set: function (value) {
        if (value === 2) {
          this.$store.dispatch('lists/getList', this.list.id)
          .finally(this.closeModal())
          return
        }
        this.step = value
        return
      }
    }
  },
  created () {
    this.listObj = {
      'id': this.list.id,
      'update_type_id': this.list.update_type_id,
      'type_id': this.list.type_id,
      'fields': this.list.fields
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:showModal', false)
    },
    next() {
      this.$refs['importContacts'].handleNext(true)
      return
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--mobile-width: 500px;

::v-deep .import-contacts {
  background: $--color-bg-light-gray;

  .el-dialog__header {
    padding-top: 48px;
  }
  .title {
    margin: 10px;
    font-size: 36px;
    font-weight: 300;
  }
  .subtitle {
    margin: 10px;
    font-size: 21px;
    font-weight: 400;
  }
  &__exit-btn {
    position: absolute;
    top: 36px;
    right: 44px;
  }
}

::v-deep .setup-loading {
  text-align: center;
  &__logo {
    margin: 24px 0px 12px 0px;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    margin: 44px 0px 24px 0px;
  }
  &__description {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
