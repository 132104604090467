
<template functional>
  <div class="kpi-card">
    <i
      v-if="props.icon"
      class="material-icons"
    >{{ props.icon }}</i>
    <img
      v-else-if="props.imgUrl"
      :src="props.imgUrl"
      alt="KPI image"
    >
    <span class="kpi-card__number">
      {{ props.isRate ? props.kpi + '%' : props.kpi }}
    </span>
    <span class="kpi-card__text">
      {{ props.name }}
      <el-tooltip
        effect="dark"
        :content="props.info"
        placement="top"
      >
        <i class="material-icons">help_outline</i>
      </el-tooltip>
    </span>
  </div>
</template>

<script>

export default {
  name: 'KpiCard',
  props: {
    icon: { type: String, default: '' },
    imgUrl: { type: String, default: '' },
    kpi: { type: Number, default: null },
    name: { type: String, default: '' },
    info: { type: String, default: '' },
    isRate: { type: Boolean, default: false },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.kpi-card {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);
  width: 135px;
  height: 135px;
  margin: 20px 30px 0 0;
  padding: 13px;
  border: solid 1px $--color-medium-gray;
  border-radius: 3px;
  text-align: center;

  i.material-icons {
    color: $--color-primary;
    font-size: 18px;
  }
  .kpi-card__number {
    font-size: 18px;
    font-weight: 600;
    margin: 6.5px 0
  }
  .kpi-card__text {
    color: $--color-gray-lighter;
    text-align: center;
    font-size: 13px;
    vertical-align: bottom;
    margin: 0 0 6.5px;
    i.material-icons {
      font-size: 12px;
      color: $--color-gray-lighter;
      margin-left: 1px;
      opacity: .5;
      vertical-align: middle;
      cursor: default;
    }
  }
}

</style>
