<template>
  <div class="exports">
    <!-- LOADING -->
    <LoadingSpinner
      v-if="(exports.length === 0 && loading) || loading"
      :title="$t('EXPORT_LIST.LOADING')"
    />
    <div
      v-if="!loading"
      class="exports__header"
    >
      <p>
        {{ $t("EXPORT_LIST.HEADER_1") }}
        <br>
        {{ $t("EXPORT_LIST.HEADER_2") }}
      </p>
    </div>
    <!-- Export List -->
    <NoData
      v-if="!exports.length"
      type="items"
      :text="$t('EXPORT_LIST.NO_FILES')"
    />
    <ul
      v-else
      class="exports-list"
    >
      <li
        v-for="(item, index) in exports"
        :key="index"
        class="exports-list__item"
      >
        <el-card shadow="never">
          <!-- card body -->
          <!-- LEFT SIDE -->
          <div class="left-side">
            <div class="creation-date">
              {{ creationDate(item.created_at) }}
            </div>
            <div class="exports-list__item-author">
              {{ $t("EXPORT_LIST.EXPORTED_BY") }}
              <span>
                {{ getFullName(item.author) }}
              </span>
            </div>
            <div class="exports-list__item-total">
              {{ item.exported_volume }}
              {{ $tc("EXPORT_LIST.CONTACTS", item.exported_volume) }}

              <span class="exports-list__item-separator">
                •
              </span>
              <span class="exports-list__item-expires">
                {{ $t("EXPORT_LIST.EXPIRES") }}
                {{ item.expiration_date | moment("ll") }}
              </span>
            </div>
          </div>
          <!-- RIGHT SIDE -->
          <div class="right-side">
            <el-button
              size="default"
              @click="downloadFile(item)"
            >
              {{ $t("EXPORT_LIST.DOWNLOAD") }}
            </el-button>
          </div>
        </el-card>
        <div class="separator" />
      </li>
    </ul>
  </div>
</template>

<script>
import config from '@/config'
import { getFullName } from '@/filters'
import { LoadingSpinner, NoData } from '@/components'

export default {
  name: 'ListActivity',
  components: { LoadingSpinner, NoData },
  data() {
    return {
      loading: false,
      downloadLoading: false,
      exports: [],
      currentTotal: 0,
      total: 0,
      page: 1,
      limit: 10,
      activityId: null
    }
  },
  computed: {
    config () {
      return config
    },
    list () {
      return this.$store.getters['lists/list'] || {}
    },
    noMore () {
      return this.currentTotal >= this.total
    },
    disabled () {
      return this.loading || this.noMore
    }
  },
  created () {
    this.init()
  },
  destroyed() {
    document.getElementById('body-content').removeEventListener('scroll', this.scrollFunction, false)
  },
  methods: {
    getFullName: getFullName,
    creationDate (creationDate) {
      let momentDate = moment(creationDate)
      const date = momentDate.format("DD MMM")
      const hour = momentDate.format("LT")
      return `${date} ${this.$t('MISC.AT')} ${hour}`
    },
    init () {
      document.getElementById('body-content').addEventListener('scroll', this.scrollFunction, false)
      this.getExports()
    },
    scrollFunction () {
      // Infinite scroll
      const bodyContentEl = document.getElementById('body-content')
      const bottomOfWindow = bodyContentEl.scrollHeight - (bodyContentEl.offsetHeight + bodyContentEl.scrollTop) < 2
      if (this.noMore || this.disabled) {
        return false
      }
      if (bottomOfWindow) {
        this.page++
        this.getExports()
      }
    },
    getExports (reset = false) {
      if (reset) {
        this.page = 1
      }
      this.loading = true
      const listId = this.$route.params.listId
      const params = {
        p: this.page,
        l: this.limit
      }
      this.$store.dispatch('exports/getListExports', {listId, params})
      .then((res) => {
        // Set total
        this.total = res.meta.total
        this.exports = res.objects.length > 0 && !reset ? this.exports.concat(res.objects) : res.objects
        this.currentTotal = this.exports.length
      })
      .catch((error) => {
        this.$notify({
          title: error.status === 403 ? this.$t('ERRORS.FORBIDDEN') : this.$t('ERRORS.UNEXPECTED'),
          message: error.status === 403 ? this.$t('ERRORS.FORBIDDEN_DESC') : this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
      .finally(() => {
        this.loading = false
      })
    },
    downloadFile (item) {
      let momentDate = moment(item.created_at)
      var label = `${this.list.name} - ${momentDate.format('YYYYMMDD_hhmm')}.csv`
      this.downloadLoading = true
      this.$store.dispatch('exports/downloadExport',
        {'listId': item.list_id, 'exportId': item.id}
      )
      .then((res) => {
        var link = document.createElement("a")
        link.download = label
        link.href = res.data
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((res) => {
        let errorTitle = res.status === 404 ? 'EXPORT_LIST.EXPORT_NOT_FOUND_TITLE': 'ERRORS.UNEXPECTED'
        let errorMessage = res.status === 404 ? 'EXPORT_LIST.EXPORT_NOT_FOUND_DESC' : 'ERRORS.UNEXPECTED_DESC'
        this.$notify({
          title: this.$t(errorTitle),
          message: this.$t(errorMessage),
          type: 'error'
        })
      })
      .finally(() => {
        this.downloadLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

ul {
  list-style: none;
  padding: 0;
  li {
    &:last-child {
      .separator {
        display: none;
      }
    }
  }
}

.exports {
  &__header {
    text-align: center;
    color: $--color-gray;
    margin-bottom: 40px;
    p {
      margin-bottom: 0;
    }
  }
  &-list {
    display: flexbox;
    flex-direction: column;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .left-side {
        line-height: 22px;
        .creation-date {
          font-weight: 600;
        }
      }
      &-separator {
        margin: 0 4px;
      }
      &-expires {
        color: $--color-gray-lighter;
      }
    }
  }

  ::v-deep .el-card {
    width: 500px;
    &__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px;
      border: 1px solid $--color-medium-gray;
    }
  }
}

.separator {
  height: 23px;
  width: 1px;
  border-left: 1px solid $--color-medium-gray;
}
</style>
