<template>
  <div
    class="no-data-container"
    :style="styles"
  >
    <img
      class="icon"
      :src="iconUrl"
      :alt="displayedText"
    >
    <p
      class="no-data-container__title"
      :class="size ? size : ''"
    >
      {{ displayedText }}
    </p>
    <p
      v-if="subtitle"
      class="no-data-container__subtitle"
    >
      {{ subtitle }}
    </p>
    <el-button
      v-if="btn"
      :disabled="disabledBtn"
      class="no-data-container__btn"
      type="primary"
      size="large"
      @click="action"
    >
      {{ btn }}
    </el-button>
  </div>
</template>

<script>
import noDataIcon from '@/assets/img/no-data-icon.svg'
import noMatchingIcon from '@/assets/img/no-matching-data-icon.svg'
import noDataBox from '@/assets/img/no-data-box.svg'
import settingsIcon from '@/assets/img/settings-icon.svg'
import bg1 from '@/assets/img/potato-1-bg.svg'
import bg2 from '@/assets/img/potato-2-bg.svg'

export default {
  name: 'NoData',
  props: {
    type: {
      type: String,
      validator: (val) => ['default', 'alternative', 'items', 'settings'].includes(val),
      default: 'default'
    },
    text: { type: String, default: '' },
    subtitle: { type: String, default: null },
    btn: { type: String, default: null },
    action: { type: Function, default: null },
    size: { type: String, default: null},
    width: { type: Number, default: 300 },
    height: { type: Number, default: 300 },
    disabledBtn : { type: Boolean, default: false }
  },
  computed: {
    iconUrl () {
      switch (this.type) {
        case 'default':
          return noDataIcon
        case 'alternative':
          return noMatchingIcon
        case 'items':
          return noDataBox
        case 'settings':
          return settingsIcon
        default:
          return noDataIcon
      }
    },
    backgroundUrl () {
      return this.type === 'default' ? bg1 : bg2
    },
    displayedText () {
      if (this.text) {
        return this.text
      } else {
        return this.type === 'default' ?
          this.$t('LISTS.NO_DATA.DEFAULT') :
          this.$t('LISTS.NO_DATA.WITH_SEARCH')
      }
    },
    styles () {
      let css = 'background-image: url(' + this.backgroundUrl + ');'
      css += 'width:' + (this.width || 300) + 'px;'
      css += 'height:' + (this.height || 300) + 'px;'
      return css
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.no-data-container {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px auto;
  max-width: 99%;

  .icon {
    width: 64px;
    height: 64px;
  }

  & > p {
    font-weight: 600;
    font-size: 12px;
    max-width: 80%;
    text-align: center;
    margin: 20px auto 0;
  }

  &__title {
    &.large {
      font-size: 14px;
      margin-top: 50px;
    }
  }
  &__subtitle {
    color: $--color-primary;
    font-weight: 600;
    font-size: 12px;
    max-width: 80%;
    margin-top: 10px !important;
  }
  &__btn {
    display: inline-block;
    width: 50%;
    margin: 2rem auto;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .no-data-container {
    max-width: 99%;
    &__btn {
      margin: 1rem auto;
    }
  }
}
</style>
