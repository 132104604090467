<template>
  <div class="loading-file">
    <img
      src="@/assets/img/file.svg"
      alt="processing-file"
      class="loading-file__file"
    >
    <img
      src="@/assets/img/processing-cog.svg"
      alt="processing-file"
      class="loading-file__cog"
    >
  </div>
</template>
<script>
export default {
  name: 'LoadingFile'
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.loading-file {
  margin-top: 20px;
  position: relative;
  height: 65px;

  &__file {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -20px;
  }

  &__cog {
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: 8px;
    -webkit-animation: lf-spin 1.5s infinite linear;
    animation: lf-spin 1.5s infinite linear;
  }

  @keyframes lf-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
