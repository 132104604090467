<template>
  <div class="setup-summary">
    <div class="setup-summary__logo">
      <img
        :src="rocketLogo"
        alt="rocket-logo"
      >
    </div>
    <!-- FILE SETTINGS -->
    <div class="setup-summary__title">
      {{ $t('SETUP.SUMMARY.FILE_SETTINGS_TITLE') }}
    </div>
    <div class="setup-summary__description">
      <div>{{ $t('SETUP.SUMMARY.FILENAME', {filename: fileName }) }}</div>
      <div>{{ $t('SETUP.SUMMARY.PRIMARY_KEYS', {keys: primaryKeys.join(', ')}) }}</div>
    </div>
    <el-alert
      class="setup-summary__alert"
      :title="$t('SETUP.SUMMARY.FILE_SETTINGS_INFO')"
      type="info"
      show-icon
      :closable="false"
    />
    <hr class="setup-summary__separator">

    <!-- OPTINS SETTINGS -->
    <div class="setup-summary__title">
      {{ $t('SETUP.SUMMARY.OPTINS_TITLE') }}
    </div>
    <div class="setup-summary__description ">
      <div v-if="emailOptin">
        {{ $t('SETUP.SUMMARY.OPTIN_EMAIL', {optin_name: emailOptin.name}) }}
      </div>
      <div v-if="smsOptin">
        {{ $t('SETUP.SUMMARY.OPTIN_SMS', {optin_name: smsOptin.name}) }}
      </div>
    </div>
    <div
      v-if="!isActiveList"
      class="setup-summary__description expiration-date"
    >
      <el-switch
        v-model="showExpirationDateForm"
        @change="$emit('show-expiration-date', showExpirationDateForm)"
      />
      <p class="show-expiration">
        {{ $t('SETUP.SUMMARY.SET_EXPIRATION_DATE') }}
        <el-tooltip
          effect="dark"
          :content="$t('LIST_DETAILS.EXPIRATION_DATE_TEXT')"
          placement="top"
        >
          <i class="material-icons help">help_outline</i>
        </el-tooltip>
      </p>
      <div
        v-if="showExpirationDateForm"
        class="expiration-date-form"
        :class="{ 'error': expirationDateError }"
      >
        <label>{{ $t('EXPIRATION_DATE.TITLE') }} : </label>
        <el-date-picker
          v-model="expirationDate"
          :placeholder="$t('EXPIRATION_DATE.PLACEHOLDER')"
          :picker-options="pickerOptions"
          clearable
          @change="setExpirationDate"
        />
        <p
          v-if="expirationDateError"
          class="error-message"
        >
          {{ $t('ERRORS.FORM_FIELD_REQUIRED') }}
        </p>
        <p
          v-if="expirationDate"
          class="expiration-information"
          :class="{'expires-soon' : getExpirationDays() < 15 }"
        >
          {{ $t('EXPIRATION_DATE.EXPIRATION_DAYS') }}
          <span>{{ $tc('EXPIRATION_DATE.EXPIRATION_COUNT', getExpirationDays(), { days: getExpirationDays() }) }}</span>
        </p>
      </div>
    </div>
    <div
      v-if="isActiveList"
      class="setup-summary__settings-description"
    >
      <hr
        class="setup-summary__separator"
      >
      <div class="setup-summary__title">
        {{ $t('SETUP.SUMMARY.IMPORT_SETTINGS_TITLE') }}
      </div>
      <!-- monthly: case every first/second/last xth of the month -->
      <div
        v-if="list.recurrence_day_number && list.recurrence_iso_weekdays != null"
      >
        {{
          $t('SETUP.SUMMARY.IMPORT_SETTINGS_MONTHLY_WEEKDAY', { occurence: monthlyRecurrenceWeeks,
                                                                day: weeklyIsodays.slice(0, -1).toLowerCase(),
                                                                time: listImportTimeStr}
          )
        }}
      </div>
      <!-- monthly: case every xth of the month -->
      <div
        v-else-if="list.recurrence_day_number"
      >
        monthly: case every xth of the month
        {{
          $t('SETUP.SUMMARY.IMPORT_SETTINGS_MONTHLY_DAY', { day: list.recurrence_day_number, time: listImportTimeStr})
        }}
      </div>
      <!-- weekly: every monday/tuesday.. of the month -->
      <div
        v-else-if="list.recurrence_iso_weekdays"
      >
        {{
          $t('SETUP.SUMMARY.IMPORT_SETTINGS_WEEKLY', { days: weeklyIsodays,
                                                       time: listImportTimeStr})
        }}
      </div>
      <div v-if="list.update_type_id">
        {{ list.update_type_id == 1 ? $t('SETUP.SCHEDULE.MODE_1') : $t('SETUP.SCHEDULE.MODE_2') }}
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import rocketLogo from '@/assets/img/rocket.svg'
import { find } from 'lodash-es'
export default {
  name: 'SetupSummary',
  components: { },
  props: {
    step: { type: Number, required: true },
    list: { type: Object, required: true },
    expirationDateError: { type: Boolean, default: false }
  },
  data () {
    return {
      listTypes: config.LIST_TYPES,
      rocketLogo: rocketLogo,
      emailOptin: null,
      smsOptin: null,
      showExpirationDateForm: false,
      expirationDate: null,
      pickerOptions: {
        disabledDate: this.disabledDate
      },
      weekDays: {
        '1': this.$t('SETUP.SCHEDULE.DAYS_1'),
        '2': this.$t('SETUP.SCHEDULE.DAYS_2'),
        '3': this.$t('SETUP.SCHEDULE.DAYS_3'),
        '4': this.$t('SETUP.SCHEDULE.DAYS_4'),
        '5': this.$t('SETUP.SCHEDULE.DAYS_5'),
        '6': this.$t('SETUP.SCHEDULE.DAYS_6'),
        '7': this.$t('SETUP.SCHEDULE.DAYS_7')
      },
      dayMonthlyOccurence: {
        1: this.$t('SETUP.SCHEDULE.DAY_FIRST').toLowerCase(),
        2: this.$t('SETUP.SCHEDULE.DAY_SECOND').toLowerCase(),
        3: this.$t('SETUP.SCHEDULE.DAY_THIRD').toLowerCase(),
        4: this.$t('SETUP.SCHEDULE.DAY_FOURTH').toLowerCase(),
        5: this.$t('SETUP.SCHEDULE.DAY_LAST').toLowerCase()
      }
    }
  },
  computed: {
    isActiveList () {
      return this.list.type_id === this.listTypes.ACTIVE
    },
    primaryKeys () {
      return this.list.fields.filter(field => {return field.primary_key})
      .map(field => { return field.name_in_db })
    },
    weeklyIsodays () {
      if (this.list.recurrence_iso_weekdays.length > 0) {
        var strWeekdays = this.list.recurrence_iso_weekdays.split('|').sort()
        var isodayString = ''
        strWeekdays.forEach((weekday) => {
          isodayString += this.weekDays[weekday] + ', '
        })
        return isodayString.slice(0, -2)
      }
      return ''
    },
    listImportTimeStr () {
      if (this.list.recurrence_import_time && this.list.recurrence_timezone) {
        var listTz = moment().tz(this.list.recurrence_timezone).format('Z')
        return this.list.recurrence_import_time + ` (GMT ${listTz})`
      }
      return ''
    },
    monthlyRecurrenceWeeks () {
      if (this.list.recurrence_day_number) {
        return this.dayMonthlyOccurence[this.list.recurrence_day_number]
      }
      return ''
    },
    fileName () {
      var filename = this.list.filename_pattern
      return this.isActiveList ? filename : filename.substring(21)
    }
  },
  watch: {
    showExpirationDateForm() {
      if (this.showExpirationDateForm) {
        this.expirationDate = null
      }
    }
  },
  created () {
    this.initOptins()
    .then((optins) => {
      let emailOptinField = this.list.fields.filter(field => { return field.optin_type_id != null && field.name_in_db == 'EMAIL'})
      let smsOptinField = this.list.fields.filter(field => { return field.optin_type_id != null && field.name_in_db == 'MOBILE'})
      if (emailOptinField.length > 0) {
        this.emailOptin = find(optins,{'id': emailOptinField[0].optin_type_id})
      }
      if (smsOptinField.length > 0) {
        this.smsOptin = find(optins, {'id': smsOptinField[0].optin_type_id})
      }
    })
  },
  methods: {
    handlePrevious () {
      var previousStep  = this.isActiveList ? 4 : 3
      this.$emit('update:step', previousStep)
    },
    initOptins () {
      return new Promise((resolve, reject) => {
      this.loadingOptins = true
      this.$store.dispatch('groups/getGroupsOptins', this.list.group_id)
        .then((response) => {
          this.loadingOptins = false
          resolve(response.objects)
        })
        .catch(() => {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
          this.loadingOptins = false
          reject()
        })
      })
    },
    moment: function () {
      return moment()
    },
    setExpirationDate(date) {
      const dateStr = moment(date).format("YYYY-MM-DD")
      this.$emit('set-expiration-date', dateStr)
    },
    disabledDate(date) {
      const today = new Date()
      return date < today
    },
    getExpirationDays() {
      const today = moment()
      const expirationDate = moment(this.expirationDate)
      if (expirationDate) {
        return expirationDate.diff(today, 'days') + 1
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--setup-summary-width: 500px;
.setup-summary {
    margin: 24px auto;
    width: $--setup-summary-width;
    &__logo {
      text-align: center;
    }

    &__title {
      color: $--text-dark;
      font-size: 12px;
      font-weight: 600;
      margin: 24px 0px 24px 0px;
    }

    &__description {
      color: $--text-light;
      font-size: 12px;
      &.expiration-date {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .el-switch {
          margin-right: 10px;
        }
        flex-wrap: wrap;
        .show-expiration {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          i {
            margin-left: 5px;
            font-size: 14px;
          }
        }
       
      }
      .error-message {
        display: block;
        width: 100%;
        color: $--color-danger;
      }
      .expiration-date-form {
        width: 100%;
        height: 100px;
        border: 1px solid $--color-secondary-light;
        background-color: white;
        padding: 20px;
        .el-input {
          margin-left: 8px;
          max-width: 150px;
        }
        .expires-information {
          font-size: 12px;
          color: $--color-gray;
          span {
            font-weight: 600;
          }
        }
        .expires-soon {
          span {
            color: $--color-orange;
          }
          
        }
        &.error {
          border-color: $--color-danger;
        }
      }

    }
    &__alert {
      margin: 18px 0px;
      word-break: break-word;

      ::v-deep .el-alert__title {
        font-size: 12px !important;
      }
    }
    &__separator {
      border: none;
      border-bottom: 1px solid $--color-secondary-light;
    }

    &__settings-description {
      margin-top: 24px;
      color: $--text-light;
      font-size: 12px;
      word-break: break-word;
    }
}
</style>
