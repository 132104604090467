<template>
  <div
    class="activity"
  >
    <!-- FILTER -->
    <el-dropdown
      v-if="list.type_id === listTypes.ACTIVE"
      class="activity__dropdown"
      @command="handleFilter"
    >
      <span class="el-dropdown-link">
        <i
          v-if="loading"
          class="el-icon-loading"
        />
        <span v-if="filter === 'all'">
          {{ $t('ACTIVITY.FILTERS.ALL') }}
        </span>
        <span v-if="filter === 'import'">
          {{ $t('ACTIVITY.FILTERS.AUTO_IMPORT') }}
        </span>
        <i class="el-icon-arrow-down el-icon--right" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="all">
          {{ $t('ACTIVITY.FILTERS.ALL') }}
        </el-dropdown-item>
        <el-dropdown-item command="import">
          {{ $t('ACTIVITY.FILTERS.AUTO_IMPORT') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- TIMELINE -->
    <el-timeline
      class="activity-timeline"
    >
      <div
        v-for="(log, indexDate) in displayedLogs"
        :key="indexDate"
      >
        <!-- SECTION OF LOGS PER DAY -->
        <el-timeline-item
          v-if="showItemTimeline(log)"
          :timestamp="indexDate"
          placement="top"
          class="activity-timeline__item"
        >
          <div
            v-for="(item, index) in log"
            :key="index"
          >
            <!-- CARD OF ONE LOG -->
            <el-card
              v-if="(!list.updating || item.id !== getLastImportLog.id)"
              :ref="`card-${item.id}`"
              class="activity-timeline__card"
            >
              <!-- ARROW LEFT FOR THE CARD -->
              <span class="arrow_box" />
              <!-- DATE OF LOG -->
              <div class="activity-timeline__date">
                {{ item.action_date | moment('HH:mm') }}
              </div>
              <!-- CONTENT OF LOG -->
              <div class="activity-timeline__content">
                <!-- LIST CREATION LOG -->
                <div v-if="item.action_type_id === config.REF_ACTION_TYPES.LIST_CREATED">
                  <!-- LIST LOG -->
                  <div v-if="item.entity_type_id === entityTypes.CONTACT_LIST">
                    <span class="text text--blue">{{ $t('ACTIVITY.LIST.CREATED') }}</span>
                    <span>
                      {{ $t('MISC.BY') }}
                      <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                    </span>
                  </div>
                  <!-- LIST FIELDS LOG -->
                  <div v-if="item.entity_type_id === entityTypes.CONTACT_LIST_FIELDS">
                    <span class="text text--blue">{{ $t('ACTIVITY.LIST_FIELDS.CREATED') }}</span>
                    <span>
                      {{ $t('MISC.BY') }}
                      <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                    </span>
                  </div>
                  <!-- LIST AUTO IMPORTED LOG -->
                  <div v-if="isImport(item)">
                    <div class="activity-timeline__card-header">
                      <span
                        v-if="list.type_id === listTypes.ACTIVE"
                        class="text text--blue"
                      >
                        {{ $t('ACTIVITY.LIST.IMPORT_AUTO') }}
                      </span>
                      <span
                        v-else
                        class="text text--blue"
                      >
                        {{ $t('ACTIVITY.LIST.IMPORT_MANUAL') }}
                      </span>
                    </div>
                    <div v-if="item.import_report && item.import_report.error">
                      <div class="activity-timeline__card-error">
                        <i class="material-icons">info_outline</i>
                        <span>
                          {{ getImportError(item) }}
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="item.import_report">
                        <ul>
                          <li>{{ item.import_report.updated_lines }} {{ $tc('ACTIVITY.LINES_UPDATED', item.import_report.updated_lines) }}</li>
                          <li>{{ item.import_report.inserted_lines }} {{ $tc('ACTIVITY.CONTACT_ADDED', item.import_report.inserted_lines) }}</li>
                        </ul>
                      </div>
                      <div class="text-right">
                        <el-button
                          v-if="item.import_report"
                          class="activity-timeline__report-btn"
                          @click="getActivity(item.id, item.entity_type_id)"
                        >
                          {{ $t('ACTIVITY.VIEW_FULL_REPORT') }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <!-- LIST EXPORTED LOG -->
                  <div v-if="item.entity_type_id === entityTypes.EXPORT_LOG">
                    <span class="text text--blue">{{ $t('ACTIVITY.LIST.EXPORTED') }}</span>
                    <span>
                      {{ $t('MISC.BY') }}
                      <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                    </span>
                    <div v-if="item.export_report && item.export_report.error">
                      <div class="activity-timeline__card-error">
                        <i class="material-icons">info_outline</i>
                        <span>
                          {{ $t('ACTIVITY.ERRORS.ERROR_EXPORT') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- LIST UPDATE LOG -->
                <div v-if="item.action_type_id === config.REF_ACTION_TYPES.LIST_UPDATED && item.property">
                  <!-- ACTIVE DEACTIVATE -->
                  <div v-if="item.property === 'active'">
                    <!-- LIST ACTIVATED LOG -->
                    <span
                      v-if="item.new_value === 0"
                      class="text text--red"
                    >
                      {{ $t('ACTIVITY.LIST.DEACTIVATED') }}
                    </span>
                    <span
                      v-else
                      class="text text--blue"
                    >
                      {{ $t('ACTIVITY.LIST.ACTIVATED') }}
                    </span>
                    <span>
                      {{ $t('MISC.BY') }}
                      <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                    </span>
                  </div>
                  <!-- LIST RENAMED -->
                  <div v-if="item.property === 'name'">
                    <span class="text text--blue">
                      {{ $t('ACTIVITY.LIST.LIST_RENAMED') }}
                    </span>
                    <span>
                      {{ $t('MISC.BY') }}
                      <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                    </span>
                    <div class="activity-timeline__change">
                      <span class="text text--gray">{{ item.old_value }}</span>
                      <i class="material-icons">east</i>
                      <span class="text text--blue">{{ item.new_value }}</span>
                    </div>
                  </div>
                  <!-- UPDATED TYPE ID LOG -->
                  <div v-if="item.property === 'update_type_id'">
                    <span class="text text--blue">
                      {{ $t('ACTIVITY.SETUP.IMPORT_MODE') }}
                    </span>
                    <span>
                      {{ $t('MISC.BY') }}
                      <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                    </span>
                    <div class="activity-timeline__change">
                      <span class="text--gray">{{ item.old_value === 1 ? $t('SETUP.SCHEDULE.MODE_1') : $t('SETUP.SCHEDULE.MODE_2') }}</span>
                      <i class="material-icons">east</i>
                      <span class="text--blue">{{ item.new_value === 1 ? $t('SETUP.SCHEDULE.MODE_1') : $t('SETUP.SCHEDULE.MODE_2') }}</span>
                    </div>
                  </div>
                  <!-- RECURENCE TIME LOG -->
                  <div v-if="item.property === 'recurrence'">
                    <span class="text text--blue">{{ $t('ACTIVITY.SETUP.IMPORT_FREQUENCY') }}</span>
                    <span v-if="item.new_value.recurrence_type_id === config.RECURRENCE_MODE.WEEK">
                      {{ $t('ACTIVITY.SETUP.WEEKLY') }}
                      {{ item.new_value.recurrence_iso_weekdays }}
                      {{ $t('MISC.AT') }}
                      [{{ item.new_value.recurrence_import_time }} {{ item.new_value.gmt }}]
                      <span>
                        {{ $t('MISC.BY') }}
                        <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                      </span>
                    </span>
                    <span v-if="item.new_value.recurrence_type_id === config.RECURRENCE_MODE.MONTHLY">
                      <!-- MODE 1 every x (day) of the month-->
                      <span v-if="item.new_value.recurrence_day_number && item.new_value.recurrence_iso_weekdays">
                        {{ $t('ACTIVITY.SETUP.CHANGED_TO') }}
                        {{ item.new_value.recurrence_day_number }} {{ item.new_value.recurrence_iso_weekdays }}
                        {{ $t('ACTIVITY.SETUP.OF_MONTH') }}
                        {{ $t('MISC.AT') }}
                        [{{ item.new_value.recurrence_import_time }} {{ item.new_value.gmt }}]
                        <span>
                          {{ $t('MISC.BY') }}
                          <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                        </span>
                      </span>
                      <!-- MODE 2 every (day number) of the month -->
                      <span v-if="item.new_value.recurrence_day_number && !item.new_value.recurrence_iso_weekdays">
                        {{ $t('ACTIVITY.SETUP.CHANGED_TO') }}
                        {{ item.new_value.recurrence_day_number }}
                        {{ $t('ACTIVITY.SETUP.OF_MONTH') }}
                        {{ $t('MISC.AT') }}
                        [{{ item.new_value.recurrence_import_time }} {{ item.new_value.gmt }}]
                        <span>
                          {{ $t('MISC.BY') }}
                          <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                        </span>
                      </span>
                    </span>
                    <div v-if="item.old_value && item.old_value.recurrence_type_id === config.RECURRENCE_MODE.WEEK">
                      {{ $t('ACTIVITY.PREVIOUSLY') }} {{ item.old_value.recurrence_iso_weekdays }}
                    </div>
                    <div v-if="wasMonthly(item) && item.old_value.recurrence_iso_weekdays">
                      {{ $t('ACTIVITY.PREVIOUSLY') }}
                      {{ item.old_value.recurrence_day_number }} {{ item.old_value.recurrence_iso_weekdays }} {{ $t('ACTIVITY.SETUP.OF_MONTH') }}
                    </div>
                    <div v-if="wasMonthly(item) && !item.old_value.recurrence_iso_weekdays">
                      {{ $t('ACTIVITY.PREVIOUSLY') }} {{ item.old_value.recurrence_day_number }} {{ $t('ACTIVITY.SETUP.OF_MONTH') }}
                    </div>
                  </div>
                  <!-- EXPIRATION DATE LOG -->
                  <div v-if="item.property === 'expiration_date'">
                    <span class="text text--blue">
                      {{ $t('ACTIVITY.LIST.EXPIRATION_DATE_UPDATED') }}
                    </span>
                    <span>
                      {{ $t('MISC.BY') }}
                      <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                    </span>
                    <div class="activity-timeline__change">
                      <span
                        v-if="item.old_value"
                        class="text text--gray"
                      >{{ item.old_value | moment('LL') }}</span>
                      <span
                        v-else
                        class="text text--blue"
                      >-</span>
                      <i class="material-icons">east</i>
                      <span
                        v-if="item.new_value"
                        class="text text--blue"
                      >{{ item.new_value | moment('LL') }}</span>
                      <span
                        v-else
                        class="text text--blue"
                      >-</span>
                    </div>
                  </div>
                  <!-- OTHER PROPERTY -->
                  <div v-if="false">
                    <span class="text text--blue">{{ item.property }}</span>
                    <span>{{ $t('ACTIVITY.CHANGED_FIELD') }}</span>
                    <span>
                      {{ $t('MISC.BY') }}
                      <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                    </span>
                    <div class="activity-timeline__change">
                      <span class="text text--gray">{{ item.old_value }}</span>
                      <i class="material-icons">east</i>
                      <span class="text text--blue">{{ item.new_value }}</span>
                    </div>
                  </div>
                </div>
                <!-- LIST DELETION LOG -->
                <div v-if="item.action_type_id === config.REF_ACTION_TYPES.LIST_DELETED && item.property === 'rows'">
                  <span class="text text--red">
                    {{ item.new_value }} {{ $tc('ACTIVITY.CONTACT_DELETED', item.new_value) }}
                  </span>
                  <span>
                    {{ $t('MISC.BY') }}
                    <span class="text text--gray">{{ getFullName(item.author) }}</span>.
                  </span>
                </div>
              </div>
            </el-card>
          </div>
        </el-timeline-item>
      </div>
    </el-timeline>
    <!-- MODAL INSERTION REPORT LIST -->
    <ModalInsertionReport
      v-if="list && activityId"
      :show-modal.sync="showModalReport"
      :list="list"
      :activity-id.sync="activityId"
      :entity-type-id.sync="entityTypeId"
    />
    <!-- LOADING -->
    <LoadingSpinner
      v-if="(displayedLogs.length === 0 && loading) || loading"
      :title="$t('ACTIVITY.LOADING')"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import config from '@/config'
import { groupBy, map, replace } from 'lodash-es'
import { ModalInsertionReport, LoadingSpinner } from '@/components'
import { getFullName } from '@/filters'

export default {
  name: 'ListActivity',
  components: { ModalInsertionReport, LoadingSpinner },
  data() {
    return {
      loading: false,
      loadingReport: false,
      listTypes: config.LIST_TYPES,
      entityTypes: config.ENTITY_TYPES,
      showModalReport: false,
      filter: 'all',
      logs: [],
      displayedLogs: [],
      currentTotal: 0,
      total: 0,
      page: 1,
      limit: 10,
      activityId: null
    }
  },
  computed: {
    config () {
      return config
    },
    list () {
      return this.$store.getters['lists/list'] || {}
    },
    noMore () {
      return this.currentTotal >= this.total
    },
    disabled () {
      return this.loading || this.noMore
    },
    getLastImportLog () {
      for (var i = 0; i < this.logs.length; ++i) {
        if (this.logs[i].entity_type_id === this.entityTypes.IMPORT_LOG) {
          return this.logs[i]
        }
      }
      return ''
    }
  },
  watch: {
    list (newList, oldList) {
      if (Object.keys(oldList).length && newList.id !== oldList.id) {
        this.displayedLogs = []
        this.getActivities(true)
      }
    }
  },
  created () {
    this.init()
  },
  destroyed() {
    document.getElementById('body-content').removeEventListener('scroll', this.scrollFunction, false)
  },
  methods: {
    getFullName: getFullName,
    init () {
      document.getElementById('body-content').addEventListener('scroll', this.scrollFunction, false)
      this.getActivities()
    },
    isImport (item) {
      return item.entity_type_id === this.entityTypes.IMPORT_LOG || item.entity_type_id === this.entityTypes.API_IMPORT_LOG
    },
    prepareLogs (logs) {
      let updatedLogs = map(logs, (log) => {
        if (log.property === 'recurrence') {
          // Timezone add gmt
          if (log.new_value.recurrence_import_time) {
            log.new_value.gmt = "(GMT " + Vue.moment().tz(log.new_value.recurrence_timezone).format('Z') + ") "
          }
          // Weekly
          // transform the days name
          if (log.new_value.recurrence_type_id === this.config.RECURRENCE_MODE.WEEK || log.new_value.recurrence_type_id === this.config.RECURRENCE_MODE.MONTHLY) {
            if (log.new_value.recurrence_iso_weekdays) {
              for (let i = 1; i <= 7; i++) {
                log.new_value.recurrence_iso_weekdays = replace(log.new_value.recurrence_iso_weekdays, i, `[${this.$t('SETUP.SCHEDULE.DAYS_' + i).substring(0,3)}]`)
              }
            }
            if (log.old_value.recurrence_iso_weekdays) {
              for (let i = 1; i <= 7; i++) {
                log.old_value.recurrence_iso_weekdays = replace(log.old_value.recurrence_iso_weekdays, i, `[${this.$t('SETUP.SCHEDULE.DAYS_' + i).substring(0,3)}]`)
              }
            }
          }
          // Monthly
          if (log.new_value.recurrence_type_id === this.config.RECURRENCE_MODE.MONTHLY) {
            if (log.new_value.recurrence_day_number) {
              let newDayNumber = ''
              switch(log.new_value.recurrence_day_number) {
                case 1:
                  newDayNumber = this.$t('DATES.1')
                  break
                case 2:
                  newDayNumber = this.$t('DATES.2')
                  break
                case 3:
                  newDayNumber = this.$t('DATES.3')
                  break
                default:
                  newDayNumber = log.new_value.recurrence_day_number.toString() + this.$t('DATES.MORE')
                  break
              }
              log.new_value.recurrence_day_number = newDayNumber

            }
          }
          if (log.old_value.recurrence_type_id === this.config.RECURRENCE_MODE.MONTHLY) {
            let oldDayNumber = ''
            if (log.old_value.recurrence_day_number) {
              if (log.old_value.recurrence_day_number <= 3) {
                oldDayNumber = this.$t('DATES.' + log.old_value.recurrence_day_number)
              } else {
               oldDayNumber = log.old_value.recurrence_day_number.toString() + this.$t('DATES.MORE')
              }
              log.old_value.recurrence_day_number = oldDayNumber
            }
          }
        }
        return log
      })
      return updatedLogs
    },
    showItemTimeline(log) {
      return !this.list.updating || log.length !== 1 || log[0].id !== this.getLastImportLog.id
    },
    wasMonthly(item) {
      return (
        item.old_value &&
        item.old_value.recurrence_type_id === this.config.RECURRENCE_MODE.MONTHLY &&
        item.old_value.recurrence_day_number
      )
    },
    scrollFunction () {
      // Infinite scroll
      const bodyContentEl = document.getElementById('body-content')
      const bottomOfWindow = bodyContentEl.scrollHeight - (bodyContentEl.offsetHeight + bodyContentEl.scrollTop) < 2
      if (this.noMore || this.disabled) {
        return false
      }
      if (bottomOfWindow) {
        this.page++
        this.getActivities()
      }
    },
    getActivities (reset = false) {
      if (reset) {
        this.page = 1
      }
      this.loading = true
      const params = {
        list_id: this.list.id,
        p: this.page,
        l: this.limit
      }
      if (this.filter === 'import') {
        params['entity_type_id'] = this.entityTypes.IMPORT_LOG
      }
      this.$store.dispatch('activities/getListActivities', params)
      .then((res) => {
        // Set total
        res.objects = this.prepareLogs(res.objects)
        this.total = res.meta.total
        this.logs = this.logs.length > 0 && !reset ? this.logs.concat(res.objects) : res.objects
        this.currentTotal = this.logs.length
        // Group by log dates
        let logsTmp = groupBy(this.logs, function(log) {
          const date = Vue.moment(log.action_date).format('DD MMM. YYYY')
          return date
        })
        this.displayedLogs = logsTmp
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
      .finally(() => {
        this.loading = false
      })
    },
    getActivity (activityId, entityTypeId) {
      this.activityId = activityId
      this.entityTypeId = entityTypeId
      this.showModalReport = true
    },
    handleFilter (command) {
      this.loading = true
      switch (command) {
        case 'all':
          this.filter = 'all'
          break
        case 'import':
          this.filter = 'import'
          break
      }
      this.getActivities(true)
    },
    toggleCard (item) {
      item['active'] = item.active ? false : true
      var domId = 'card-' + item.id
      var domClass = this.$refs[domId][0].$el.classList
      if (domClass.contains('active')) {
        domClass.remove('active')
      } else {
        domClass.add('active')
      }
      this.$refs[domId][0].classList = domClass
    },
    getImportError (item) {
      var errorMsg = item.import_report.error_msg
      var error = this.$t(`ACTIVITY.ERRORS.${errorMsg}`)
      if (errorMsg === 'ERROR_FILE_NOT_FOUND') {
        var fileName = item.import_report.error_params && item.import_report.error_params.fileName ?
          item.import_report.error_params.fileName :
          this.list.filename_pattern
        error = this.$t('ACTIVITY.ERRORS.ERROR_FILE_NOT_FOUND', {file: fileName})
      }
      return error
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.activity {
  height: calc(100% - 65px);
  position: relative;
  &__dropdown {
    position: absolute;
    right: 0;
    &:hover {
      cursor: pointer;
    }
  }
  overflow: hidden;
}
.activity-timeline {
  @include flexbox;
  @include flex-direction(column);
  max-width: 40%;
  width: 100%;
  height: 100%;
  margin: 5rem auto 2rem auto;
  font-size: 12px;
  ::v-deep &.el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
    display: block;
    height: 90%;
  }

  &__item {
    &:last-child {
      @include flex(1)
    }
    ::v-deep .el-timeline-item {
      padding: 0;
      &__timestamp {
        position: absolute;
        left: -90px;
        font-weight: 600;
        color: $--color-gray;
        text-transform: uppercase;
        font-size: 11px;
        top: 20px;
      }
      &__content {
        min-width: 330px;
        word-break: break-word;
        &:not(:last-child) {
          padding: 1rem 0;
        }
      }
      &__node {
        background: $--color-secondary;
        width: 10px;
        border: 1px solid $--color-medium-gray;
        height: 10px;
        top: 25px;
      }
      &__tail {
        height: 100%;
        left: 3px;
        top: 25px;
        background: $--color-medium-gray;
      }
    }
  }
  &__card {
    margin-bottom: 10px;
    border-width: 2px;
    overflow: visible;
    position: relative;
    color: $--text-dark;
    &.el-card {
      border: 2px solid $--color-light-gray;
      box-shadow: none;
      &__body {
        padding: 5px 10px;
      }
    }
    &:not(:first-child) {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 25px;
        left: -32px;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        border: 2px solid $--color-medium-gray;
        background: $--color-white;
      }
    }
    ul {
      padding-left: 10px;
      list-style: disc;
    }
    &.active {
      .activity-timeline__card-content {
        display: block;
      }
      .activity-timeline__card-btn {
        @include rotate(-180)
      }
    }
    .el-link {
      color: $--color-tertiary;
      border-bottom: 1px solid $--color-tertiary;
    }
  }
  &__card-error {
    @include flexbox;
    background: $--color-danger-lighter;
    color: $--color-danger;
    padding: 15px 10px;
    border-radius: 3px;
    font-size: 12px;
    i {
      margin-right: 10px;
      font-size: 20px;
    }
  }
  &__date {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 10px;
  }
  &__card-header {
    position: relative;
    margin-bottom: 10px;
    padding-right: 30px;
  }
  &__card-content {
    display: none;
  }
  &__card-btn {
    position: absolute;
    right: 0;
    border: 0;
    padding: 10px;
    top: -10px;
    &:hover {
      background: none;
    }
  }
  &__report-btn {
    margin-top: 10px;
    padding: 8px 16px;
  }

  &__change {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    i {
      font-size: 14px;
      margin: 0 5px;
      color: $--text-light;
    }
  }
}

@media only screen and (max-width: $--mobile-width) {
  .activity {
    &__dropdown {
      position: relative;
      text-align: right;
      display: block;
    }
  }
  .activity-timeline {
    max-width: 90%;
    margin-top: 1rem;
    &__item {
      ::v-deep .el-timeline-item {
        &__timestamp {
          position: relative;
          top: 0;
        }
      }
    }
  }
}
</style>
