<!-- eslint-disable vue/no-v-html -->
<template>
  <el-dialog
    custom-class="dialog-modal"
    :visible="dialogVisible"
    :width="width"
    :append-to-body="true"
    :show-close="Boolean(logs)"
    @close="closeModal"
    @opened="onOpened"
  >
    <div
      slot="title"
      class="dialog-modal__title"
    >
      {{ title }}
    </div>

    <!-- Subscribe logs -->
    <div v-if="isLogs">
      <el-table
        v-if="logs.length > 0 || loading"
        v-loading="loading"
        :data="logs"
        fit
      >
        <el-table-column
          v-for="col in logsColumns"
          :key="col.column"
          :label="col.label ? $t(`SUBSCRIBE.LOGS.${col.label}`): null"
          :prop="col.column"
          :width="col.width"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="col.column==='action'"
              class="action"
            >
              {{ scope.row.action_type === 'optout' ? $t('SUBSCRIBE.LOGS.UNSUB') : $t('SUBSCRIBE.LOGS.SUB') }}
            </span>
            <span v-if="col.column === 'date'">{{ scope.row.action_date | moment('LLL') }}</span>
            <span v-if="col.column === 'author'">{{ scope.row.author.first_name }} {{ scope.row.author.last_name }}</span>
            <span v-if="col.column==='reason'">{{ scope.row.details || '...' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <NoData
        v-else-if="logs.length === 0 && !loading"
        type="alternative"
        :text="$t('SUBSCRIBE.LOGS.NO_DATA')"
      />
      <el-pagination
        v-if="logs.length > 0"
        layout="prev, pager, next"
        class="text-center"
        :total="totalLogs"
        :page-size="defaultFilters.limit"
        :current-page.sync="currentPage"
        @current-change="refreshData"
      />
    </div>

    <!-- Subscribe Actions -->
    <div
      v-else
      class="dialog-modal__information"
    >
      <div class="dialog-modal__information__desc">
        {{ $t(description, { to: name }) }}
      </div>
      <el-form
        ref="form"
        :model="form"
        class="dialog-modal__information__form"
      >
        <el-form-item>
          <el-input
            v-model="form.reason"
            size="large"
            type="textarea"
            maxlength="280"
            show-word-limit
            :placeholder="$t(placeholderTrad)"
          />
        </el-form-item>
      </el-form>
    </div>
    <div
      v-if="!isLogs"
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="closeModal">
        {{ $t('BUTTON.CANCEL') }}
      </el-button>
      <el-button
        type="primary"
        :disabled="!form.reason"
        :loading="loading"
        @click="subscribe"
      >
        {{ $t('BUTTON.CONFIRM') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { NoData } from '@/components'
import openBook from '@/assets/img/open-book.svg'

export default {
  name: 'ModalSubscribe',
  components: {
    NoData
  },
  props: {
    dialogVisible: { type: Boolean, default: false },
    isOptin: { type: Boolean, required: true },
    contactId: { type: Number, default: null },
    channel: { type: String, default: null },
    optinId: { type: Number, default: null },
    title: { type: String, required: true },
    width: { type: String, default: '30%' },
    name: { type: String, default: null },
    isLogs: { type: Boolean, default: false }
  },
  data() {
    return {
      openBookLogo: openBook,
      loading: false,
      currentPage: 1,
      totalLogs: 0,
      logs: [],
      form: {
        reason: null
      },
      logsColumns: [
        { column: 'action', width: 150, label: 'ACTION' },
        { column: 'date', width: 150, label: 'DATE' },
        { column: 'author', width: 150, label: 'AUTHOR' },
        { column: 'reason', width: 360, label: 'REASON' }
      ],
      defaultFilters: {
        page: 1,
        limit: 5
      }
    }
  },
  computed: {
    placeholderTrad () {
      return this.isOptin
        ? 'SUBSCRIBE.PLACEHOLDER.UNSUB'
        : 'SUBSCRIBE.PLACEHOLDER.SUB'
    },
    description () {
      return this.isOptin
        ? 'SUBSCRIBE.DESCRIPTION_UNSUB'
        : 'SUBSCRIBE.DESCRIPTION_SUB'
    }
  },
  methods: {
    onOpened () {
      if (this.isLogs) { this.getOptinLogs(this.defaultFilters) }
    },
    subscribe () {
      this.loading = true
      const status = this.isOptin ? 'unsubscribe' : 'subscribe'
      this.$store
        .dispatch(
          `contacts/${ status }`,
          {
            channel: this.channel,
            id: this.contactId,
            optId: this.optinId,
            details: this.form.reason
          }
        ).then(() => {
          this.loading = false
          this.$emit('optin-updated', this.optinId)
          this.form.reason = null
          switch (status) {
            case 'subscribe': {
              this.$notify({
                title: this.$t('SUBSCRIBE.CONFIRM.SUCCESS'),
                message: this.$t('SUBSCRIBE.CONFIRM.OPTIN_SUCCESS'),
                type: 'success'
              })
              break
            }
            case 'unsubscribe': {
              this.$notify({
                title: this.$t('SUBSCRIBE.CONFIRM.SUCCESS'),
                message: this.$t('SUBSCRIBE.CONFIRM.OPTOUT_SUCCESS'),
                type: 'success'
              })
              break
            }
          }
        }).catch(() => {
          this.loading = false
          switch (status) {
            case 'subscribe': {
              this.$notify({
                title: this.$t('SUBSCRIBE.CONFIRM.ERROR'),
                message: this.$t('SUBSCRIBE.CONFIRM.OPTIN_FAIL'),
                type: 'error'
              })
              break
            }
            case 'unsubscribe': {
              this.$notify({
                title: this.$t('SUBSCRIBE.CONFIRM.ERROR'),
                message: this.$t('SUBSCRIBE.CONFIRM.OPTOUT_FAIL'),
                type: 'error'
              })
              break
            }
          }
        })
    },
    refreshData () {
      this.getOptinLogs({
        limit: this.defaultFilters.limit,
        page: this.currentPage
      })
    },
    getOptinLogs (params) {
      this.loading = true
      params.channel = this.$route.params.channel
      params.id = this.$route.params.contactId
      params.optId = this.optinId

      this.$store.dispatch('contacts/getOptinLogs', params)
        .then((res) => {
          this.totalLogs = res.meta.total
          this.logs = res.objects
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          if (err.status === 500) {
            this.$notify({
              title: this.$t('ERRORS.UNEXPECTED'),
              message: this.$t('ERRORS.UNEXPECTED_DESC'),
              type: 'error'
            })
          }
        })
    },
    closeModal () {
      this.logs.length = 0
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
::v-deep .dialog-modal {
  padding: 24px 24px;
  .el-table .cell.el-tooltip {
    white-space: normal;
  }
  .el-pagination {
    padding-top: 20px;
  }
  .el-dialog__body {
    padding-top: 20px;
    padding-bottom: 0;
    .action {
      font-weight: 700;
      opacity: 0.7;
    }
  }
  &__title {
    text-align: left;
    color: $--color-primary;
    font-weight: 600;
  }
  &__information {
    &__desc {
      word-break: break-word;
      margin-bottom: 15px;
    }
    &__form {
      .el-form-item {
        margin-bottom: 20px;
      }
      .el-textarea textarea::placeholder {
        opacity: 0.3;
      }
      .el-textarea__inner {
        height: 120px !important;
      }
    }
  }
}
</style>
