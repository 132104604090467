<template>
  <div class="home__lists">
    <!-- DELETE MODAL -->
    <Modal
      :dialog-visible.sync="showDeleteModal"
      width="500px"
      :title="$tc('DELETE.TITLE_MODAL', selectedIds.length)"
      :subtitle="$tc('DELETE.SUBTITLE_MODAL', selectedIds.length)"
      :btn="$t('BUTTON.CONFIRM')"
      type="danger"
      :action="deleteSelectedLists"
    />

    <!-- SETUP MODAL -->
    <SetupList
      :list="list"
      :show-modal.sync="showSetupModal"
    />

    <!-- FOLDER SELECTION (MOBILE ONLY) -->
    <div class="folder-select">
      <el-select
        v-model="selectedFolder"
        class="select--light select--primary"
        :placeholder="$t('CONTACT_LISTS.ALL_FOLDERS')"
        @change="onFolderChange"
      >
        <el-option
          v-for="folder in folders"
          :key="folder.id"
          :label="folder.name"
          :value="folder"
        />
      </el-select>
    </div>

    <!-- FILTERS AND ACTION BUTTONS -->
    <HomeActions
      tab-index="lists"
      :on-delete="askDeleteConfirmation"
      :on-setup="onShowSetupModal"
      :secondary-action="moveSelected"
      :secondary-action-label="secondaryActionLabel"
      :on-filters-change="onFiltersChange"
      :disabled="deleting"
      :selected="selected"
      :search-placeholder="$t('FILTER.SEARCH_LIST')"
      @search-updated="onFiltersChange"
      @filters-updated="onFiltersChange"
    />
    <!-- ALERT : LIST(s) EXPIRING SOON -->
    <el-alert
      v-if="expiringSoon"
      type="warning"
      :title="$t('LISTS.WARNING_EXPIRING_SOON')"
      :closable="true"
      show-icon
    />
    <!-- LISTS: DESKTOP VIEW -->
    <div class="table-container">
      <!-- CURRENT FOLDER -->
      <el-breadcrumb
        v-if="selectedFolder"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item to="folders">
          {{ $t('CONTACT_LISTS.ALL_FOLDERS') }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ selectedFolder.name }}</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- TABLE -->
      <el-table
        ref="listsTable"
        v-loading="listsLoading || deleting"
        :data="lists"
        :default-sort="orderBy"
        @selection-change="(val) => selected = val"
        @sort-change="onSortChange"
        @row-click="onRowClick"
      >
        <div slot="empty">
          <NoData
            :type="filtersActive ? 'alternative' : 'default'"
          />
        </div>
        <el-table-column
          type="selection"
          :selectable="userCanEdit"
        />
        <el-table-column
          width="40"
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.group"
              effect="dark"
              :content="scope.row.group.name"
              placement="top"
            >
              <AvatarHolder
                :avatar-url="scope.row.group.logo_url"
                :display-name="scope.row.group.name"
                :width="25"
                :height="25"
                :font-size="8"
              />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.NAME')"
          width="200"
        >
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="scope.row.name"
              placement="top"
            >
              <span
                class="table-container__list-name"
              >
                {{ scope.row.name }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.TYPE')"
          width="120"
        >
          <template slot-scope="scope">
            <ListType :type-id="scope.row.type_id" />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.CONTACTS')"
          property="contacts_count"
        >
          <template slot-scope="scope">
            <span class="text-tertiary">
              {{ scope.row.contacts_count || 0 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.LAST_UPDATE')"
          property="last_log_date"
          width="200"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.last_log_date">
              {{ scope.row.last_log_date | moment('LLL') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.EXPIRATION_DATE')"
          sortable="custom"
          property="expiration_date"
          width="200"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.expiration_date"
              :class="{ 'expiring-soon': isExpiringSoon(scope.row)}"
            >
              {{ scope.row.expiration_date | moment('LL') }}
            </span>
            <span v-else>
              -
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('LISTS.AUTHOR')"
        >
          <template slot-scope="scope">
            <el-tooltip
              :key="scope.row.id"
              effect="dark"
              :content="getFullName(scope.row.author)"
              placement="top"
            >
              <AvatarHolder
                :avatar-url="scope.row.author ? scope.row.author.avatar_url : noAuthor"
                :display-name="scope.row.author ? getFullName(scope.row.author) : null"
                :width="25"
                :height="25"
                :font-size="8"
              />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              type="text"
              class="text-secondary"
              :disabled="!userCanEdit(scope.row)"
              @click.stop="askDeleteConfirmation(scope.row.id)"
            >
              {{ $t('BUTTON.DELETE') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- PAGINATION -->
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :current-page.sync="page"
        class="text-center"
        @current-change="getLists"
      />
    </div>

    <!-- LISTS: MOBILE VIEW -->
    <div
      class="simple-view-container"
    >
      <HomeListItem
        v-for="(l, idx) in lists"
        :key="l.id"
        :item="l"
        :index="idx"
      />
      <NoData
        v-if="lists.length === 0 && !listsLoading"
        :type="filtersActive ? 'alternative' : 'default'"
      />
      <!-- LOADING -->
      <LoadingSpinner
        v-if="listsLoading"
        :title="$t('LISTS.FETCHING_LISTS')"
      />
    </div>
  </div>
</template>

<script>
import { map, findKey, debounce } from 'lodash-es'
import {
  HomeActions,
  AvatarHolder,
  ListType,
  HomeListItem,
  NoData,
  Modal,
  LoadingSpinner
} from '..'
import { SetupList } from '..'
import { getInitials, getFullName } from '@/filters'
import config from '@/config'
import NoAuthor from '@/assets/img/no-author.svg'

export default {
  name: 'HomeLists',
  components: {
    HomeActions,
    AvatarHolder,
    ListType,
    HomeListItem,
    NoData,
    Modal,
    LoadingSpinner,
    SetupList
  },
  data () {
    return {
      loading: false,
      selected: [],
      selectedIds: [],
      selectedFolder: null,
      lists: [],
      page: 1,
      total: 0,
      currentTotal: 0,
      limit: 10,
      orderBy: null,
      filters: {
        group: [],
        name: null,
        type: '1,2',
        author: null
      },
      showDeleteModal: false,
      isMobile: false,
      reset: false,
      noAuthor: NoAuthor,
      showSetupModal: false
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user'] || {}
    },
    folders () {
      return this.$store.getters['folders/folders'] || []
    },
    secondaryActionLabel () {
      return this.selectedFolder ?
        this.$t('BUTTON.REMOVE_FROM_FOLDER') :
        this.$t('BUTTON.ADD_TO_FOLDER')
    },
    listsLoading () {
      return this.$store.getters['lists/loading']
    },
    deleting () {
      return this.$store.getters['lists/deleting']
    },
    momentSetup () {
      return {
        sameDay: '[' + this.$t('MISC.TODAY') + '], HH:mm',
        lastDay: '[' + this.$t('MISC.YESTERDAY') + '], HH:mm',
        sameYear: 'D MMM, HH:mm',
        sameElse: 'D MMM YYYY, HH:mm'
      }
    },
    filtersActive () {
      let keyWithValue = findKey(this.filters, function(val) {
        return val !== null && val !== undefined && val !== ''
      })
      return keyWithValue ? true : false
    },
    noMore () {
      return this.currentTotal >= this.total
    },
    list() {
      return this.$store.getters['lists/list']
    },
    expiringSoon() {
      return this.$store.getters['lists/expiringSoon']
    },
  },
  created () {
    // Listening to window resizing to detect mobile or desktop
    window.addEventListener('resize', this.resizingFunction, false)
    window.addEventListener('scroll', this.scrollFunction, false)

    // Detect if mobile
    if (window.innerWidth <= 1024) {
      this.isMobile = true
      this.limit = 20
    }
    // extract query params
    this.extractQueryParams()
    // load lists
    this.getLists()
  },
  destroyed () {
    window.removeEventListener('resize', this.resizingFunction, false)
    window.removeEventListener('scroll', this.scrollFunction, false)
  },
  methods: {
    getInitials: getInitials,
    getFullName: getFullName,
    resetLists (params) {
      this.lists = []
      this.isMobile = params.isMobile
      this.page = params.page
      this.limit = params.limit
      this.getLists()
    },
    resizingFunction () {
      // Listening to window resizing to detect mobile or desktop
      if (window.innerWidth <= 1024) {
        // If is mobile width and not loading yet, reset lists
        if (!this.isMobile && !this.loading) {
          // Limit is 20 for mobile in order to do infinite scroll
          const params = {
            isMobile: true,
            page: 1,
            limit: 20
          }
          this.resetLists(params)
        }
      } else if (window.innerWidth > 1024) {
        if (this.isMobile && !this.loading) {
          // If resize to desktop, reset all params
          const params = {
            isMobile: true,
            page: 1,
            limit: 10
          }
          this.resetLists(params)
          window.removeEventListener('scroll', this.scrollFunction, false)
        }
      }
    },
    scrollFunction () {
      // Infinite scroll
      const bottomOfWindow = (window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight
      if (this.noMore) {
        return false
      }
      // If scrolled down to bottom of page and not still loading, get lists
      if (bottomOfWindow && this.isMobile && !this.listsLoading && !this.loading) {
        this.page++
        this.getLists()
      }
    },
    askDeleteConfirmation (id) {
      if (!this.selected && !id) {
        // nothing to delete
        return
      }
      // set selected ids (ids which will be deleted)
      if (id) {
        this.selectedIds = [id]
      } else {
        this.selectedIds = map(this.selected, (list) => { return list.id })
      }
      // show modal
      this.showDeleteModal = true
    },
    deleteSelectedLists () {
      // dispatch request
      this.$store.dispatch('lists/bulkDelete', this.selectedIds)
      .then((deletedCount) => {
        // check if all lists have been deleted
        if (deletedCount < this.selectedIds.length) {
          let msgKey = this.selectedIds.length > 1 ? 'DELETE.ERROR_MULTIPLE' : 'DELETE.ERROR_SINGLE'
          this.$message.error(this.$t(msgKey))
        }
        // close modal
        this.showDeleteModal = false
        // reload lists
        this.getLists()
        // Notify success
        this.$notify({
          title: this.$t('MISC.SUCCESS'),
          message: this.$tc('DELETE.SUCCESS_MSG', this.selectedIds.length),
          type: 'success'
        })
      })
    },
    moveSelected () {
      if (this.selectedFolder) {
        // TODO
        // console.log('TODO: remove list from ' + this.selectedFolder)
        // reload lists only if navigating in a folder, as it doesn't impact
        // the "All lists" view
        this.getLists()
      } else {
        // TODO
        // console.log('TODO: add list to folder ' + this.selectedFolder)
      }
    },
    getLists () {
      this.loading = true
      // unselect any selected lists
      this.selected = []
      // format orderBy
      let ob = null
      if (this.orderBy) {
        ob = this.orderBy.prop
        if (this.orderBy.order === 'descending') {
          ob = '-' + ob
        }
      }

      // format request data
      let data = {
        'page': this.page,
        'orderBy': ob,
        'limit': this.limit,
        'search': this.filters.name,
        'groupId': this.filters.group,
        'authorId': this.filters.author,
        'typeId': this.filters.type ? this.filters.type : '1,2'
      }

      // dispatch request
      this.$store.dispatch('lists/getLists', data)
      .then((res) => {
        // If mobile version and not loading and no filters name, infinite scroll --> add res to current lists
        if (this.isMobile && !this.filters.name && !this.reset) {
          this.lists = this.lists.concat(res.objects)
          this.currentTotal = this.lists.length
        } else {
          this.lists = res.objects
        }
        this.total = res.meta.total
      })
      .catch(() => {
        this.lists = []
        this.total = 0
      })
      .finally(() => {
        this.loading = false
        // reset to false
        this.reset = false
      })
    },
    exportList (row) {
      // TODO
      // console.log('TODO - export list ' + row.id)
      return row.id
    },
    userCanEdit (row) {
      if (row.type_id === config.LIST_TYPES.ACTIVE && row.active) return false
      var group = this.$store.getters['groups/groups'].find((x) => x.id == row.group_id)
      var isRowAuthor = row.created_by === this.user.id && group['permissions'].indexOf(config.PERMISSIONS.LIST_WRITE_SELF) != -1
      return (group &&
              (isRowAuthor || group['permissions'].indexOf(config.PERMISSIONS.LIST_WRITE) != -1)
             )
    },
    onSortChange (orderBy) {
      // set orderBy
      let ob = ''
      if (!orderBy.order) {
        this.orderBy = {}
      } else {
        this.orderBy = orderBy
        ob = orderBy.prop + ',' + orderBy.order
      }
      // append orderBy to query string
      let queryString = Object.assign({}, this.$route.query, {'ob': ob})
      this.$router.push({ query: queryString }).catch(() => {})
      // if params have changed, reloading lists will be triggered by beforeRouteUpdate
    },
    onFiltersChange () {
      this.reset = true
      // reinit page to 1
      this.page = 1
      // reset lists if mobile
      if (this.isMobile) {
        this.lists = []
      }
      // append filters to query string
      this.$store.dispatch('lists/getQueryParams').then((query) => {
        this.$router.push({ query }).catch(() => {})
      })
      // if params have changed, reloading lists will be triggered by beforeRouteUpdate
    },
    onFolderChange () {
      // TODO
      // update state with folder parameter
    },
    extractQueryParams () {
      // extract orderBy from query
      let orderBy = (this.$route.query.ob || '').split(',')
      if (orderBy.length === 2) {
        this.orderBy = { prop: orderBy[0], order: orderBy[1] }
      } else {
        this.orderBy = {}
      }
      // extract other filters as well
      let queryParams = this.$route.query
      for (var key in this.filters) {
        if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
          this.filters[key] = queryParams[key]
        } else {
          this.filters[key] = null
        }
      }
    },
    onRowClick (item) {
      // go to the details page
      this.$router.push({ path: `/lists/${item.id}/details` })
    },
    onShowSetupModal() {
      this.$store.dispatch('lists/resetList')
      debounce(() => {
        this.showSetupModal = true
      }, 100)()
    },
    isExpiringSoon(list) {
      const today = moment()
      const expirationDate = moment(list.expiration_date)
      if (expirationDate) {
        return expirationDate.diff(today, 'days') < 15
      }
      return false
    },
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.path != from.path || to.query != from.query) {
      // extract query params
      this.extractQueryParams()
      // reload lists
      this.getLists()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.home__lists {
  .avatar-holder {
    line-height: 20px;
    display: inline-flex;
    vertical-align: middle;
  }

  .list__type-slot {
    display: inline-flex;
    vertical-align: middle;
    line-height: 20px;

    > i.material-icons {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  ::v-deep .el-table__header {
    .cell {
      font-weight: 600
    }
  }

  ::v-deep .el-table__row {
    cursor: pointer;

    > td:last-child {
      text-align: right;
    }
  }

  ::v-deep .el-table .cell {
    text-overflow: unset;
  }

  ::v-deep .el-table__empty-text {
    color: inherit;
    line-height: inherit;
  }

  .folder-select,
  .simple-view-container {
    display: none;
  }

  .simple-view-container {
    background-color: $--color-white;
    border: 1px solid $--border-color-base;
    margin: 5px;
    padding: 10px;
  }
}

.el-alert {
  margin: 20px auto;
}
.table-container {
  &__list-name {
    overflow: hidden;
    height: 20px;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .expiring-soon {
    color: $--color-orange;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .home__lists {
    .table-container {
      display: none;
    }

    .folder-select,
    .simple-view-container {
      display: block;
    }

    .folder-select {
      width: 100%;
      text-align: center;
      padding: 0 50px;
      margin-bottom: 20px;
      display: none; // TODO: remove when folders feature will be active
    }
  }
}
</style>
