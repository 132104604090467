import Vue from 'vue'
import config from '@/config'

const SET_USERS = 'SET_USERS'
const SET_LOADING = 'SET_LOADING'

const moduleUsers = {
  namespaced: true,
  state: {
    users: [],
    loading: false
  },
  mutations: {
    [SET_USERS] (state, users) {
      Vue.set(state, 'users', users)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    getUsers ({ commit }) {
      commit(SET_LOADING, true)
      const url = config.API_URL + '/users'
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_USERS, response.data.objects)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
  },
  getters: {
    users: state => state.users,
    loading: state => state.loading
  }
}

export default moduleUsers
