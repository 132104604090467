<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    :width="getWidth"
    :append-to-body="true"
    custom-class="modal"
    :show-close="false"
    @close="closeModal"
  >
    <span v-if="subtitle">{{ subtitle }}</span>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="closeModal">{{ $t('BUTTON.CANCEL') }}</el-button>
      <el-button
        :type="getType"
        :loading="loading"
        @click="action(actionArgs)"
      >
        {{ btn }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    dialogVisible: { type: Boolean, default: false },
    width: { type: String, default: '30%'},
    title: { type: String, default: '', required: true },
    subtitle: { type: String, default: '' },
    btn: { type: String, default: '', required: true },
    type: { type: String, default: '' },
    action: { type: Function, default: null, required: true },
    loading: { type: Boolean, default: false },
    actionArgs: { type: String, default: null, required: false }
  },
  computed: {
    getWidth () {
      return this.width ? this.width : '30%'
    },
    getType () {
      return this.type ? this.type : 'primary'
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
::v-deep .modal {
  .el-dialog__title {
    color: $--color-primary;
    font-weight: 600;
  }
}
</style>
