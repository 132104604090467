import Vue from 'vue'
import config from '@/config'

/* eslint camelcase: ["error", {properties: "never"}] */

const CLEAR_SESSION = 'CLEAR_SESSION'
const SET_USER = 'SET_USER'

const moduleAuth = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    [SET_USER] (state, user) {
      Vue.set(state, 'user', user)
    },
    [CLEAR_SESSION] (state) {
      Vue.set(state, 'user', null)
    }
  },
  actions: {
    getProfile ({ commit, dispatch }) {
      const url = config.API_URL + '/sessions'
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status === 200) {
              dispatch('clients/setClient', response.data.client, { root: true })
              dispatch('snapcall/setSnapcallId', response.data.client.configs.snapcall_id, { root: true })
              // Set global timezone otherwise leave the default
              if (response.data.timezone) {
                Vue.moment.tz.setDefault(response.data.timezone)
              }
              commit(SET_USER, response.data)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    clearSession ({ commit }) {
      commit(CLEAR_SESSION)
    },
    logout ({ commit }) {
      commit(CLEAR_SESSION)
      // redirect to the logout page
      window.location = '/api/logout'
    }
  },
  getters: {
    user: state => state.user,
    hasPermissionInGroup: (state) => (groupId, perm) => {
      if (!state.user) {
        return false
      }
      return state.user.groups[groupId] &&
        state.user.groups[groupId].indexOf(perm) !== -1
    },
    hasPermission: (state, getters) => (perm) => {
      if (!state.user) {
        return false
      }
      let permSplitted = perm.split(':')
      if (permSplitted.length < 3) {
          return false
      }
      switch (permSplitted[1]) {
        case 'user':
          return state.user.permissions.indexOf(perm) !== -1
        case 'role': {
          for (var groupId in state.user.groups) {
            const res = getters.hasPermissionInGroup(groupId, perm)
            if (res) {
              return true
            }
          }
          return false
        }
        default:
          return false
      }
    }
  }
}

export default moduleAuth
