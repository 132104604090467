<template>
  <div>
    <div
      class="notification"
      @click="setAsRead"
    >
      <i class="alert material-icons">
        error
      </i>
      <div class="notification__content">
        <div class="title">
          {{ $t('NOTIFICATION.UPDATE_ERROR') }}
          <el-badge
            v-if="!read"
            is-dot
          />
        </div>
        <div>
          {{ createdAt | moment('LLL') }}
        </div>
        <div class="description">
          {{ $t('NOTIFICATION.CONTENT', { name: listName }) }}
        </div>
        <el-button
          type="default"
          size="default"
          @click.stop="goToList"
        >
          {{ $t('BUTTON.SEE_LIST') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      listName: {
        type: String,
        required: true
      },
      listId: {
        type: Number,
        required: true
      },
      read: {
        type: Boolean,
        required: true
      },
      createdAt: {
        type: String,
        required: true
      },
      importLogId: {
        type: Number,
        required: true
      }
    },
    methods: {
      goToList() {
        this.$store.dispatch('lists/getList', this.listId).then(() => {
          this.$router.push({ path: `/lists/${this.listId}/activity` })
            .catch(() => {})
        })
        this.$emit('closePopover')
        this.setAsRead()
      },
      setAsRead() {
        const notification = {
          list_id: this.listId,
          import_log_id: this.importLogId,
          read: this.read,
        }
        this.$emit('setAsRead', notification)
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.notification {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 15px;
  .alert {
    font-size: 24px;
    color: $--color-warning;
    margin-right: 14px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #707070;
    .description {
      word-break: break-word;
    }
    .el-badge {
      vertical-align: -webkit-baseline-middle;
    }
    ::v-deep .el-badge__content.is-dot{
      width: 8px;
      height: 8px;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .el-button {
      width: fit-content;
    }
  }
}
</style>
