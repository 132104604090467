<template>
  <el-dialog
    v-loading="loadingReport"
    custom-class="list-report"
    :append-to-body="true"
    :fullscreen="true"
    :show-close="false"
    :visible="showModal"
    center
    @close="closeModal"
  >
    <!-- TITLE -->
    <div
      slot="title"
    >
      <el-button
        size="medium"
        class="list-report__exit-btn"
        @click="closeModal()"
      >
        {{ $t('BUTTON.EXIT') }}
      </el-button>
      <h2 class="title">
        {{ $t('REPORT.TITLE') }}
      </h2>
      <div class="subtitle">
        {{ list.name }}
      </div>
    </div>

    <!-- REPORT -->
    <div
      v-if="report"
      class="list-report__content"
    >
      <!-- # DATE ROW # -->
      <el-row :gutter="24">
        <el-col :span="6">
          {{ $t('REPORT.IMPORT_DATE') }}
        </el-col>
        <el-col :span="18">
          {{ report.start_date | moment('DD/MM/YY, HH:mm') }}
        </el-col>
      </el-row>
      <!-- # SOURCE FILE ROW # -->
      <el-row
        v-if="!isImportedByApi"
        :gutter="24"
      >
        <el-col :span="6">
          {{ $t('REPORT.SOURCE_FILE') }}
        </el-col>
        <el-col :span="18">
          {{ report ? sourceFileName : '' }}
        </el-col>
      </el-row>
      <!-- # INITIAL VOLUME ROW # -->
      <el-row
        v-if="!isImportedByApi"
        :gutter="24"
      >
        <el-col :span="12">
          {{ $t('REPORT.INITIAL_VOLUME') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.initial_volume : '' }}
        </el-col>
      </el-row>
      <!-- # PROCESSED LINES ROW # -->
      <el-row :gutter="24">
        <el-col :span="12">
          {{ $t('REPORT.PROCESSED_LINES') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.processed_lines : '' }}
        </el-col>
      </el-row>
      <!-- # REJECTED LINES ROW # -->
      <el-row
        v-if="isImportedByApi"
        :gutter="24"
      >
        <el-col :span="12">
          {{ $t('REPORT.DELETED_LINES') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.deleted_lines : '' }}
        </el-col>
      </el-row>
      <el-row
        v-else
        :gutter="24"
      >
        <el-col :span="12">
          {{ $t('REPORT.REJECTED_LINES') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.rejected_lines : '' }}
        </el-col>
      </el-row>
      <!-- # UPDATED CONTACTS ROW # -->
      <el-row :gutter="24">
        <el-col :span="12">
          {{ $t('REPORT.UPDATED_CONTACTS') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.updated_lines : '' }}
        </el-col>
      </el-row>
      <!-- # NEW CONTACTS ROW # -->
      <el-row :gutter="24">
        <el-col :span="12">
          {{ $t('REPORT.NEW_CONTACTS') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.inserted_lines : '' }}
        </el-col>
      </el-row>
      <!-- # INCORRECT EMAIL ROW # -->
      <el-row :gutter="24">
        <el-col :span="12">
          {{ $t('REPORT.INCORRECT_EMAIL') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.invalid_emails : '' }}
        </el-col>
      </el-row>
      <!-- # INCORRECT MOBILE ROW # -->
      <el-row :gutter="24">
        <el-col :span="12">
          {{ $t('REPORT.INCORRECT_MOBILE') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.invalid_phone_numbers : '' }}
        </el-col>
      </el-row>
      <!-- # EMAIL OPTINS ROW # -->
      <el-row :gutter="24">
        <el-col :span="12">
          {{ $t('REPORT.EMAIL_OPTINS') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.new_optins_email : '' }}
        </el-col>
      </el-row>
      <!-- # MOBILE OPTINS ROW # -->
      <el-row :gutter="24">
        <el-col :span="12">
          {{ $t('REPORT.MOBILE_OPTINS') }}
        </el-col>
        <el-col :span="12">
          {{ report ? report.new_optins_sms : '' }}
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import config from '@/config'

export default {
  name: 'ModalInsertionReport',
  components: { },
  props: {
    showModal: { type: Boolean, required: true },
    list: { type: Object, required: true },
    activityId: { type: Number, default: null },
    entityTypeId: { type: Number, default: null }
  },
  data() {
    return {
      loadingReport: false,
      report: null
    }
  },
  computed: {
    isImportedByApi () {
      return this.entityTypeId === config.ENTITY_TYPES.API_IMPORT_LOG
    },
    sourceFileName() {
      return this.report.file_name || this.report.source_file
    }
  },
  created () {
    this.getActivity()
  },
  methods: {
    closeModal () {
      this.$emit('update:activityId', null)
      this.$emit('update:entityTypeId', null)
      this.$emit('update:showModal', false)
    },
    getActivity () {
      this.loadingReport = true
      const params = {'entity_type_id': this.entityTypeId}
      const activityId = this.activityId
      this.$store.dispatch('activities/getActivity', { activityId, params })
      .then((report) => {
        // Replace null values by zeros
        Object.keys(report).forEach(function(key) {
          if (report[key] === null && key !== 'source_file') {
              report[key] = 0
          }
        })
        this.report = report
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
      .finally(() => {
        this.loadingReport = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--mobile-width: 750px;

::v-deep .list-report {
  background: $--color-bg-light-gray;
  .el-dialog__header {
    padding-top: 48px;
  }
  .title {
    margin: 10px;
    font-size: 36px;
    font-weight: 300;
  }
  .subtitle {
    margin: 10px;
    font-size: 21px;
    font-weight: 400;
  }
  &__exit-btn {
    position: absolute;
    top: 36px;
    right: 44px;
  }

  &__content {
    font-size: 12px;
    max-width: $--mobile-width;
    width: 50%;
    margin: 0 auto;
    .el-row {
      padding: 1rem;
      border-bottom: 2px solid $--color-light-gray;
      .el-col:nth-child(1) {
        color: $--color-gray;
      }
      .el-col:nth-child(2) {
        text-align: right;
      }
    }
  }
}

@media only screen and (max-width: $--mobile-width) {
  ::v-deep .list-report {
    .title {
      margin-top: 2rem
    }
    &__content {
      max-width: none;
      width: 100%;
    }
  }
}
</style>
