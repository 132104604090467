<template>
  <div class="list-details-main">
    <!-- LIST DETAILS MAIN HEADER -->
    <div class="list-details-main__content header">
      <el-button
        class="list-details-main__back"
        size="medium"
        @click="backToLists"
      >
        <i class="el-icon-arrow-left" />
        {{ $t('BUTTON.BACK_LISTS') }}
      </el-button>
    </div>
    <!-- LIST DETAILS MAIN INFOS -->
    <div class="list-details-main__content">
      <h1
        v-loading="loadingName"
        class="list-details-main__name"
      >
        <span
          ref="listNameSpan"
          @click="toggleEditMode(true)"
        >
          {{ newName }}
        </span>
        <el-input
          v-show="editNameMode && hasCreationPermission"
          ref="listNameInput"
          v-model="newName"
          type="textarea"
          maxlength="45"
          minlength="1"
          :placeholder="list.name"
          :class="{ active: editNameMode }"
          @keydown.native.exact="changeInputNameHeight"
          @blur="toggleEditMode(false)"
          @change="updateName"
        />
        <div
          v-if="isContactList"
          class="list-details-main__uid"
        >
          {{ $t('LIST_DETAILS.UID', {uid: list.uid}) }}
        </div>
      </h1>

      <div class="list-details-main__type">
        <ListType
          v-if="list.type_id && isContactList"
          :type-id="list.type_id"
        />
        <span
          class="list-details-main__count"
        >
          •
        </span>
        <span
          v-if="extendedList"
          class="list-details-main__count"
        >
          <span v-if="isContactList">
            {{ $tc('LIST_DETAILS.CONTACTABILITY_CHART_TOOLTIP', contactsCount, {'contacts': contactsCount}) }}
          </span>
          <span v-else>
            {{ $tc('LIST_DETAILS.SUBMISSION_CHART_TOOLTIP', contactsCount, {'submissions': contactsCount}) }}
          </span>
        </span>
      </div>
      <div v-if="list.last_log_date">
        {{ $t('MISC.LAST_MODIFIED') }} {{ list.last_log_date | moment('LLL') }}
      </div>
      <div class="list-details-main__author">
        {{ $t('MISC.CREATED_BY') }}
        <el-tooltip
          v-if="extendedList"
          class="item"
          effect="dark"
          :content="getFullName(extendedList.author)"
          placement="top"
        >
          <AvatarHolder
            class="list-details-main__author-span"
            :avatar-url="extendedList.author ? extendedList.author.avatar_url : noAuthor"
            :display-name="extendedList.author ? getFullName(extendedList.author) : null"
            :width="20"
            :height="20"
            :font-size="8"
          />
        </el-tooltip>
        {{ $t('MISC.IN') }}
        <span
          v-if="extendedList"
          class="list-details-main__author-span"
        >
          <el-tooltip
            :content="extendedList.group.name"
            :disabled="disabledTooltipGroup"
            placement="bottom"
            effect="dark"
          >
            <div
              ref="groupName"
              class="list-details-main__author-span__group"
            >
              {{ extendedList.group.name }}
            </div>
          </el-tooltip>
        </span>
      </div>
    </div>
    <!-- LIST DETAILS EXPIRATION DATE -->
    <div
      v-if="isStaticList"
      class="list-details-main__content"
    >
      <div class="top">
        <p class="text text--semi-bold">
          {{ $t('EXPIRATION_DATE.TITLE') }}
          <el-tooltip
            effect="dark"
            :content="$t('LIST_DETAILS.EXPIRATION_DATE_TEXT')"
            placement="top"
          >
            <i class="material-icons help">help_outline</i>
          </el-tooltip>
        </p>
        <el-button
          v-if="!expirationDisabled"
          type="text"
          @click="showExpirationDateModal = true"
        >
          <i class="material-icons">edit</i>
          {{ $t('BUTTON.EDIT') }}
        </el-button>
      </div>

      <div
        class="expiration-date"
      >
        <span
          v-if="isExpiringSoon"
          class="expires-soon-badge"
        >
          {{ $t('LIST_DETAILS.EXPIRES_SOON') }}
        </span>
        <span v-if="list.expiration_date"> {{ list.expiration_date | moment('LL') }}</span>
        <span
          v-else
          class="text--gray"
        >{{ $t('LIST_DETAILS.NO_EXPIRATION_DATE') }}</span>
      </div>
    </div>
    <!-- LIST DETAILS MAIN CHARTS -->
    <div
      v-if="isContactList"
      class="list-details-main__content"
    >
      <p class="text text--semi-bold">
        {{ $t('LIST_DETAILS.CONTACTABILITY') }}
        <el-tooltip
          effect="dark"
          :content="$t('LIST_DETAILS.CONTACTABILITY_TEXT')"
          placement="top"
        >
          <i class="material-icons help">help_outline</i>
        </el-tooltip>
      </p>
      <p
        v-if="extendedList && extendedList.last_log_date"
        class="text text--light text--small"
      >
        {{ $t('MISC.UPDATED_ON') }} {{ extendedList.last_log_date | moment('DD MMM YYYY, HH:mm') }}
      </p>
      <div
        class="list-details-main__charts"
        :class="{'justify-center': !displayBothDonuts}"
      >
        <div>
          <vc-donut
            v-if="fieldsList.indexOf('MOBILE') > -1"
            background="white"
            foreground="#F1F2F3"
            :size="100"
            unit="px"
            :thickness="20"
            has-legend
            legend-placement="top"
            :sections="chartSMS"
            :total="100"
            :start-angle="0"
          >
            <el-tooltip
              effect="dark"
              :content="$tc('LIST_DETAILS.CONTACTABILITY_CHART_TOOLTIP', extendedList.sms_optins ? extendedList.sms_optins : 0, {'contacts': extendedList.sms_optins ? extendedList.sms_optins : 0})"
              placement="top"
            >
              <span>{{ getOptinsPercentage(extendedList.sms_optins) }}%</span>
            </el-tooltip>
          </vc-donut>
          <div
            v-if="optinSms"
            class="optin"
          >
            <div>{{ $t('LIST_DETAILS.OPTIN_NAME') }}</div>
            <el-tooltip
              :content="optinSms"
              :disabled="disabledTooltipOptinSms"
              placement="bottom"
              effect="dark"
            >
              <!-- content to trigger tooltip here -->
              <div
                ref="optinSms"
                class="optin-name"
              >
                {{ optinSms }}
              </div>
            </el-tooltip>
          </div>
        </div>
        <div>
          <vc-donut
            v-if="fieldsList.indexOf('EMAIL') > -1"
            background="white"
            foreground="#F1F2F3"
            :size="100"
            unit="px"
            :thickness="20"
            has-legend
            legend-placement="top"
            :sections="chartEmail"
            :total="100"
            :start-angle="0"
          >
            <el-tooltip
              effect="dark"
              :content="$tc('LIST_DETAILS.CONTACTABILITY_CHART_TOOLTIP', extendedList.email_optins ? extendedList.email_optins : 0, {'contacts': extendedList.email_optins ? extendedList.email_optins : 0})"
              placement="top"
            >
              <span>{{ getOptinsPercentage(extendedList.email_optins) }}%</span>
            </el-tooltip>
          </vc-donut>
          <div
            v-if="optinEmail"
            class="optin"
          >
            <div>{{ $t('LIST_DETAILS.OPTIN_NAME') }}</div>
            <el-tooltip
              placement="bottom"
              :disabled="disabledTooltipOptinEmail"
              :content="optinEmail"
              effect="dark"
            >
              <!-- content to trigger tooltip here -->
              <div
                ref="optinEmail"
                class="optin-name"
              >
                {{ optinEmail }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div class="list-details-main__delete">
      <el-button
        v-if="isContactList"
        size="medium"
        :disabled="!hasCreationPermission || list.updating || (list.type_id == listTypes.ACTIVE && list.active)"
        @click="showDeleteModal = true"
      >
        <i class="material-icons">delete</i>
        {{ $t('LIST_DETAILS.ACTIONS.DELETE') }}
      </el-button>
    </div>

    <!-- DELETE MODAL -->
    <Modal
      :disabled="!hasCreationPermission"
      :dialog-visible.sync="showDeleteModal"
      width="500px"
      :title="$tc('DELETE.TITLE_MODAL')"
      :subtitle="$tc('DELETE.SUBTITLE_MODAL')"
      :btn="$t('BUTTON.CONFIRM')"
      :loading="loadingDeleting"
      type="danger"
      :action="deleteList"
    />

    <ModalExpirationDate
      :dialog-visible.sync="showExpirationDateModal"
      :list="list"
      :loading="loadingExpirationDate"
      @update-expiration-date="updateExpirationDate"
    />
  </div>
</template>

<script>
import { ListType, AvatarHolder, Modal } from '@/components'
import ModalExpirationDate from "@/components/Parts/ModalExpirationDate.vue"
import { getFullName } from '@/filters'
import config from '@/config'
import NoAuthor from '@/assets/img/no-author.svg'

export default {
  name: 'ListDetailsMain',
  components: { ListType, AvatarHolder, Modal, ModalExpirationDate },
  props: {
    list: {
      type: Object,
      default: () => ({})
    },
    optins: {
      type: Array,
      required: true
    },
    expirationDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadingName: false,
      loadingExpirationDate: false,
      editNameMode: false,
      disabledTooltipOptinEmail: true,
      disabledTooltipOptinSms: true,
      disabledTooltipGroup: true,
      showDeleteModal: false,
      showExpirationDateModal: false,
      loadingDeleting: false,
      newName: '',
      listTypes: config.LIST_TYPES,
      noAuthor: NoAuthor
    }
  },
  computed: {
    user: function () {
      return this.$store.getters['auth/user']
    },
    extendedList () {
      return this.$store.getters['lists/extendedList'] || null
    },
    contactsCount () {
      return this.$store.getters['lists/totalContacts']
    },
    fieldsList () {
      if (!this.list || !this.list.fields) {
        return []
      }
      return this.list.fields.map(f => f.name_in_db)
    },
    chartSMS () {
      if (!this.list) {
        return []
      }
      const value =  (this.extendedList.sms_optins * 100 / this.extendedList.contacts_count) || 0
      return [{ label: 'SMS', value: value, color: '#A2ADB8' }]
    },
    chartEmail () {
      if (!this.list) {
        return []
      }
      const value =  (this.extendedList.email_optins * 100 / this.extendedList.contacts_count) || 0
      return [{ label: 'Email', value: value, color: '#A2ADB8' }]
    },
    hasCreationPermission () {
      return this.$store.getters['auth/hasPermissionInGroup'](this.list.group_id, config.PERMISSIONS.LIST_WRITE) ||
        (this.$store.getters['auth/hasPermissionInGroup'](this.list.group_id, config.PERMISSIONS.LIST_WRITE_SELF) &&
          this.user.id === this.list.created_by)
    },
    isContactList () {
      return [this.listTypes.STATIC, this.listTypes.ACTIVE].indexOf(this.list.type_id) > -1
    },
    optinEmail () {
      return this.optinName('EMAIL')
    },
    optinSms () {
      return this.optinName('MOBILE')
    },
    displayBothDonuts() {
      return this.fieldsList.indexOf('MOBILE') > -1 && this.fieldsList.indexOf('EMAIL') > -1
    },
    isStaticList () {
      return this.list.type_id === this.listTypes.STATIC
    },
    isExpiringSoon() {
      const today = moment()
      const expirationDate = moment(this.list.expiration_date)
      if (expirationDate) {
        return expirationDate.diff(today, 'days') < 15
      }
      return false
    }
  },
  watch: {
    list (newList, oldList) {
      this.newName = newList.name
      if (Object.keys(oldList).length && newList.id !== oldList.id) {
        const params = {
          l: 1,
          p: 1
        }
        this.$store.dispatch('lists/retrieveContacts', { listId: newList.id, params: params, storeContacts: true })
      }
    }
  },
  created: function () {
    this.newName = this.list.name
  },
  mounted () {
    this.setTooltipToDisplay()
  },
  methods: {
    getFullName: getFullName,
    setTooltipToDisplay() {
      if (this.$refs.optinEmail && this.$refs.optinEmail.scrollWidth > this.$refs.optinEmail.offsetWidth) {
        this.disabledTooltipOptinEmail = false
      }
      if (this.$refs.optinSms && this.$refs.optinSms.scrollWidth > this.$refs.optinSms.offsetWidth) {
        this.disabledTooltipOptinSms = false
      }
      if (this.$refs.groupName && this.$refs.groupName.scrollWidth > this.$refs.groupName.offsetWidth) {
        this.disabledTooltipGroup = false
      }
    },
    optinName(canal) {
      if (!this.list.name || !this.list.fields) return null
      const optinId = this.list.fields.filter(field => {
        return field.optin_type_id !== null && field.name_in_db === canal
      })
      if (!optinId.length) { return null }
      const optin = this.optins.filter(el => el.id === optinId[0].optin_type_id)
      if (optin.length) { return optin[0].name }
      return null
    },
    getOptinsPercentage (contacts) {
      if (!this.list || !this.extendedList.contacts_count || this.extendedList.contacts_count === 0) {
        return 0
      }
      return (100 * (contacts || 0) / this.extendedList.contacts_count).toFixed(2)
    },
    changeInputNameHeight (evt) {
      const inputHeight = this.$refs.listNameSpan.clientHeight
      this.$refs.listNameInput.$el.style.height = inputHeight + 'px'
      // Set focus on textarea
      setTimeout(() => {
        this.$refs.listNameInput.focus()
      }, 1)
      if (evt) {
        // Disable enter button
        if (evt.keyCode === 13) {
          evt.preventDefault()
          this.updateName()
        }
      }
    },
    deleteList () {
      if (!this.hasCreationPermission) {
        return false
      }
      // dispatch request
      this.loadingDeleting = true
      this.$store.dispatch('lists/bulkDelete', [this.list.id])
      .then((res) => {
        if (res === 1) {
          // go back to lists
          this.loadingDeleting = false
          this.$notify({
            title: this.$t('MISC.SUCCESS'),
            message: this.$tc('DELETE.SUCCESS_MSG', 1),
            type: 'success'
          })
          this.$router.push({ name: 'lists' })
        }
      })
      .catch(() => {
        // error notifications
        this.loadingDeleting = false
        this.$notify.error({
          title: 'Error',
          message: this.$t('DELETE.ERROR_SINGLE')
        })
      })
    },
    toggleEditMode (bool) {
      if (!this.hasCreationPermission) {
        return false
      }
      this.editNameMode = bool
      if (bool) {
        this.changeInputNameHeight()
      }
    },
    updateName () {
      this.loadingName = true
      if (this.newName === '') {
        this.loadingName = false
        this.newName = this.list.name
        return false
      }
      const data = {
        'listId': this.list.id,
        'data': {'name': this.newName}
      }
      this.$store.dispatch('lists/patchList', data)
      .catch((error) => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: error.data.message,
          type: 'error'
        })
      })
      .finally(() => {
        this.loadingName = false
      })
    },
    updateExpirationDate (date) {
      this.loadingExpirationDate = true
      const data = {
        'listId': this.list.id,
        'data': {'expiration_date': date}
      }
      this.$store.dispatch('lists/patchList', data)
      .catch((error) => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: error.data.message,
          type: 'error'
        })
      })
      .finally(() => {
        this.$notify({
          title: this.$t('MISC.SUCCESS'),
          message: this.$t('EXPIRATION_DATE.NOTIFICATION_MESSAGE'),
          type: 'success'
        })
        this.showExpirationDateModal = false
        this.loadingExpirationDate = false
      })
    },
    backToLists() {
      this.$store.dispatch('lists/getQueryParams').then((query) => {
        if (!this.isContactList) {
          this.$router.push({path: '/submission-lists', query: query})
          return
        }
        this.$router.push({path: '/lists', query: query})
    })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.list-details-main {
  position: relative;
  padding-bottom: 100px;
  &__back {
    padding: 9px 15px 9px 10px;
    .el-icon-arrow-left {
      margin-right: 5px;
    }
  }
  &__content {
    padding: 2rem 2rem 1rem 2rem;
    border-bottom: 1px solid $--color-light-gray;
    > div {
      margin: 20px 0;
    }
    h1, h2, p {
      margin-top: 0
    }
    .material-icons.help {
      font-size: 1rem;
      position: relative;
      top: 4px;
      margin-left: 5px;
      color: $--color-gray;
    }
    &:first-child {
      padding: 2rem;
    }
    .top {
      margin: 0;
      display: flex;
      flex: row;
      justify-content: space-between;
      .el-button {
        color: $--color-primary;
        line-height: 19.5px;
        height: 19.5px;
        padding: 0;
        margin-bottom: 12px;
        ::v-deep span {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
        i {
          margin-right: 8px;
        }
        &:hover {
          color: $--color-lighter-orange;
        }
      }
    }
    .expiration-date {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin: 0;
      margin-top: 10px;
      .expires-soon-badge {
        background-color: $--color-orange;
        color: white;
        width: 100%;
        max-width: 94px;
        margin: auto;
        line-height: 25px;
        border-radius: 14px;
        margin-bottom: 10px;
      }
    }
  }

  &__delete {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    ::v-deep .el-button {
      span {
        display: flex;
        align-items: flex-end;
        i {
          margin-right: 4px;
          font-size: 16px;
        }
      }
    }
  }
  ::v-deep &__name {
    position: relative;
    font-size: 24px;
    &:hover {
      > span {
        border: 1px solid $--color-light-gray;
      }
    }
    > span {
      border: 1px solid $--color-white;
      word-break: break-word;
      display: block;
      padding-right: 20px;
      &:hover {
        cursor: pointer;
      }
      &.hidden {
        opacity: 0;
      }
    }
    .el-textarea {
      position: absolute;
      top: 0;
      left: 0;
      &__inner {
        position: absolute;
        height: 100%;
        top: 0;
        font-size: 24px;
        font-weight: 300;
        padding: 0;
        line-height: normal;
        font-family: 'Ubuntu', sans-serif;
        border: 1px solid $--color-light-gray;
      }
    }
  }
  &__type {
    @include flexbox;
    @include align-items(center);
  }
  &__uid {
    color: $--text-light;
    font-size: 10px;
    margin-top: 12px;
  }
  &__count {
    margin-left: 10px;
    position: relative;
    bottom: 2px;
  }
  &__author {
    @include flexbox;
    align-items: center;
    margin: 10px 0;
  }
  &__author-span {
    margin: 0 5px;
    &__group {
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }
  ::v-deep &__charts {
    @include flexbox;
    @include justify-content(space-around);
    width: 100%;
    margin: 2rem auto 0 auto;
    span {
      font-size: 13px;
    }
    .optin {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-name {
        width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
    .cdc-container {
      @include flex-direction(column);
      margin-bottom: 12px;
    }
    .cdc-legend {
      @include order(-1);
    }
    .cdc-legend-item-color {
      display: none
    }
  }
}

.justify-center {
  justify-content: center;
}

  /* For mobile: */
@media only screen and (max-width: 900px) {
  .list-details-main {
    padding-bottom: 30px;
    .header {
      position: absolute;
      top: 5px;
      right: 5px;
      border: 0;
      padding: 10px;
    }
    &__content {
      padding: 1rem;
    }
    &__type {
      @include flexbox;
      @include align-items(center);
    }
    &__name {
      margin-right: 150px;
    }
  }
}
</style>
