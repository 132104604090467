<template>
  <div
    class="status-tag"
    :class="className"
  >
    {{ displayedStatus }}
    <el-tooltip
      effect="dark"
      :content="$t(`CONTACT_CARDS.MODALS.CONTACTABILITY.${translationStatusKey}_TOOLTIP`)"
      placement="top"
    >
      <i class="material-icons">help_outline</i>
    </el-tooltip>
  </div>
</template>

<script>import config from '@/config'

export default {
  name: 'StatusTag',
  props: {
    status: { type: Number, default: null }
  },
  computed: {
    translationStatusKey () {
      if (!this.status) { return 'REACHABLE' }

      return Object.keys(config.CONTACT_CARD_STATUS)
      .find(key => config.CONTACT_CARD_STATUS[key] === this.status)
    },
    displayedStatus () {
      return this.$t(`CONTACT_CARDS.STATUS.${this.translationStatusKey}`)
    },
    className () {
      if (!this.status) { return 'status-tag-reachable' }
      return `status-tag-${this.translationStatusKey.toLowerCase()}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.status-tag {
  color: $--color-white;
  padding: 5px;
  margin-right: 5px;
  width: 112px;
  border-radius: 14px;
  font-size: 12px;
  text-align: center;
  .material-icons {
    font-size: 15px;
    position: relative;
    padding: 1px;
    margin-left: 5px;
    vertical-align: middle;
    position: relative;
    cursor: default;
  }
}
.status-tag-blacklist {
  background-color: $--color-black;
}
.status-tag-hardbounce {
  background-color: $--background-color-hardbounce;
}
.status-tag-feedbackloop {
  background-color: $--background-color-feedbackloop;
}
.status-tag-spamtrap {
  background-color: $--background-color-spamtrap;
}
.status-tag-reachable {
  background-color: $--background-color-reachable;
}

</style>
