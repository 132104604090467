import Vue from "vue"

const SHOW_SNAPCALL = 'SHOW_SNAPCALL'
const SET_SNAPCALL_ID = 'SET_SNAPCALL_ID'

const moduleSnapcall = {
  namespaced: true,
  state: {
    id: undefined,
    showSnapcall: false
  },
  mutations: {
    [SHOW_SNAPCALL] (state, showSnapcall) {
      Vue.set(state, 'showSnapcall', showSnapcall)
    },
    [SET_SNAPCALL_ID] (state, id) {
      Vue.set(state, 'id', id)
    },
  },
  actions: {
    startSnapcall({ commit }) {
      commit(SHOW_SNAPCALL, true)
    },
    endSnapcall({ commit }) {
      commit(SHOW_SNAPCALL, false)
    },
    setSnapcallId({ commit }, id) {
      commit(SET_SNAPCALL_ID, id)
    }
  },
  getters: {
    showSnapcall: (state) => state.showSnapcall,
    snapcallId: state => state.id
  }
}

export default moduleSnapcall
