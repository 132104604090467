var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"custom-class":_vm.customClass,"visible":_vm.dialogVisible,"append-to-body":true,"close-on-click-modal":!_vm.isConfirmationModal,"show-close":!_vm.isConfirmationModal,"close-on-press-escape":!_vm.isConfirmationModal},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"dialog-modal__title",attrs:{"slot":"title"},slot:"title"},[_c('div',[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")])]),(!_vm.isConfirmationModal)?_c('div',[_c('div',{staticClass:"dialog-modal__information"},[_c('span',{staticClass:"dialog-modal__information__intro"},[_vm._v(" "+_vm._s(_vm.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.INTRO'))+" ")]),(!_vm.isReachable)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.DETAILS'))+" ")]):_vm._e(),_c('div',{staticClass:"dialog-modal__information__status"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.CURRENT_STATUS'))+" ")]),_c('div',{staticClass:"dialog-modal__information__status__tags"},[(_vm.isReachable)?_c('StatusTag'):_vm._l((_vm.statusTags),function(tag){return _c('StatusTag',{key:tag,attrs:{"status":tag}})})],2)])]),(!_vm.isConfirmationModal && _vm.hasAdminContactCardPermission)?_c('div',{staticClass:"dialog-modal__action"},[(_vm.logs.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.LOGS_INTRO'))+" ")]):_vm._e(),(_vm.logs.length)?_c('ul',{staticClass:"dialog-modal__action__logs"},_vm._l((_vm.logs.slice(0, 3)),function(log,index){return _c('li',{key:index,staticClass:"log"},[(log.action_type === 'blacklist')?_c('el-alert',{attrs:{"title":_vm.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.BLACKLIST_INFO', { 
              date: _vm.getDate(log.action_date)
            }),"closable":false,"type":"info","show-icon":""}}):_vm._e(),(log.action_type === 'unblacklist')?_c('el-alert',{attrs:{"title":_vm.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.UNBLACKLIST_INFO', { 
              date: _vm.getDate(log.action_date)
            }),"closable":false,"type":"info","show-icon":""}}):_vm._e(),(log.action_type === 'dehardification')?_c('el-alert',{attrs:{"title":_vm.$t('CONTACT_CARDS.MODALS.CONTACTABILITY.DEHARDIF_INFO', { 
              date: _vm.getDate(log.action_date)
            }),"closable":false,"type":"info","show-icon":""}}):_vm._e()],1)}),0):_vm._e(),_c('div',{staticClass:"dialog-modal__action__btn"},[(_vm.isHardbounce)?_c('el-button',{attrs:{"size":"medium"},on:{"click":function($event){return _vm.switchModal('hardbounce')}}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.REMOVE_NPAI'))+" ")]):_vm._e(),_c('el-button',{attrs:{"size":"medium"},on:{"click":function($event){return _vm.switchModal('blacklist')}}},[_vm._v(" "+_vm._s(_vm.actionButtonText)+" ")])],1)]):_vm._e()]):_vm._e(),(_vm.isConfirmationModal)?_c('div',{staticClass:"dialog-modal__information"},[_c('span',{staticClass:"dialog-modal__information__intro"},[_vm._v(" "+_vm._s(_vm.confirmationText)+" ")]),(_vm.confirmationType === 'hardbounce' || (_vm.confirmationType === 'blacklist' && !_vm.isBlacklisted))?_c('el-alert',{attrs:{"title":_vm.$t(_vm.confirmationWarning),"closable":false,"type":"warning","show-icon":""}}):_vm._e()],1):_vm._e(),(_vm.isConfirmationModal)?_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.switchModal}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.CANCEL'))+" ")]),_c('el-button',{attrs:{"type":"danger","loading":_vm.statusLoading},on:{"click":_vm.actions}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.CONFIRM'))+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }