import Vue from 'vue'
import config from '@/config'

const SET_GROUPS = 'SET_GROUPS'
const SET_OPTINS = 'SET_OPTINS'
const SET_LOADING = 'SET_LOADING'

const moduleGroups = {
  namespaced: true,
  state: {
    groups: [],
    optins: [],
    loading: false
  },
  mutations: {
    [SET_GROUPS] (state, groups) {
      Vue.set(state, 'groups', groups)
    },
    [SET_OPTINS] (state, optins) {
      Vue.set(state, 'optins', optins)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    getGroups ({ commit }) {
      commit(SET_LOADING, true)
      const url = config.API_URL + '/groups'
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_GROUPS, response.data.objects)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getGroupsOptins ({ commit }, groupId) {
      const url = `${config.API_URL}/groups/${groupId}/optins`
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_OPTINS, response.data.objects)
            commit(SET_LOADING, false)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    }
  },
  getters: {
    groups: state => state.groups,
    optins: state => state.optins,
    loading: state => state.loading
  }
}

export default moduleGroups
