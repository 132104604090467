import Vue from 'vue'
import config from '@/config'

const SET_NOTIFICATIONS_LIST = 'SET_NOTIFICATIONS_LIST'
const SET_LOADING = 'SET_LOADING'

const moduleNotifications = {
  namespaced: true,
  state: {
    notificationsList: [],
    loading: false
  },
  mutations: {
    [SET_NOTIFICATIONS_LIST] (state, notifications) {
      Vue.set(state, 'notificationsList', notifications)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    getNotificationsList({ state, commit }, { params }) {
      commit(SET_LOADING, true)
      const url = `${config.API_URL}/notifications`
      const { p } = params
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: params })
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            const notifications =
              p === 1 ?
                response.data.objects :
                state.notificationsList.concat(response.data.objects)
            commit(SET_NOTIFICATIONS_LIST, notifications)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
          .finally(() => {
            commit(SET_LOADING, false)
          })
      })
    },
    postNotificationsList({ commit, state }, params) {
      commit(SET_LOADING, true)
      const url = `${config.API_URL}/notifications/read`
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, params)
          .then(response => {
            if (response.status != 204) {
              reject(response)
            }
            let updatedNotificationsList = state.notificationsList
            if (params.list_id && params.import_log_id) {
              const notificationToUpdate = updatedNotificationsList.find(n =>  n.import_log_id === params.import_log_id)
              notificationToUpdate.read = true
            } else {
              updatedNotificationsList = state.notificationsList.map(n => {
                n.read = true
                return n
              })
            }
            commit(SET_NOTIFICATIONS_LIST, updatedNotificationsList)
            resolve(response)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
          .finally(() => {
            commit(SET_LOADING, false)
          })
      })
    }
  },
  getters: {
    notificationsList: state => state.notificationsList,
    loading: state => state.loading
  }
}

export default moduleNotifications
