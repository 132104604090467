import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fr from './fr.json'
import en from './en.json'
import { messages } from '@team-crm-automation/nmp-components-vue2'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    fr: {
      ...messages.fr,
      ...fr
    },
    en: {
      ...messages.en,
      ...en
    }
  }
})
if (process.env.DEV_MODE) {
  const langs = Object.keys(i18n.messages)

  document.addEventListener('keydown', (event) => {
    if (event.code === 'KeyL' && event.ctrlKey) {
      const id = (langs.indexOf(i18n.locale) + 1) % langs.length
      i18n.locale = langs[id]
    }
  })
}

export default i18n
