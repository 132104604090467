<template>
  <div class="home__contact-cards">
    <!-- Channel switch -->
    <div class="home__contact-cards__channels">
      <div
        class="home__contact-cards__channels__email"
        :class="{'active': isEmail}"
        @click="channel='email'"
      >
        <div>
          <img
            :src="emailImgUrl"
            alt="logo"
          >
        </div>
        <el-radio
          v-model="channel"
          label="email"
        >
          {{ $t('CONTACT_CARDS.CHANNELS.EMAIL') }}
        </el-radio>
      </div>
      <div
        class="home__contact-cards__channels__mobile"
        :class="{'active': !isEmail}"
        @click="channel='mobile'"
      >
        <div>
          <img
            :src="mobileImgUrl"
            alt="logo"
          >
        </div>
        <el-radio
          v-model="channel"
          label="mobile"
        >
          {{ $t('CONTACT_CARDS.CHANNELS.MOBILE') }}
        </el-radio>
      </div>
    </div>

    <!-- Search input -->
    <div class="home__contact-cards__search">
      <div
        v-if="isEmail"
        class="home__contact-cards__search__email"
      >
        <el-input
          v-model="searchQuery"
          clearable
          maxlength="250"
          :placeholder="$t('CONTACT_CARDS.SEARCH_EMAIL')"
          @clear="clearSearch"
          @keyup.enter.native="searchContact()"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
          />
        </el-input>
      </div>
      <div
        v-else
        class="home__contact-cards__search__mobile"
      >
        <div class="home__contact-cards__search__mobile__row">
          <el-select
            v-model="country"
            filterable
            clearable
            @change="getCountryCode(country)"
          >
            <el-option
              v-for="(name, codeISO) in countries"
              :key="codeISO"
              :label="name"
              :value="codeISO"
            />
          </el-select>
          <el-input
            v-model="countryCode"
            class="home__contact-cards__search__mobile__code"
            @change="getCountryFromCountryCode"
          />

          <el-input
            v-model="searchQuery"
            class="home__contact-cards__search__mobile__number"
            clearable
            maxlength="50"
            :placeholder="$t('CONTACT_CARDS.SEARCH_MOBILE')"
            @clear="clearSearch"
            @keyup.enter.native="searchContact()"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
            />
          </el-input>
        </div>
        <div class="home__contact-cards__search__info">
          {{ $t('CONTACT_CARDS.MOBILE_INFO') }}
        </div>
      </div>
      <el-button
        type="primary"
        size="medium"
        :disabled="!enableSearch"
        :loading="loading"
        @click="searchContact()"
      >
        {{ $t('BUTTON.SEARCH') }}
      </el-button>
    </div>


    <!-- Search results -->
    <el-card v-if="contact">
      <router-link
        :to="{ path: `/contacts/${channel}/${contact.id}` }"
        class="home__contact-cards__result"
      >
        <div
          :style="imgStyle"
          class="home__contact-cards__result__img-column"
        >
          <img
            :src="isEmail ? emailImgUrl: mobileImgUrl"
            alt="logo"
          >
        </div>
        <div class="home__contact-cards__result__contact-column">
          {{ $t('CONTACT_CARDS.CONTACT', {'channel': capitalizeFirstLetter(channel)}) }}
          <div>
            {{ contact.email || '+' + contact.mobile }}
          </div>
        </div>
        <div class="home__contact-cards__result__status-column">
          <StatusTag
            :status="contact.status_id"
          />
        </div>
      </router-link>
    </el-card>
    <el-card v-else-if="searchingContact && !contact">
      <div class="home__contact-cards__no-result">
        {{ $t('CONTACT_CARDS.NO_RESULT') }}
      </div>
    </el-card>
  </div>
</template>

<script>

import emailActiveImage from '@/assets/img/email-active.svg'
import emailInactiveImage from '@/assets/img/email.svg'
import mobileActiveImage from '@/assets/img/mobile-active.svg'
import mobileInactiveImage from '@/assets/img/mobile.svg'
import background from '@/assets/img/channel-bg.svg'
import { StatusTag }  from '@/components'

const libphonenumber = require('google-libphonenumber')

export default {
  name: 'HomeContactCards',
  components: {
  StatusTag
  },
  data () {
    return {
      loading: false,
      backgroundUrl: background,
      channel: 'email',
      searchQuery: '',
      countries: {},
      country: 'France',
      countryCode: '+33',
      contact: null,
      searchingContact: false
    }
  },
  computed: {
    emailImgUrl () {
      return this.isEmail ? emailActiveImage: emailInactiveImage
    },
    mobileImgUrl () {
      return this.isEmail ?  mobileInactiveImage: mobileActiveImage
    },
    enableSearch () {
      if (this.channel === 'email'){
        return this.searchQuery
      }
      return this.searchQuery && this.country
    },
    isEmail () {
      return this.channel === 'email'
    },
    rawCountryCode () {
      return this.countryCode.replace('+', '')
    },
    imgStyle () {
      let css = 'background-image: url(' + this.backgroundUrl + ');'
      css += 'width: 55px; height: 55px;'
      return css
    }
  },
  watch: {
    channel() {
      this.clearSearch()
    }
  },
  created () {
    this.initCountries()
  },
  methods: {
    clearSearch () {
      this.searchQuery = ''
      this.contact = null
      this.searchingContact = false
    },
    searchContact () {
      const params = this.buildQueryParams()
      this.loading = true
      this.$store.dispatch('contacts/searchContact', params)
        .then((res) => {
          this.contact = res
          this.searchingContact = true
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        })
    },
    buildQueryParams () {
      const search = this.isEmail ? this.searchQuery : this.rawCountryCode + this.searchQuery
      return { channel: this.channel, search: search }
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    initCountries () {
      import(`@/assets/data/countries/countries_${this.$i18n.locale}.json`).then((countries) => {
        this.countries = countries.default
      })
    },
    getCountryCode(country) {
      if (country) {
        const phoneUtils = libphonenumber.PhoneNumberUtil.getInstance()
        this.countryCode = '+' + phoneUtils.getCountryCodeForRegion(country.toUpperCase())
      } else {
        this.countryCode =  null
      }
    },
    getCountryFromCountryCode() {
      const phoneUtils = libphonenumber.PhoneNumberUtil.getInstance()
      const isoCode = phoneUtils.getRegionCodeForCountryCode(this.rawCountryCode)
      this.country = this.countries[isoCode]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.home__contact-cards {
    @include align-items(center);
    @include flex-direction(column);
    @include flexbox;
  .home__contact-cards__channels {
    @include flexbox;
    text-align: center;
    margin: 0 30px 30px;
    cursor: pointer;
    .home__contact-cards__channels__email,
    .home__contact-cards__channels__mobile
     {
      background-color: $--color-white;
      border: solid 1px $--color-medium-gray;
      height: 170px;
      width: 209px;
      margin: 10px;
      @include align-items(left);
      @include flex-direction(column);
      @include flexbox;
      @include justify-content(center);
      img {
        width: 70px;
        height: 70px;
        margin-bottom: 15px;
      }
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: $--color-primary;
        border-color: $--color-primary;
      }
    }
    .home__contact-cards__channels__email:hover,
    .home__contact-cards__channels__mobile:hover {
      border-color: $--color-primary;
    }
    .active {
      border: solid 1px $--color-primary;
    }
  }
  .home__contact-cards__search {
    @include flexbox;
    ::v-deep .el-button {
      min-width: 30px;
      width: 120px;
      padding: 0;
    }
    .home__contact-cards__search__email {
      margin-right: 16px;
      .el-input {
        width: 380px
      }
    }
    .home__contact-cards__search__mobile {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
      .home__contact-cards__search__mobile__row {
        display: flex;
      }
      .home__contact-cards__search__mobile__number {
        width: 226px;
      }
      .home__contact-cards__search__mobile__code {
        font-size: 12px;
        margin-right: 5px;
        border-radius: 1px;
        ::v-deep .el-input__inner {
          padding: 5px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0;
        }
        width: 45px;
      }
      .el-select {
        width: 109px;
        ::v-deep .el-input__inner {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
      .home__contact-cards__search__info {
        padding: 10px 5px;
        font-size: 12px;
        width: 100%;
        text-align: left;
      }
    }
  }

  .el-card {
    margin-top: 20px;
    ::v-deep .el-card__body {
      padding: 0 10px;
    }
  }
  .home__contact-cards__no-result {
    @include flexbox;
    @include justify-content(center);
    @include flex-direction(column);
    font-size: 12px;
    width: 496px;
    height: 75px;
  }
  .home__contact-cards__result {
    display: flex;
    width: 496px;
    font-size: 12px;
    cursor: pointer;
    padding: 10px 0;
    text-decoration: none;
    .home__contact-cards__result__img-column {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      @include flex-direction(column);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      img {
        width: 40px;
        height: 40px;
        margin: 10px;
      }
    }
    .home__contact-cards__result__contact-column {
      margin: 10px;
      width: 60%;
      color: $--color-text-regular;
      @include align-items(left);
      @include flex-direction(column);
      @include flexbox;
      @include justify-content(center);
      div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .home__contact-cards__result__status-column {
      @include flex-direction(column);
      @include flexbox;
      @include justify-content(center);
    }
  }
}

@media only screen and (max-width: $--mobile-width) {
  .home__contact-cards {
    .home__contact-cards__channels{
      .home__contact-cards__channels__email,
      .home__contact-cards__channels__mobile {
        height: 152px;
        width: 177px;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
    .home__contact-cards__result,
    .home__contact-cards__no-result {
      width: 360px;
      .home__contact-cards__result__contact-column {
        width: 42%;
      }
    }
    .home__contact-cards__search {
      .home__contact-cards__search__email {
        margin-right: 3px;
        .el-input{
          width: 237px;
        }
      }
      .home__contact-cards__search__mobile {
        margin-right: 2px;
        .home__contact-cards__search__mobile__number {
          width: 110px;
        }
        .el-select {
          width: 89px
        }
        .home__contact-cards__search__mobile__code {
          width: 32px;
        }
      }
    }
    .el-card {
      width: 360px;
    }
  }
}

</style>
