<template>
  <div class="notification">
    <i
      v-loading="true"
      class="notification__icon"
      element-loading-spinner="el-icon-loading"
      element-loading-background="transparent"
    />
    <span class="notification__text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    text: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.notification {
  background: $--color-bg-gray;
  color: $--color-gray;
  text-align: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  font-size: 12px;
  &__icon {
    display: inline-block;
    color: $--color-primary;
    font-size: 18px;
    margin-right: 10px;
    line-height: 18px;
    height: 18px;
    width: 18px;

    ::v-deep .el-loading-spinner {
      margin-top: -5px;
    }
  }
  &__text {
    display: inline-block;
    height: 18px;
  }
}
</style>
