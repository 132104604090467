<template>
  <div
    class="filter-cascade filter"
    :style="cssVar"
    :class="{
      'filter-cascade--active': hasActiveFilters,
      name: true
    }"
  >
    <el-cascader
      ref="cascader"
      v-model="selected"
      :popper-class="`filter-${name} filter-cascader-custom`"
      :placeholder="translatedName"
      :before-filter="onFilter"
      :filter-method="filterOptions"
      :options="otpimizedOptions"
      :props="props"
      :filterable="filterable"
      clearable
    >
      <template slot-scope="{ data }">
        <el-tooltip
          effect="dark"
          placement="top"
          :content="data[displayKey]"
          :disabled="isTruncated(data[displayKey])"
        >
          <div
            :ref="camelCase(data[displayValue].id)"
            :style="`color: ${ color };`"
            @click="clickOnCheckbox(data[displayValue].id)"
          >
            {{ truncate(data[displayKey], optionLimit) }} <span v-if="data.count"> ({{ data.count }}) </span>
          </div>
        </el-tooltip>
      </template>

      <!-- NO DATA -->
      <template slot="empty">
        <div style="padding: 10px 15px; opacity: 0.5;">
          {{ $t( 'FILTER.NO_MATCHING_DATA' ) }}
        </div>
      </template>
    </el-cascader>
  </div>
</template>

<script>
import {
  capitalize as _capitalize,
  camelCase as _camelCase,
  get as _get
} from 'lodash-es'

export default {
  name: 'FilterCascade',
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    displayValue: {
      type: String,
      default: 'value'
    },
    displayKey: {
      type: String,
      default: 'label'
    },
    color: {
      type: String,
      default: '#CC0000'
    },
    filterable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      beforeFilter: false,
      optionLimit: 65,
      props: {
        multiple: true,
        checkStrictly: true,
        leaf: 'label',
        label: this.displayKey,
        value: this.displayValue
      }
    }
  },
  computed: {

    /**
     * Filter name translation
     */
    translatedName() {
      return this.$t(`FILTER.${this.name.toUpperCase()}`)
    },

    /**
     * Get css variable from js to css
     */
    cssVar() {
      return {
        '--display-placeholder': (this.filterable === true) ? 0 : 1,
        '--filter-color': this.color,
        '--filter-name': JSON.stringify(_capitalize(this.translatedName))
      }
    },

    /**
     * Limit displayed options for mouse navigation in order
     * to avoid DOM lags
     */
    otpimizedOptions() {
      return this.options.map(opt => {
        const newOpt = { ...opt }
        if (opt.children && !this.beforeFilter) {
            newOpt.children = opt.children.length > 200
            ? opt.children.slice(0, 199)
            : opt.children
        }
        return newOpt
      })
    },
    /**
     * Check if data is multipanel
     * @return {Boolean}
     */
    isMultipanelOptions () {
      return Boolean(_get(this.options, '0.children'))
    },

    /**
     * Check if the filter is active
     */
    hasActiveFilters() {
      return this.values.length > 0
    },

    /**
     * Update selected values
     */
    selected: {
      get () {
        return this.values
      },
      set (newVal) {
        this.$emit('updated', newVal)
      }
    }
  },
  methods: {
    camelCase: _camelCase,
    truncate (value, limit) {
      return value.length > limit ? value.substring(0, limit - 1) + '…' : value
    },
    isTruncated (value) {
      return value.length < this.optionLimit
    },
    clickOnCheckbox (t) {
      const container = this.$refs[_camelCase(t)].parentNode.parentNode
      const isMenu = Boolean(container.querySelector('.el-cascader-node__postfix'))
      if (!isMenu) { container.querySelector('.el-checkbox').click() }
    },
    filterOptions (node, keyword) {
      const isNotParent = (this.isMultipanelOptions && node.parent) || !this.isMultipanelOptions
      return isNotParent && node.text && node.text.toLowerCase().includes(keyword.toLowerCase())
    },
    onFilter () {
      this.beforeFilter = true
      return new Promise(resolve => {
        setTimeout(resolve, 500)
      })
    },
    clearAll () {
      this.selected = []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/element-variables.scss";
@import "@/assets/styles/variables.scss";
.filter-cascade {
  counter-reset: tag;

  ::v-deep .el-cascader {
    .el-input.is-focus .el-input__inner {
      border-color: var(--filter-color);
    }
    .el-input__inner {
      max-width: 140px;
      &::placeholder {
        opacity: var(--display-placeholder);
        color: $--color-text-placeholder;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
      }
      &:focus {
        border-color: var(--filter-color);
      }
    }
  }

  ::v-deep .el-cascader__tags {
    top: -30px;
    .el-tag.el-tag--info {
      background-color: transparent;
      position: absolute;
      border: none;
      width: 100%;
      margin: 0;
      > span,
      &::before,
      .el-tag__close {
        display: none;
      }
      &::after {
        position: absolute;
        background-color: $--color-white;
        color: $--color-dark-gray;
        top: 40px;
        left: 15px;
        width: calc(100% - 10px);
        counter-increment: tag;
        content: var(--filter-name)" ("counter(tag)")";
      }
    }
    .el-cascader__search-input {
      &::placeholder {
        text-transform: capitalize;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
      }
      background-color: transparent;
      position: absolute;
      width: 100px;
      top: 37px;
    }
    &:focus-within {
      .el-tag--info::after,
      .el-cascader__search-input::placeholder {
        opacity: 0;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
}

.filter-cascade--active {
  ::v-deep .el-cascader {
    .el-input__inner {
      border: 1px solid;
      border-color: var(--filter-color);
    }
  }
}
</style>
