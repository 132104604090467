<template>
  <div>
    <!-- TODO: IMPORTS -->
    IMPORTS
  </div>
</template>

<script>
export default {
  name: 'Imports',
  components: { }
}
</script>

<style lang="scss" scoped>
</style>
