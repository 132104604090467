<template>
  <div class="setup-fields">
    <div

      class="setup-fields__errors"
    >
      <el-alert
        v-for="(value, key) in errors"
        :key="key"
        class="setup-active-list__alert"
        type="error"
        show-icon
        :closable="false"
      >
        <span
          slot="title"
          class="el-alert__title"
        >
          {{ $t(value.error, { field: value.field, column: value.column }) }}
        </span>
      </el-alert>
    </div>
    <div class="setup-fields__title">
      {{ $t('SETUP.FIELDS.TITLE') }}
    </div>
    <div class="setup-fields__description">
      {{ $t('SETUP.FIELDS.DESCRIPTION') }}
    </div>

    <el-alert
      v-show="displayRequiredFieldsError"
      class="setup-fields__alert"
      :title="$t('SETUP.FIELDS.ERROR_MISSING_REQUIRED_MAPPING')"
      type="error"
      show-icon
      :closable="false"
    />

    <el-form
      ref="fieldsForm"
      class="setup-fields__form"
      :model="fieldsForm"
      :rules="rules"
      label-width="140px"
      label-position="left"
      :hide-required-asterisk="true"
    >
      <!-- HEADER -->
      <el-form-item
        class="setup-fields__form__header"
        :label="$t('SETUP.FIELDS.CONTACT_FIELD')"
      >
        <el-col :span="10">
          {{ $t('SETUP.FIELDS.FILE_COLUMN') }}
        </el-col>
        <el-col :span="10">
          {{ $t('SETUP.FIELDS.OPTIN') }}
        </el-col>
      </el-form-item>

      <!-- EMAIL COLUMN -->
      <el-form-item
        :label="$t('SETUP.FIELDS.EMAIL_LABEL')"
        prop="email_field"
      >
        <el-col :span="10">
          <el-form-item
            prop="email_channel_field"
            :show-message="false"
          >
            <el-select
              v-model="fieldsForm.email_field"
              :no-data-text="$t('MISC.NO_DATA')"
              placeholder=""
              autocomplete="no"
              filterable
              clearable
              @clear="cleanField('email_field')"
              @change="formChange('email_field', $event)"
            >
              <el-option
                v-for="col in emailFields"
                :key="col.id"
                :label="col.name_in_file"
                :value="col.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item prop="email_optin_field">
            <el-select
              v-model="fieldsForm.email_optin_field"
              :loading="loadingOptins"
              :no-data-text="$t('MISC.NO_DATA')"
              :disabled="!fieldsForm.email_field"
              placeholder=""
              autocomplete="no"
              clearable
              filterable
              @change="formChange()"
            >
              <el-option
                v-for="emailOptin in emailOptins"
                :key="emailOptin.id"
                :label="emailOptin.name"
                :value="emailOptin.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-form-item>

      <!-- SMS COLUMN -->
      <el-form-item

        :label="$t('SETUP.FIELDS.SMS_LABEL')"
      >
        <el-col :span="10">
          <el-form-item
            prop="sms_channel_field"
            :show-message="false"
          >
            <el-select
              v-model="fieldsForm.sms_field"
              :no-data-text="$t('MISC.NO_DATA')"
              placeholder=""
              autocomplete="no"
              filterable
              clearable
              @clear="cleanField('sms_field')"
              @change="formChange('sms_field', $event)"
            >
              <el-option
                v-for="col in smsFields"
                :key="col.id"
                :label="col.name_in_file"
                :value="col.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item prop="sms_optin_field">
            <el-select
              v-model="fieldsForm.sms_optin_field"
              :loading="loadingOptins"
              :no-data-text="$t('MISC.NO_DATA')"
              :disabled="!fieldsForm.sms_field"
              placeholder=""
              autocomplete="no"
              filterable
              clearable
              @change="formChange()"
            >
              <el-option
                v-for="smsOptin in smsOptins"
                :key="smsOptin.id"
                :label="smsOptin.name"
                :value="smsOptin.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-form-item>

      <!-- Mobile number settings -->
      <div
        v-show="fieldsForm.sms_field"
        class="setup-fields__form__mobile-settings"
      >
        <div class="setup-fields__form__mobile-settings__title">
          {{ $t('SETUP.FIELDS.MOBILE_NUMBER_SETTINGS') }}
        </div>
        <div class="setup-fields__form__mobile-settings__label">
          {{ $t('SETUP.FIELDS.MOBILE_SETTINGS_COUNTRY_MULTI_LABEL') }}
        </div>
        <el-form-item class="setup-fields__form__mobile-settings__radio">
          <el-radio-group
            v-model="fieldsForm.mobile_settings"
            @change="formChange()"
          >
            <el-radio :label="mobileSettingsType.MONO">
              {{ $t('SETUP.FIELDS.MOBILE_SETTINGS_COUNTRY') }}
            </el-radio>
            <el-radio :label="mobileSettingsType.MULTI">
              {{ $t('SETUP.FIELDS.MOBILE_SETTINGS_COUNTRY_MULTI') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          class="setup-fields__form__mobile-settings__select-country"
        >
          <span slot="label">
            {{ fieldsForm.mobile_settings === mobileSettingsType.MULTI ?
              $t('SETUP.FIELDS.MOBILE_SETTINGS_COUNTRY_MULTI_LABEL') :
              $t('SETUP.FIELDS.MOBILE_SETTINGS_COUNTRY_LABEL')
            }}
            <el-tooltip
              :disabled="fieldsForm.mobile_settings === mobileSettingsType.MONO"
              effect="dark"
              :content="$t('SETUP.FIELDS.ISOCODE_INFO')"
              placement="bottom"
            >
              <i
                v-show="fieldsForm.mobile_settings !== mobileSettingsType.MONO"
                class="material-icons"
              >
                help_outline
              </i>
            </el-tooltip>
          </span>

          <el-form-item
            v-if="fieldsForm.mobile_settings === mobileSettingsType.MULTI"
            prop="mobile_settings_multi"
          >
            <el-select
              ref="country-code-fields-select"
              v-model="fieldsForm.country_code_fields"
              :no-data-text="$t('MISC.NO_DATA')"
              placeholder=""
              autocomplete="no"
              filterable
              clearable
              @change="formChange()"
            >
              <el-option
                v-for="col in countryCodeFields"
                :key="col.id"
                :label="col.name_in_file"
                :value="col.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-else
            prop="mobile_settings_mono"
          >
            <el-select
              ref="default-country-code-fields-select"
              v-model="fieldsForm.default_country_code"
              :no-data-text="$t('MISC.NO_DATA')"
              placeholder=""
              autocomplete="no"
              filterable
              clearable
              @change="formChange()"
            >
              <el-option
                v-for="(country_name, isocode) in countries"
                :key="isocode"
                :label="country_name"
                :value="isocode"
              />
            </el-select>
          </el-form-item>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { find, remove, clone } from 'lodash-es'
import config from '@/config'

export default {
  name: 'SetupFields',
  components: { },
  props: {
    step: { type: Number, required: true },
    list: { type: Object, required: true },
    changeDetected: { type: Boolean, default: false }
  },
  data () {
    var validateEmailField = (rule, value, callback) => {
      if (Object.prototype.hasOwnProperty.call(this.errors, 'email')) {
        return callback(new Error())
      }
      return callback()
    }
    var validateSmsField = (rule, value, callback) => {
      if (Object.prototype.hasOwnProperty.call(this.errors, 'sms')) {
        return callback(new Error())
      }
      return callback()
    }
    var validateEmailOptin = (rule, value, callback) => {
      if (this.fieldsForm.email_field && !this.fieldsForm.email_optin_field) {
        return callback(new Error(this.$t('ERRORS.FORM_FIELD_REQUIRED')))
      }
      return callback()
    }
    var validateSmsOptin = (rule, value, callback) => {
      if (this.fieldsForm.sms_field && !this.fieldsForm.sms_optin_field) {
        return callback(new Error(this.$t('ERRORS.FORM_FIELD_REQUIRED')))
      }
      return callback()
    }
    var validateMobileSettingsMono = (rule, value, callback) => {
      if (this.fieldsForm.sms_field && this.fieldsForm.mobile_settings === this.mobileSettingsType.MONO && !this.fieldsForm.default_country_code) {
        return callback(new Error(this.$t('ERRORS.FORM_FIELD_REQUIRED')))
      }
      return callback()
    }
    var validateMobileSettingsMultiCountry = (rule, value, callback) => {
      if (this.fieldsForm.sms_field && this.fieldsForm.mobile_settings === this.mobileSettingsType.MULTI && !this.fieldsForm.country_code_fields) {
        return callback(new Error(this.$t('ERRORS.FORM_FIELD_REQUIRED')))
      }
      return callback()
    }
    return {
      errors: {},
      fieldsForm: {
        email_field: null,
        sms_field: null,
        mobile_settings: 1,
        email_optin_field: null,
        sms_optin_field: null,
        default_country_code: null,
        country_code_fields: null
      },
      mobileSettingsType: {
        'MONO': 1,
        'MULTI': 2
      },
      rules: {
        email_channel_field: [
          { validator: validateEmailField, trigger: 'change' }
        ],
        sms_channel_field: [
          { validator: validateSmsField, trigger: 'change' }
        ],
        email_optin_field: [
          { validator: validateEmailOptin, trigger: 'change' }
        ],
        sms_optin_field: [
          { validator: validateSmsOptin, trigger: 'change' }
        ],
        mobile_settings_mono: [
          { validator: validateMobileSettingsMono, trigger: 'change' }
        ],
        mobile_settings_multi: [
          { validator: validateMobileSettingsMultiCountry, trigger: 'change' }
        ]
      },
      loadingOptins: false,
      emailOptins: [],
      smsOptins: [],
      countries: [],
      displayRequiredFieldsError: false,
      previousList: {},
      lockedFields: ['EMAIL', 'MOBILE', 'COUNTRY_CODE']
    }
  },
  computed: {
    emailFields () {
      var cleanFields = clone(this.list.fields)
      if (this.fieldsForm.sms_field) {
        remove(cleanFields, {id: this.fieldsForm.sms_field})
      }
      if (this.fieldsForm.country_code_fields) {
        remove(cleanFields, {id: this.fieldsForm.country_code_fields})
      }
      return cleanFields
    },
    smsFields () {
      var cleanFields = clone(this.list.fields)
      if (this.fieldsForm.email_field) {
        remove(cleanFields, {id: this.fieldsForm.email_field})
      }
      if (this.fieldsForm.country_code_fields) {
        remove(cleanFields, {id: this.fieldsForm.country_code_fields})
      }
      return cleanFields
    },
    countryCodeFields () {
      var cleanFields = clone(this.list.fields)
      if (this.fieldsForm.email_field) {
        remove(cleanFields, {id: this.fieldsForm.email_field})
      }
      if (this.fieldsForm.sms_field) {
        remove(cleanFields, {id: this.fieldsForm.sms_field})
      }
      return cleanFields
    }
  },
  created () {
    this.initCountries()
    this.initOptins()
    .then(() => {
      this.initForm()
    })
  },
  mounted () {
  },
  methods: {
    handleNext (triggerSaving) {
      this.$emit('update:loading', true)
      if (triggerSaving) {
        if (!this.fieldsForm.email_field && !this.fieldsForm.sms_field) {
          this.displayRequiredFieldsError = true
          this.$emit('update:loading', false)
          return
        }
        return new Promise((resolve) => {
          this.$refs['fieldsForm'].validate((valid) => {
            if (valid) {
              var updateForm = this.formatForm()
              let data = {
                'listId': this.list.id,
                'data': updateForm
              }
              this.$store.dispatch('lists/patchList', data)
              .then(() => {
                this.$emit('update:step', 3)
              })
              .catch(() => {
                  this.$notify({
                    title: this.$t('ERRORS.UNEXPECTED'),
                    message: this.$t('ERRORS.UNEXPECTED_DESC'),
                    type: 'error'
                  })
              })
              this.$emit('update:loading', false)
            } else {
              this.$emit('update:loading', false)
            }
            resolve(valid)
          })
        })
      } else {
        this.$emit('update:loading', false)
        this.$emit('update:step', 3)
      }
    },
    handlePrevious () {
      this.$emit('update:step', 1)
    },
    initForm () {
      // init email
      let emailField = find(this.list.fields, function (field) {
        return field.name_in_db.toUpperCase() == 'EMAIL'
      })
      if (emailField) {
        this.fieldsForm.email_field = emailField.id
        if (emailField.optin_type_id) {
          this.fieldsForm.email_optin_field = emailField.optin_type_id
        }
      }

      this.previousList.email_field = emailField

      // init sms
      let smsField = find(this.list.fields, function (field) {
        return field.name_in_db.toUpperCase() == 'MOBILE'
      })
      if (smsField) {
        this.fieldsForm.sms_field = smsField.id
        if (smsField.optin_type_id) {
          this.fieldsForm.sms_optin_field = smsField.optin_type_id
        }
      }

      this.previousList.sms_field = smsField

      // init mobile settings
      if (this.list.default_country_code != null) {
        this.fieldsForm.default_country_code = this.list.default_country_code

        this.previousList.default_country_code = this.list.default_country_code
      }

      let countryCodeField = find(this.list.fields, function (field) {
        return field.name_in_db.toUpperCase() == 'COUNTRY_CODE'
      })
      if (countryCodeField != null) {
        this.fieldsForm.country_code_fields = countryCodeField.id
        this.fieldsForm.mobile_settings = this.mobileSettingsType.MULTI

        this.previousList.country_code_fields = countryCodeField
      }

    },
    initOptins () {
      return new Promise((resolve, reject) => {
      this.loadingOptins = true
      this.$store.dispatch('groups/getGroupsOptins', this.list.group_id)
        .then((response) => {
          var optins = response.objects
          this.emailOptins = optins.filter(optin => optin.channel_id == config.CHANNELS.EMAIL)
          this.smsOptins = optins.filter(optin => optin.channel_id == config.CHANNELS.SMS)
          this.loadingOptins = false
          resolve()
        })
        .catch(() => {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
          this.loadingOptins = false
          reject()
        })
      })
    },
    initCountries () {
      import(`@/assets/data/countries/countries_${this.$i18n.locale}.json`).then((countries) => {
        this.countries = countries.default
      })
    },
    cleanField (field) {
      switch(field) {
        case 'email_field':
          this.fieldsForm.email_optin_field = null
          this.formChange('email_field')
          break
        case 'sms_field':
          this.fieldsForm.sms_optin_field = null
          this.formChange('sms_field')
          break
        default:
          return
      }
    },
    formatForm (exit) {
      var $this = this
      var updateForm = {'fields': [], 'default_country_code': null, 'step': 3}
      if (exit) {
        updateForm['step'] = 2
      }

      let newEmailField = find(this.list.fields, function (field) { return field.id == $this.fieldsForm.email_field})
      if (newEmailField != null) {
        newEmailField.name_in_db = 'EMAIL'
        newEmailField.optin_type_id = this.fieldsForm.email_optin_field
        updateForm.fields.push(newEmailField)
      }

      // clean the previous email field
      if ((newEmailField != null && this.previousList.email_field && newEmailField.id != this.previousList.email_field.id)
          ||
          (newEmailField == null && this.previousList.email_field)) {
        this.previousList.email_field.name_in_db = this.previousList.email_field.name_in_file
        this.previousList.email_field.optin_type_id = null
        updateForm.fields.push(this.previousList.email_field)
      }

      let newSmsField = find(this.list.fields, function (field) { return field.id == $this.fieldsForm.sms_field})
      let newCountryCodeFields = find(this.list.fields, function (field) { return field.id == $this.fieldsForm.country_code_fields})
      if (newSmsField) {
        newSmsField.name_in_db = 'MOBILE'
        newSmsField.optin_type_id = this.fieldsForm.sms_optin_field
        updateForm.fields.push(newSmsField)

        if (this.fieldsForm.mobile_settings == this.mobileSettingsType.MONO) {
          updateForm.default_country_code = this.fieldsForm.default_country_code
        } else {
          newCountryCodeFields.name_in_db = 'COUNTRY_CODE'
          updateForm.fields.push(newCountryCodeFields)
        }
      }

      // clean the previous sms and country_code_fields field
      if ((newSmsField != null && this.previousList.sms_field && newSmsField.id != this.previousList.sms_field.id)
          ||
          (!newSmsField && this.previousList.sms_field)) {
          this.previousList.sms_field.name_in_db = this.previousList.sms_field.name_in_file
          this.previousList.sms_field.optin_type_id = null
          updateForm.fields.push(this.previousList.sms_field)
        }

      // clean mobile settings
      if (!newSmsField && this.previousList.country_code_fields) {
        updateForm.default_country_code = null
        this.previousList.country_code_fields.name_in_db = this.previousList.country_code_fields.name_in_file
        updateForm.fields.push(this.previousList.country_code_fields)
      }
      if (newSmsField && this.previousList.country_code_fields &&
        ((newCountryCodeFields && newCountryCodeFields.id != this.previousList.country_code_fields.id)
          ||
        (updateForm.default_country_code != null))) {
        this.previousList.country_code_fields.name_in_db = this.previousList.country_code_fields.name_in_file
        updateForm.fields.push(this.previousList.country_code_fields)
      }
      this.displayRequiredFieldsError = false
      return updateForm
    },
    save: function (triggerSaving) {
      if (triggerSaving) {
        return new Promise((resolve, reject) => {
          this.$refs['fieldsForm'].validate((validated) => {
            if (validated) {
              var updateForm = this.formatForm(true)
              this.$store.dispatch('lists/patchList', { listId: this.list.id , 'data': updateForm })
              .then(() => resolve(validated))
              .catch((error) => reject(error))
            } else {
              resolve(validated)
            }
          })
        })
      }
    },
    formChange: function(fieldName, extraParam) {
      if (fieldName == "email_field" || fieldName == 'sms_field') {
          var selectedField = this.list.fields.find((x) => x.id === extraParam)
          var defaultFieldName = (fieldName === 'sms_field' ? 'MOBILE' : 'EMAIL')
          var defaultField = find(this.list.fields,
            function (field) {
              return field.name_in_file.toUpperCase() == defaultFieldName
            }
          )
          // If user unselect field and there is no default field for this field, remove error of this current field and emit changes
          if (!selectedField && !defaultField) {
            delete this.errors[(fieldName === 'sms_field' ? 'sms' : 'email')]
            this.$emit('update:change-detected', true)
            return false
          }
          if (defaultField && ((selectedField && defaultField.id !== selectedField.id) || !selectedField)) {
              this.errors[(fieldName === 'sms_field' ? 'sms' : 'email')] = {
                error: 'SETUP.FIELDS.RESERVED_COLUMN_MAPPING',
                column: defaultField.name_in_db,
                field: (fieldName === 'sms_field' ? 'sms' : 'email')
              }
          } else {
              delete this.errors[(fieldName === 'sms_field' ? 'sms' : 'email')]
          }
      }
      this.$emit('update:change-detected', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--setup-fields-width: 600px;
.setup-fields {
    margin: 50px auto 24px;
    width: $--setup-fields-width;
    font-size: 12px;

    .setup-active-list__alert {
      margin-bottom:20px;

      ::v-deep .el-alert__title {
        font-size: 12px !important;
      }
    }

    &__alert {
      margin: 10px 0px;
      word-break: break-word;
    }

    &__title {
      word-break: break-word;
      color: $--color-dark-gray;
      font-weight: 400;
    }

    &__description {
      word-break: break-word;
      margin: 12px 0px;
      color: $--color-gray;
    }

    &__form {
      ::v-deep .el-col {
        margin-left: 38px;
      }
      &__header {
        margin-bottom: 10px;

        ::v-deep .el-form-item__label, ::v-deep .el-form-item__content {
          font-size: 12px;
          font-weight: 600;
        }
      }

      ::v-deep .el-select {
        width: 100%;
      }

      &__mobile-settings {
        width: 100%;
        padding: 18px;
        border-radius: 3px;
        border: 1px solid $--color-medium-gray;
        background-color: $--color-light-gray;

        &__title {
          font-size: 12px;
          font-weight: 600;
          color: $--text-dark;
        }

        &__label {
          margin-top: 18px;
          margin-bottom: 0px;
          font-size: 12px;
        }

        &__radio {
          margin-bottom: 0px;

          ::v-deep .el-form-item__content {
            margin-left: 0px !important;
          }
        }

        &__select-country {
          margin-bottom: 0px;
          ::v-deep .el-form-item__label {
            color: $--color-gray;
            width: 280px !important;
            .material-icons {
              margin-left: 4px;
              color: $--color-secondary;
              font-size: 16px;
              height: 35px;
              line-height: 35px;
              vertical-align: middle;
            }
          }

          ::v-deep .el-form-item__content {
            margin-left: 280px !important;
            .el-form-item {
              .el-form-item__content {
                margin-left: 0px !important;
                .el-select {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
}
</style>
