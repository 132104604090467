<template>
  <div>
    <AppHeader
      :scrolled="headerScrolled"
    />
    <div
      id="body-content"
      class="body-content"
      @scroll.passive="handleScroll"
    >
      <transition>
        <router-view />
      </transition>
    </div>
    <SnapcallSupport
      v-if="showSnapcall"
      :button-id="snapcallId"
      :show-snapcall="showSnapcall"
      @end-snapcall="endCall"
    />
    <AppFooter />
  </div>
</template>

<script>
import { AppHeader, AppFooter } from '@/components'
import { debounce as _debounce  } from 'lodash-es'
import { SnapcallSupport } from '@team-crm-automation/nmp-components-vue2'

export default {
  name: 'ContainerMain',
  components: { AppHeader, AppFooter, SnapcallSupport },
  data () {
    return {
      headerScrolled: false
    }
  },
  computed: {
    showSnapcall() {
      return this.$store.getters['snapcall/showSnapcall']
    },
    snapcallId() {
      return this.$store.getters['snapcall/snapcallId']
    }
  },
  methods: {
    handleScroll: _debounce(function (e) {
      this.headerScrolled = e.target.scrollTop > 0
    }, 10),
    endCall() {
      this.$store.dispatch('snapcall/endSnapcall')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.body-content {
  width: 100%;
  margin-top: $--header-height;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 95vh;
}

@media only screen and (max-width: $--mobile-width) {
  .body-content {
    padding-bottom: calc(#{$--footer-height} + 20px);
  }
}

.snapcall-support {
  ::v-deep .el-button {
    min-width: unset;
    width: 20px;
  }
}
</style>
