<template>
  <div id="app-container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // Detect if mobile or desktop, interface not permitted on mobile screen
    isMobile: function () {
     if( navigator.userAgent.match(/Android/i)
       || navigator.userAgent.match(/webOS/i)
       || navigator.userAgent.match(/iPhone/i)
       || navigator.userAgent.match(/iPad/i)
       || navigator.userAgent.match(/iPod/i)
       || navigator.userAgent.match(/BlackBerry/i)
       || navigator.userAgent.match(/Windows Phone/i)
     ){
        return true
      }
     else {
        return false
      }
    },
    handleResize: function () {
      this.mode = this.isMobile() ? 'mobile' : 'desktop'
    }
  }
}
</script>

<style lang="scss">
html {
  min-height: 100%;
}

body {
  min-height: 100%;
  overflow: hidden;
}

body.loading-screen,
body.hide-scroll {
  overflow: hidden;
}
</style>
