<template>
  <el-dialog
    custom-class="export-list"
    :append-to-body="true"
    :fullscreen="true"
    :show-close="false"
    :visible="showModal"
    center
    @close="closeModal"
  >
    <!-- TITLE -->
    <div
      slot="title"
    >
      <el-button
        size="medium"
        class="export-list__exit-btn"
        :disabled="loadingSubmit"
        @click="closeModal()"
      >
        {{ $t('BUTTON.EXIT') }}
      </el-button>
      <h2 class="title">
        {{ $t('EXPORT_LIST.TITLE') }}
      </h2>
      <div class="subtitle">
        {{ list.name }}
      </div>
    </div>
    <!-- FORM EXPORT -->
    <div class="export-list__content">
      <p v-if="list.group && hasExportPermission">
        {{ $t('EXPORT_LIST.SUBTITLE_EXPORT_PERMISSION') }}
      </p>
      <p v-else-if="list.group && !hasExportPermission">
        {{ $t('EXPORT_LIST.SUBTITLE_NO_EXPORT_PERMISSION') }}
      </p>

      <div v-if="!emptyFilters">
        <el-alert
          :title="$t('EXPORT_LIST.FILTERS_WARNING')"
          type="warning"
          show-icon
          :closable="false"
        />
      </div>

      <el-form
        ref="form"
        class="export-list__form"
        label-width="0"
        :model="form"
        :disabled="loadingSubmit"
        :rules="rules"
      >
        <!-- DELIMITER -->
        <el-form-item
          class="export-list__radio-groups"
          prop="delimiter"
          required
        >
          <p>{{ $t('EXPORT_LIST.COLUMN_DELIMITER') }}</p>
          <el-radio-group v-model="form.delimiter">
            <el-radio :label="'\t'">
              {{ $t('SETUP.UPLOAD.DELIMITER_OPTION_2') }}
            </el-radio>
            <el-radio label=",">
              {{ $t('SETUP.UPLOAD.DELIMITER_OPTION_3') }}
            </el-radio>
            <el-radio label=";">
              {{ $t('SETUP.UPLOAD.DELIMITER_OPTION_4') }}
            </el-radio>
            <el-radio label="1">
              {{ $t('SETUP.UPLOAD.DELIMITER_OPTION_5') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="isOtherDelimiter"
          prop="otherDelimiter"
        >
          <el-input
            v-model="form.otherDelimiter"
            maxlength="1"
          />
        </el-form-item>

        <!-- INPUT TEXT QUALIFIER -->
        <el-form-item
          class="export-list__input"
          prop="textQualifier"
        >
          <p>{{ $t('EXPORT_LIST.TEXT_QUALIFIER') }}</p>
          <el-input
            v-model="form.textQualifier"
            maxlength="1"
          />
        </el-form-item>

        <!-- ADD EMAIL -->
        <el-form-item
          v-if="hasExportPermission"
          class="export-list__select"
          prop="recipients"
        >
          <p>{{ $t('EXPORT_LIST.RECIPIENTS_RECO.ADD_EMAIL') }}</p>
          <el-select
            v-model="form.recipients"
            filterable
            multiple
            allow-create
            autocomplete="no"
            default-first-option
            placeholder=""
            remote
            :value="form.recipients"
            :no-data-text="$t('MISC.NO_DATA')"
            :remote-method="getRecipients"
            :loading="recipientsLoading"
            @change="selectRecipient"
            @focus="getRecipients"
          >
            <el-option
              v-for="recipient in recipientOptions"
              :key="recipient.id"
              :label="recipient.recipient"
              :value="recipient.recipient"
            >
              {{ recipient.recipient }}
              <el-button
                class="delete-recipient-btn"
                type="text"
                @click="(event) => deleteRecipient(recipient.id, event)"
              >
                <i class="material-icons text-medium">
                  close
                </i>
              </el-button>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="export-list__submit text-center">
          <el-button
            type="primary"
            size="medium"
            :loading="loadingSubmit"
            @click="submit"
          >
            {{ $t('EXPORT_LIST.EXPORT_BTN') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import config from '@/config'
export default {
  name: 'ModalExportList',
  components: { },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    list: {
      type: Object,
      required: true
    },
    filters: {
      type:Object,
      default: () => {}
    }
  },
  data: function () {
    var validateEmails = (rule, value, callback) => {
      // Check if all emails are correct
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let checkMsg = ''
      let incorrectEmails = []
      for (let i = 0; i < this.form.recipients.length; i++) {
        let email = this.form.recipients[i]
        if (!re.test(String(email).toLowerCase())) {
          incorrectEmails.push(email)
        }
      }
      if (incorrectEmails.length >= 1) {
        if (incorrectEmails.length === 1) {
          checkMsg += incorrectEmails[0] + ' ' + this.$t('EXPORT_LIST.ERROR_RECIPIENT')
        } else {
          checkMsg = incorrectEmails.join(', ')
          checkMsg += ' ' + this.$t('EXPORT_LIST.ERROR_RECIPIENTS')
        }
        callback(new Error(checkMsg))
      } else {
        callback()
      }
    }
    return {
      form: {
        delimiter: '\t',
        textQualifier: '"',
        recipients: []
      },
      rules: {
        delimiter: [
          { required: true, message: this.$t('EXPORT_LIST.COLUMN_DELIMITER_ERROR'), trigger: 'blur' },
        ],
        recipients: [
          { required: false, validator: validateEmails, trigger: 'blur' }
        ]
      },
      recipientOptions: [],
      selectedRecipients: [],
      loadingSubmit: false
    }
  },
  computed: {
    isOtherDelimiter() {
      if (
        this.form.delimiter != ";" &&
        this.form.delimiter != "," &&
        this.form.delimiter != "\t"
      ) {
        return true
      }
      return false
    },
    hasExportPermission () {
      return this.$store.getters['auth/hasPermissionInGroup'](this.list.group_id, config.PERMISSIONS.LIST_EXPORT)
    },
    emptyFilters () {
      return Object.values(this.filters).every(x => x === undefined)
    },
    recipientsLoading () {
      return this.$store.getters['recipients/loading']
    },
  },
  methods: {
    closeModal () {
      this.$emit('update:showModal', false)
    },
    validateEmailList () {
      this.$refs['form'].validateField('recipients')
    },
    getRecipients () {
      let params = {}
      this.$store.dispatch('recipients/getRecipients', params)
      .then(data => {
          this.recipientOptions = data
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
    },
    selectRecipient (selected) {
      // check if remove case or add case
      if (selected.length <= this.form.recipients.length) {
        this.form.recipients = selected
      } else {
        // if add case check if recipientList selected or recipient
        let selectedValue = selected.pop()
        if (selectedValue instanceof Object) {
          // check duplicate
          let recipientsTmp = this.form.recipients.concat(selectedValue)
          this.form.recipients = [...new Set(recipientsTmp)]
        } else if (this.form.recipients.indexOf(selectedValue) === -1) {
          this.form.recipients.push(selectedValue)
        }
      }
      this.$refs['form'].validate((valid) => {
        if (! valid) { return false }
      })
    },
    buildRecipientsList () {
      let existingEmails = this.recipientOptions.map(r => r.recipient)

      // separate existing emails and new ones
      let selectedExistingEmails = this.form.recipients.filter(r => existingEmails.includes(r))
      let selectedNewEmails = this.form.recipients.filter(r => !existingEmails.includes(r))

      // build recipients array
      let recipients = this.recipientOptions.filter(r => selectedExistingEmails.includes(r.recipient))
      for (let i = 0; i < selectedNewEmails.length; i++) {
        recipients.push({id: null, recipient: selectedNewEmails[i]})
      }
      return recipients
    },
    async deleteRecipient (recipientId, event) {
      // block element ui from selecting option on click
      event.stopPropagation()
      // delete recipient
      this.$store.dispatch('recipients/deleteRecipient', recipientId)
      .then(() => {
        this.recipientOptions= this.recipientOptions.filter(o => o.id != recipientId)
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
    },
    submit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loadingSubmit = true
          let data = {
            delimiter: this.isOtherDelimiter
              ? this.form.otherDelimiter
              : this.form.delimiter,
            text_qualifier: this.form.textQualifier,
          }
          if (this.filters.s) {
            data.search = this.filters.s
          }
          if (this.filters.status) {
            data.filters = {}
            let filterStatus = this.filters.status.split(",")
            let emailStatus = filterStatus.filter(elem => elem.startsWith("EMAIL_"))
            let mobileStatus = filterStatus.filter(elem => elem.startsWith("MOBILE_"))
            if (emailStatus.length > 0) {
              data.filters.email_status = emailStatus.map(elem => elem.replace("EMAIL_", "")).join(",")
            }
            if (mobileStatus.length > 0) {
              data.filters.sms_status = mobileStatus.map(elem => elem.replace("MOBILE_", "")).join(",")
            }
          }
          if (this.form.recipients.length ) {
            data.recipients = this.buildRecipientsList()
          }
          this.$store.dispatch('exports/exportList', {'listId': this.list.id, 'params': data})
          .then(() => {
            this.closeModal()
            this.$notify({
              title: this.$t('EXPORT_LIST.NOTIFICATION_TITLE'),
              message: this.$t('EXPORT_LIST.NOTIFICATION_DESCRIPTION'),
              type: 'success'
            })
          })
          .catch((error) => {
            if (error.data.code == 409) {
              let importError = error.data.message === 'List is being updated.'
              this.$notify({
                title: importError ? this.$t('ERRORS.IMPORT_TITLE') : this.$t('ERRORS.EXPORT_TITLE'),
                message: importError ? this.$t('ERRORS.IMPORT_IN_PROGRESS_DESC') : this.$t('ERRORS.EXPORT_IN_PROGRESS_DESC'),
                type: 'error'
              })
            } else {
              this.$notify({
                title: this.$t('ERRORS.UNEXPECTED'),
                message: this.$t('ERRORS.UNEXPECTED_DESC'),
                type: 'error'
              })
            }
          })
          .finally(this.loadingSubmit = false)
          return
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--mobile-width: 450px;

.export-list__select {
  p {
    line-height: 1.5em;
  }
  .el-select {
    width: 100%;
  }
}

.el-select-dropdown__item {
  width: 440px;
  .delete-recipient-btn {
    display: none;
  }
}
.el-select-dropdown__item:not(.selected):hover {
  .delete-recipient-btn {
    display: inline;
    position: absolute;
    right: 7px;
  }
}
::v-deep .el-button {
  min-width: 20px;
}
::v-deep .export-list {
  background: $--color-bg-light-gray;
  .el-dialog__header {
    padding-top: 48px;
  }
  .title {
    margin: 10px;
    font-size: 36px;
    font-weight: 300;
  }
  .subtitle {
    margin: 10px;
    font-size: 21px;
    font-weight: 400;
  }
  &__exit-btn {
    position: absolute;
    top: 36px;
    right: 44px;
  }

  &__content {
    color: $--color-gray;
    max-width: $--mobile-width;
    width: 50%;
    margin: 0 auto;
    .el-row {
      padding: 1rem;
      border-bottom: 2px solid $--color-light-gray;
    }
  }
  &__radio-groups {
    .el-radio {
      display: block;
      margin: 0 0 1rem 0;
      color: $--text-dark;
    }
  }

  ::v-deep .el-select {
    width: 100%;
  }
  &__submit {
    margin-top: 3rem;
  }
}
</style>
