<template>
  <el-dialog
    custom-class="dialog-modal"
    :visible="dialogVisible"
    :width="width"
    :append-to-body="true"
    :show-close="true"
    @close="closeModal"
  >
    <!-- back button -->
    <el-button
      class="dialog-modal__back-btn"
      type="text"
      @click="backToColumnRequirements"
    >
      <i class="el-icon-arrow-left dialog-modal__back-btn" />
      {{ $t('SETUP.COLUMN_REQUIREMENTS_LINK') }}
    </el-button>
    <!-- Table header -->
    <div class="dialog-modal__header">
      <div class="dialog-modal__header__name">
        {{ $t('SETUP.COLUMNS.COLUMN_NAME') }}
      </div>
      <div class="dialog-modal__header__detail">
        {{ $t('SETUP.COLUMNS.COLUMN_REQUIRED') }}
      </div>
      <div class="dialog-modal__header__detail">
        {{ $t('SETUP.COLUMNS.COLUMN_TYPE') }}
      </div>
    </div>

    <!-- Table rows -->
    <div v-if="list.fields">
      <div
        v-for="item in list.fields"
        :key="item.id"
        class="dialog-modal__list"
      >
        <div
          class="dialog-modal__list-item"
          :class="{'is-primary': item.primary_key, 'is-not-primary': !item.primary_key}"
        >
          <div class="dialog-modal__list-item__name-column">
            {{ item.name_in_file }}
            <div
              v-show="item.optin_type_id"
              class="dialog-modal__list-item__name-column__contactability"
            >
              {{ displayContactabilityField(item) }}
            </div>
            <div
              v-show="item.primary_key"
              class="dialog-modal__list-item__name-column__primary"
            >
              <img
                alt="primary-key"
                :src="keyLogo"
              >
            </div>
          </div>
          <div class="dialog-modal__list-item__detail-column">
            {{ item.mandatory ? $t('MISC.YES') : $t('MISC.NO') }}
          </div>

          <div class="dialog-modal__list-item__detail-column">
            {{ $t(dataTypes[item.datatype_id - 1].label) }}
            <span v-if="isDateField(item)">
              {{ `(${getDateFormat(item)})` }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import openBook from '@/assets/img/open-book.svg'
import config from '@/config'
import keyLogo from '@/assets/img/key.svg'

export default {
  name: 'ModalListColumns',
  props: {
    dialogVisible: { type: Boolean, default: false },
    width: { type: String, default: '30%'},
    list : {type: Object, default: () => {}},
  },
  data() {
    return {
      openBookLogo: openBook,
      dataTypes: config.REF_DATA_TYPES,
      keyLogo: keyLogo,
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:dialogVisible', false)
    },
    backToColumnRequirements () {
      this.$emit('backToColumnRequirements', true)
      this.closeModal()
    },
    displayContactabilityField(item) {
      return this.$t('SETUP.FIELDS.CONTACTABILITY_FIELD', {field: item.name_in_db.toLowerCase()})
    },
    getDateFormat(item) {
      return this.dataTypes.filter(d => d.value === item.datatype_id)[0]
        .children.filter(c => c.value === item.value_format)[0].label
    },
    isDateField(item) {
      return [4, 5].includes(item.datatype_id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

::v-deep .dialog-modal {
  padding: 0px 24px 24px 24px;
  .dialog-modal__back-btn {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 20px;
  }
  .dialog-modal__header {
    display: flex;
    font-weight: 550;
    font-size: 13px;
    padding: 10px;
    .dialog-modal__header__name {
      width: 44%
    }
    .dialog-modal__header__detail {
      width: 28%
    }
  }
  .dialog-modal__list-item {
    display: flex;
    text-align: left;
    padding: 10px;
    font-size: 11px;
    color: $--color-text-regular;

    .dialog-modal__list-item__name-column {
      width: 44%;
      display: flex;
      .dialog-modal__list-item__name-column__contactability {
        padding-left: 10px;
        color: $--color-text-secondary;
      }
      .dialog-modal__list-item__name-column__primary {
        padding-left: 10px;
      }
    }
    .dialog-modal__list-item__detail-column {
      width: 28%;
      span {
        padding-left: 3px;
        overflow-wrap: break-word;
      }
    }
  }
  .is-primary {
    border: 1px solid lighten($--color-tertiary, 30%);
    border-radius: 3px;
    background-color: lighten($--color-tertiary, 48%);
  }
  .is-not-primary {
    border-bottom: solid 1px $--color-text-placeholder;
  }
}
</style>
