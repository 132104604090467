<!-- eslint-disable vue/no-v-html -->
<template>
  <el-dialog
    custom-class="dialog-modal"
    :visible="dialogVisible"
    :append-to-body="true"
    :show-close="true"
    @close="closeModal"
  >
    <div
      slot="title"
      class="dialog-modal__title"
    >
      <div>
        {{ $t('CONTACT_CARDS.MODALS.GROUPS.TITLE') }}
      </div>
    </div>
    <div class="dialog-modal__information">
      <!-- intro sentence -->
      <span>
        {{ $t('CONTACT_CARDS.MODALS.GROUPS.INTRO') }}
      </span>
      <el-input
        v-model="search"
        class="dialog-modal__information__search"
        :placeholder="$t('CONTACT_CARDS.MODALS.GROUPS.SEARCH')"
        clearable
        maxlength="250"
        @clear="clearSearch"
      />
      <div
        v-for="group in filteredGroups"
        :key="group.name"
        class="dialog-modal__information__item"
      >
        <el-tooltip
          effect="dark"
          :content="group.name"
          placement="top"
        >
          <AvatarHolder
            :key="group.name"
            :avatar-url="group.logo_url || null"
            :display-name="group.name"
            :width="25"
            :height="25"
            :font-size="8"
          />
        </el-tooltip>
        <span class="dialog-modal__information__item__name">
          {{ group.name }}
        </span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { AvatarHolder }  from '@/components'

export default {
  name: 'ModalGroups',
  components: { AvatarHolder },
  props: {
    dialogVisible: { type: Boolean, default: false },
    groups: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    filteredGroups () {
      var query = this.search
      return this.groups
      .filter(group => group.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:dialogVisible', false)
    },
    clearSearch () {
      this.search =''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
::v-deep .dialog-modal {
  padding: 20px;
  height: 600px;
  width: 500px;
  overflow-y: auto;
  .el-dialog__header {
    padding-bottom: 0;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .dialog-modal__title {
    color: $--color-primary;
    font-weight: 600;
  }
  .dialog-modal__information {
    word-break: break-word;
    .dialog-modal__information__search {
      margin-top: 20px;
      margin-bottom: 20px;
      .el-input {
        width: 120px
      }
    }
    .dialog-modal__information__item {
      height: 40px;
      display: flex;
      border-bottom: 1px solid $--color-light-gray;
      margin-top: 20px;
    }
    .dialog-modal__information__item__name {
      margin-left: 10px;
    }
  }
}
@media only screen and (max-width: $--mobile-width) {
  ::v-deep .dialog-modal {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0;
    height: 100%;
    overflow: auto;
  }
}
</style>
