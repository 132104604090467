import Vue from 'vue'
import config from '@/config'
import router from '@/router'

const moduleExports = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    exportList (_, { listId, params }) {
      const url = `${config.API_URL}/lists/${listId}/exports`
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, params)
          .then(response => {
            if (response.status != 201) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
    getListExports (_, { listId, params }) {
      const url = `${config.API_URL}/lists/${listId}/exports`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: params })
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            if (error.status === 403) {
              router.push({ path: '/lists' })
            }
            reject(error.response ? error.response : error)
          })
      })
    },
    getExportActivity (_, { listId, params }) {
      const url = `${config.API_URL}/lists/${listId}/exports/activity`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: params })
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
    downloadExport (_, { listId, exportId }) {
      const url = `${config.API_URL}/lists/${listId}/exports/${exportId}`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response)
          })
          .catch(error => {
            if (error.status === 403) {
              router.push({ path: '/lists' })
            }
            reject(error.response ? error.response : error)
          })
      })
    }
  },
  getters: {
  }
}

export default moduleExports
