import Vue from 'vue'
import config from '@/config'
import axios from 'axios'

const CancelToken = axios.CancelToken
let listCancel

const SET_LIST = 'SET_LIST'
const SET_LISTS = 'SET_LISTS'
const SET_EXTENDED_LIST = 'SET_EXTENDED_LIST'
const SET_CONTACTS = 'SET_CONTACTS'
const SET_TOTAL_CONTACTS = 'SET_TOTAL_CONTACTS'
const SET_LOADING = 'SET_LOADING'
const SET_LOADING_CONTACTS = 'SET_LOADING_CONTACTS'
const SET_DELETING_LISTS = 'SET_DELETING_LISTS'
const SET_UPDATING_LIST = 'SET_UPDATING_LIST'
const SET_UPLOADING = 'SET_UPLOADING'
const SET_ATTR_LIST = 'SET_ATTR_LIST'
const SET_STATIC_FILES = 'SET_STATIC_FILES'
const SET_STATIC_FILES_LOADING = 'SET_STATIC_FILES_LOADING'
const SET_TYPE_FILTER = 'SET_TYPE_FILTER'
const SET_AUTHOR_FILTER = 'SET_AUTHOR_FILTER'
const SET_GROUP_FILTER = 'SET_GROUP_FILTER'
const SET_LISTS_SEARCH = 'SET_LISTS_SEARCH'
const SET_LIST_EXPIRATION_DATE = 'SET_LIST_EXPIRATION_DATE'
const SET_EXPIRING_SOON = 'SET_EXPIRING_SOON'

const moduleLists = {
  namespaced: true,
  state: {
    lists: [],
    staticFiles: [],
    contacts: {},
    totalContacts: 0,
    extendedList: null,
    loading: false,
    loadingContacts: false,
    staticFilesLoading: false,
    creatingList: false,
    deletingLists: false,
    updatingList: false,
    uploading: false,
    expiringSoon: false,
    list: null,
    filters: {
      search: '',
      group: [],
      type: [],
      author: []
    }
  },
  mutations: {
    [SET_LIST] (state, list) {
      Vue.set(state, 'list', list)
    },
    [SET_LISTS] (state, lists) {
      Vue.set(state, 'lists', lists)
    },
    [SET_STATIC_FILES] (state, files) {
      Vue.set(state, 'staticFiles', files)
    },
    [SET_STATIC_FILES_LOADING] (state, loading) {
      Vue.set(state, 'staticFilesLoading', loading)
    },
    [SET_EXTENDED_LIST] (state, extendedList) {
      Vue.set(state, 'extendedList', extendedList)
    },
    [SET_CONTACTS] (state, contacts) {
      Vue.set(state, 'contacts', contacts)
    },
    [SET_TOTAL_CONTACTS] (state, total) {
      Vue.set(state, 'totalContacts', total)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    },
    [SET_LOADING_CONTACTS] (state, loading) {
      Vue.set(state, 'loadingContacts', loading)
    },
    [SET_DELETING_LISTS] (state, deleting) {
      Vue.set(state, 'deletingLists', deleting)
    },
    [SET_UPDATING_LIST] (state, updating) {
      Vue.set(state, 'updatingList', updating)
    },
    [SET_UPLOADING] (state, uploading) {
      Vue.set(state, 'uploading', uploading)
    },
    [SET_ATTR_LIST] (state, { attribute, value }) {
      Vue.set(state.list, attribute, value)
    },
    [SET_TYPE_FILTER] (state, types) {
      Vue.set(state.filters, 'type', types)
    },
    [SET_AUTHOR_FILTER] (state, authors) {
      Vue.set(state.filters, 'author', authors)
    },
    [SET_GROUP_FILTER] (state, groups) {
      Vue.set(state.filters, 'group', groups)
    },
    [SET_LISTS_SEARCH] (state, search) {
      Vue.set(state.filters, 'search', search)
    },
    [SET_LIST_EXPIRATION_DATE] (state, date) {
      Vue.set(state.list, 'expiration_date', date)
    },
    [SET_EXPIRING_SOON] (state, expiringSoon) {
      Vue.set(state, 'expiringSoon', expiringSoon)
    }
  },
  actions: {
    resetFilters ({ commit }) {
      commit(SET_TYPE_FILTER, [])
      commit(SET_AUTHOR_FILTER, '')
      commit(SET_LISTS_SEARCH, '')
    },
    setTypeFilter ({ commit }, types) {
      commit(SET_TYPE_FILTER, types)
    },
    setAuthorFilter ({ commit }, userId) {
      commit(SET_AUTHOR_FILTER, userId)
    },
    setGroupFilter ({ commit }, groupFilter) {
      commit(SET_GROUP_FILTER, groupFilter)
    },
    setSearch ({ commit }, s) {
      commit(SET_LISTS_SEARCH, s)
    },
    getQueryParams( { getters }) {
      let params = {}
      if (getters.search) { params.name = getters.search }
      if (getters.groupFilter.length) { params.group = getters.groupFilter.map(g => g.id).join(',') }
      if (getters.authorFilter.length) { params.author = getters.authorFilter.map(a => a.id).join(',') }
      if (getters.typeFilter.length) { params.type = getters.typeFilter.map(t => t.id).join(',') }
      return params
    },
    getLists ({ commit }, { page, search, groupId, limit, orderBy, typeId, authorId }) {
      commit(SET_LOADING, true)
      const params = {}
      if (listCancel) { listCancel() }
      const url = `${config.API_URL}/lists`
      if (page) { params['p'] = page }
      if (search && search.length > 0) { params['s'] = search }
      if (groupId && groupId.length > 0) { params['group_id'] = groupId }
      if (limit) { params['l'] = limit }
      if (orderBy) { params['ob'] = orderBy }
      if (typeId) { params['type_id'] = typeId }
      if (authorId) { params['created_by'] = authorId }
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {
          params: params,
          cancelToken: new CancelToken(function executor(c) {
            listCancel = c
          })
        }).then(response => {
            commit(SET_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            
            commit(SET_EXPIRING_SOON, response.data.meta.expiring_soon > 0)
            commit(SET_LISTS, response.data.objects)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            if (!axios.isCancel(error)) {
              reject(error.response ? error.response : error)
            }
          })
      })
    },
    createList ({ commit }, listForm) {
      const url = `${config.API_URL}/lists`
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, listForm)
          .then(response => {
            if (response.status >= 300) {
              reject(response)
            }
            commit(SET_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
    getStaticFiles ({ commit }, {listId}) {
      commit(SET_STATIC_FILES_LOADING, true)
      const url = `${config.API_URL}/lists/${listId}/storage/files/statics`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url).then(response => {
            commit(SET_STATIC_FILES_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_STATIC_FILES, response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_STATIC_FILES_LOADING, false)
            if (!axios.isCancel(error)) {
              reject(error.response ? error.response : error)
            }
          })
      })
    },
    patchList ({ commit }, { listId, data }) {
      const url = `${config.API_URL}/lists/${listId}`
      commit(SET_UPDATING_LIST, true)
      return new Promise((resolve, reject) => {
        Vue.axios.patch(url, data)
          .then(response => {
            commit(SET_UPDATING_LIST, false)
            if (response.status >= 300) {
              reject(response)
            }
            commit(SET_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_UPDATING_LIST, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    inferColumns ({ commit }, { listId, data }) {
      const url = `${config.API_URL}/lists/${listId}/infer`
      commit(SET_UPDATING_LIST, true)
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, data)
          .then(response => {
            commit(SET_UPDATING_LIST, false)
            if (response.status >= 300) {
              reject(response)
            }
            commit(SET_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_UPDATING_LIST, false)
            if (error.status == 400 && error.data.errors) {
              commit(SET_ATTR_LIST, {'attribute': 'errors', 'value': error.data.errors})
              reject(error.data)
            } else {
              reject(error.response ? error.response : error)
            }
          })
      })
    },
    validateColumns ({ commit }, { listId, data }) {
      const url = `${config.API_URL}/lists/${listId}/validate`
      commit(SET_UPDATING_LIST, true)
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, data)
          .then(response => {
            commit(SET_UPDATING_LIST, false)
            if (response.status >= 300) {
              reject(response)
            }
            commit(SET_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_UPDATING_LIST, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    activate ({ commit }, { listId, data }) {
      const url = `${config.API_URL}/lists/${listId}/activate`
      commit(SET_UPDATING_LIST, true)
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, data)
          .then(response => {
            commit(SET_UPDATING_LIST, false)
            if (response.status >= 300) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_UPDATING_LIST, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    deactivate ({ commit }, { listId }) {
      const url = `${config.API_URL}/lists/${listId}/deactivate`
      commit(SET_UPDATING_LIST, true)
      return new Promise((resolve, reject) => {
        Vue.axios.post(url)
          .then(response => {
            commit(SET_UPDATING_LIST, false)
            if (response.status >= 300) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_UPDATING_LIST, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    importContacts ({ commit }, { listId, data }) {
      const url = `${config.API_URL}/lists/${listId}/contacts`
      commit(SET_UPDATING_LIST, true)
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, data)
          .then(response => {
            commit(SET_UPDATING_LIST, false)
            if (response.status >= 300) {
              reject(response)
            }
            commit(SET_ATTR_LIST, {'attribute': 'updating', 'value': true})
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_UPDATING_LIST, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    bulkDelete ({ commit }, listsIds) {
      commit(SET_DELETING_LISTS, true)
      const url = `${config.API_URL}/lists`
      let data = { 'ids': listsIds }
      return new Promise((resolve, reject) => {
        Vue.axios.delete(url, { 'data': data })
          .then(response => {
            commit(SET_DELETING_LISTS, false)
            if (response.status >= 300) {
              reject(response)
            }
            resolve(response.data.deleted_count)
          })
          .catch(error => {
            commit(SET_DELETING_LISTS, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getList ({ commit }, listId) {
      const url = `${config.API_URL}/lists/${listId}`
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            const list = response.data
            // Save data in store
            commit(SET_LIST, list)
            commit(SET_EXTENDED_LIST, {
              author: list.author,
              group: list.group,
              contacts_count: list.contacts_count,
              email_optins: list.email_optins,
              sms_optins: list.sms_optins,
              last_update: list.last_update
            })
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    resetList ({ commit }) {
      commit(SET_LIST, null)
      commit(SET_CONTACTS, null)
      commit(SET_EXTENDED_LIST, null)
    },
    uploadFile ({ commit }, { listId, file, data }) {
      const url = `${config.API_URL}/lists/${listId}/preupload`
      commit(SET_UPLOADING, true)
      return new Promise((resolve, reject) => {
        // do pre-upload first
        Vue.axios.post(url, data)
          .then(response => {
            if (response.status != 200) {
              commit(SET_UPLOADING, false)
              reject(response)
            }
            // upload file to AWS S3
            var awsUploadUrl = response.data.url
            var awsUploadData = new FormData()
            for (let key in response.data.fields) {
              awsUploadData.append(key, response.data.fields[key])
            }
            awsUploadData.append('file', file.file)
            Vue.axios.post(awsUploadUrl, awsUploadData)
            .then(awsResponse => {
              commit(SET_UPLOADING, false)
              if (awsResponse.status != 204) {
                reject(awsResponse)
              }
              resolve(response.data)
            })
            .catch(reject)
          })
          .catch(error => {
            commit(SET_UPLOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getInsertionPreview({ commit }, listId) {
      const url = `${config.API_URL}/lists/${listId}/insertionpreview`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_ATTR_LIST, {'attribute': 'errors', 'value': response.data.errors})
            commit(SET_ATTR_LIST, {'attribute': 'step', 'value': response.data.redirect_step})
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
    getS3Folders(_, listId) {
      const url = `${config.API_URL}/lists/${listId}/storage/folders`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
    checkS3File(_, { listId, params }) {
      const url = `${config.API_URL}/lists/${listId}/storage/file`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: params })
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
    previewS3File(_, listId) {
      const url = `${config.API_URL}/lists/${listId}/storage/preview`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
    retrieveContacts({ commit }, { listId, params, storeContacts }) {
      const url = `${config.API_URL}/lists/${listId}/contacts`
      return new Promise((resolve, reject) => {
        commit(SET_LOADING_CONTACTS, true)
        Vue.axios.get(url, { params : params })
          .then(response => {
            if (response.status != 200) {
              commit(SET_LOADING_CONTACTS, false)
              reject(response)
            }
            commit(SET_LOADING_CONTACTS, false)
            if (storeContacts) {
              commit(SET_CONTACTS, response.data)
              if (!params.search) {
                // update total only if the list has not been filtered
                commit(SET_TOTAL_CONTACTS, response.data.meta.total)
              }
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING_CONTACTS, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    getActiveState(_, listId) {
      const url = `${config.API_URL}/lists/${listId}/status`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status != 200) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
    bulkDeleteContacts(_, { listId, deleteIds }) {
      const url = `${config.API_URL}/lists/${listId}/contacts`
      let data = { 'ids': deleteIds }
      return new Promise((resolve, reject) => {
        Vue.axios.delete(url, { 'data': data })
          .then(response => {
            if (response.status >= 300) {
              reject(response)
            }
            resolve(response.data.deleted_count)
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    }
  },
  getters: {
    lists: state => state.lists,
    staticFiles: state => state.staticFiles,
    extendedList: state => state.extendedList,
    contacts: state => state.contacts,
    totalContacts: state => state.totalContacts,
    loading: state => state.loading,
    loadingContacts: state => state.loadingContacts,
    deleting: state => state.deletingLists,
    updating: state => state.updatingList,
    list: state => state.list,
    groupFilter: state => state.filters.group,
    authorFilter: state => state.filters.author,
    typeFilter: state => state.filters.type,
    search: state => state.filters.search,
    expiringSoon: state => state.expiringSoon,
  }
}

export default moduleLists
