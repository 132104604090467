<template>
  <el-dialog
    custom-class="edit-list"
    :append-to-body="true"
    :fullscreen="true"
    :show-close="false"
    :visible="showModal"
    center
    @close="closeModal"
  >
    <!-- TITLE -->
    <div
      slot="title"
    >
      <el-button
        size="medium"
        class="edit-list__exit-btn"
        @click="closeModal()"
      >
        {{ $t('BUTTON.EXIT') }}
      </el-button>
      <h2 class="title">
        {{ $t('LIST_DETAILS.EDIT_LIST.TITLE') }}
      </h2>
      <div class="subtitle">
        {{ list.name }}
      </div>
    </div>

    <!-- FORM -->
    <div class="edit-list__content">
      <el-row
        type="flex"
        justify="center"
      >
        <el-col :span="24">
          <el-tabs
            class="edit-list__tabs"
          >
            <el-tab-pane :label="$t('LIST_DETAILS.EDIT_LIST.FILE_SETUP')">
              <div class="file-setup">
                <el-row>
                  <el-col
                    class="file-setup__label"
                    :span="6"
                  >
                    {{ $t('LIST_DETAILS.EDIT_LIST.FILE_FOLDER') }}
                  </el-col>
                  <el-col
                    class="file-setup__value"
                    :span="18"
                  >
                    <span
                      v-if="list.file_path"
                      class="text"
                    >
                      <span class="text--light">{{ fileFolder }} </span>
                    </span>
                    <span
                      v-else
                      class="text text--dark"
                    >
                      None
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col
                    class="file-setup__label"
                    :span="6"
                  >
                    {{ $t('LIST_DETAILS.EDIT_LIST.FILE_NAME') }}
                  </el-col>
                  <el-col
                    class="file-setup__value"
                    :span="18"
                  >
                    <span class="text text--blue">
                      {{ list.filename_pattern ? list.filename_pattern : 'None' }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col
                    class="file-setup__label"
                    :span="12"
                  >
                    {{ $t('LIST_DETAILS.EDIT_LIST.COLUMN_DELIMITER') }}
                  </el-col>
                  <el-col
                    class="file-setup__value"
                    :span="12"
                  >
                    {{ list.delimiter ? list.delimiter : 'None' }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-col
                    class="file-setup__label"
                    :span="12"
                  >
                    {{ $t('LIST_DETAILS.EDIT_LIST.TEXT_QUALIFIER') }}
                  </el-col>
                  <el-col
                    class="file-setup__value"
                    :span="12"
                  >
                    {{ list.text_qualifier ? list.text_qualifier : 'None' }}
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('LIST_DETAILS.EDIT_LIST.IMPORT_SETUP')">
              <SetupImport
                ref="setupImport"
                :step="1"
                class="edit-list__setup"
                :list="list"
              />
              <!-- INFO -->
              <el-alert
                class="edit-list__alert info"
                type="warning"
                show-icon
                :closable="false"
              >
                <div slot="title">
                  <span>
                    {{ $t('IMPORT_CONTACTS.NOTIFICATIONS.ALL_COLUMNS') }}
                  </span>
                  <el-button
                    type="text"
                    class="edit-list__column-requirements"
                    @click="showColumnRequirementModal = true"
                  >
                    {{ $t('MISC.COLUMN_REQUIREMENTS') }}
                  </el-button>
                </div>
              </el-alert>
              <div class="align-items text-center">
                <el-button
                  type="secondary"
                  :disabled="list.updating"
                  :loading="updatingList"
                  @click="showImportModal = true"
                >
                  <div class="align-items">
                    <i class="material-icons">download</i>
                    {{ $t('LIST_DETAILS.EDIT_LIST.FORCE_IMPORT') }}
                  </div>
                </el-button>
                <el-button
                  type="primary"
                  :disabled="list.updating"
                  :loading="updatingList"
                  @click="updateList"
                >
                  {{ $t('LIST_DETAILS.EDIT_LIST.APPLY') }}
                </el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
    <!-- FORCE IMPORT MODAL -->
    <Modal
      :dialog-visible.sync="showImportModal"
      width="500px"
      :title="$t('LIST_DETAILS.EDIT_LIST.FORCE_IMPORT_TITLE_MODAL')"
      :subtitle="$t('LIST_DETAILS.EDIT_LIST.FORCE_IMPORT_SUBTITLE_MODAL')"
      :btn="$t('BUTTON.CONFIRM')"
      type="danger"
      :action="forceImport"
    />
    <ModalColumnRequirements
      width="500px"
      :dialog-visible.sync="showColumnRequirementModal"
      :title="$t('SETUP.COLUMN_REQUIREMENTS')"
      @showListColumns="showListColumnsModal = true"
    />
    <ModalListColumns
      width="500px"
      :dialog-visible.sync="showListColumnsModal"
      :title="$t('SETUP.COLUMN_REQUIREMENTS')"
      :list="list"
      @backToColumnRequirements="showColumnRequirementModal = true"
    />
  </el-dialog>
</template>

<script>
import config from '@/config'
import SetupImport from '@/components/Parts/SetupImport'
import ModalColumnRequirements from '@/components/Parts/ModalColumnRequirements'
import ModalListColumns from '@/components/Parts/ModalListColumns'
import { Modal } from '..'

export default {
  name: 'ModalEditList',
  components: {
    SetupImport,
    Modal,
    ModalColumnRequirements,
    ModalListColumns
    },
  props: {
    showModal: { type: Boolean, required: true },
    list: { type: Object, required: true }
  },
  data () {
    return {
      listTypes: config.LIST_TYPES,
      availableRoutes: ['file', 'import'],
      fileFolder: '',
      showImportModal: false,
      showColumnRequirementModal: false,
      showListColumnsModal: false,
    }
  },
  computed: {
    activeIndex () {
      return this.availableRoutes.indexOf(this.$route.name) > -1 ?
        this.$route.name :
        this.availableRoutes[0]
    },
    updatingList () {
      return this.$store.getters['lists/updating'] || false
    }
  },
  watch: {
    list () {
      this.initColors()
    }
  },
  created: function () {
    this.initColors()
  },
  methods: {
    initColors () {
      if (this.list.file_path) {
        const pathElements = this.list.file_path.split('/')
        pathElements.splice(0, 2) // remove useless parts (client/contactly)
        this.fileFolder = pathElements.join('/')
      }
    },
    closeModal () {
      this.$emit('update:showModal', false)
    },
    checkUpdating (errorTitle, errorMsg) {
      // Check if list is updating
      return new Promise((resolve, reject) => {
        this.$store.dispatch('lists/getActiveState', this.$route.params.listId)
        .then((res) => {
          // list is updating, show error
          if (res.updating) {
            this.$notify({
              title: errorTitle,
              message: errorMsg,
              type: 'error'
            })
            this.closeModal()
          }
          resolve(res)
        })
        .catch(() => {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
          reject()
        })
        .finally(() => {
          this.loading = false
        })
      })
    },
    updateList () {
      const setupImportComp = this.$refs['setupImport']
      const $this = this
      this.checkUpdating(
        this.$t('LIST_DETAILS.EDIT_LIST.ERROR_TITLE'),
        this.$t('LIST_DETAILS.EDIT_LIST.ERROR_MSG')
      )
      .then((res) => {
        if (!res.updating) {
          // save changes
          setupImportComp.save(true)
          .then(() => {
            $this.$notify({
              title: $this.$t('MISC.SUCCESS'),
              message: $this.$t('LIST_DETAILS.EDIT_LIST.UPDATE_SUCCESS'),
              type: 'success'
            })
            $this.closeModal()
          })
          .catch((error) => {
            if (error) {
              $this.$notify({
                title: $this.$t('ERRORS.UNEXPECTED'),
                message: $this.$t('ERRORS.UNEXPECTED_DESC'),
                type: 'error'
              })
            }
            $this.closeModal()
          })
        }
      })
    },
    forceImport () {
      const $this = this
      this.checkUpdating(
        this.$t('MISC.ERROR'),
        this.$t('ERRORS.IMPORT_IN_PROGRESS')
      )
      .then((res) => {
        if (!res.updating) {
          // force list update
          $this.$store.dispatch('lists/importContacts', {
            'listId': $this.$route.params.listId,
            'data': { 'import_type_id': config.UPLOAD_TYPE.FORCED }
          })
          .then(() => {
            $this.closeModal()
          })
          .catch((err) => {
            // Notify error
            if (err.status === 409) {
              $this.$notify({
                title: $this.$t('MISC.ERROR'),
                message: $this.$t('ERRORS.IMPORT_IN_PROGRESS'),
                type: 'error'
              })
            } else {
              $this.$notify({
                title: $this.$t('ERRORS.UNEXPECTED'),
                message: $this.$t('ERRORS.UNEXPECTED_DESC'),
                type: 'error'
              })
            }
          })
          .finally(() => {
            $this.showImportModal = false
          })
        } else {
          $this.showImportModal = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
$--form-width: 450px;

::v-deep .edit-list {
  background: $--color-bg-light-gray;
  .el-dialog__header {
    padding-top: 48px;
  }
  .el-button.edit-list__column-requirements {
    text-decoration: underline;
    color: $--color-orange;
    font-size: 13px;
  }
  .edit-list__alert {
    text-align: left;
    word-break: normal;
    margin-bottom: 60px;
    margin-top: 30px;
    &.info {
      line-height: 0px;
      padding-top: 15px;
    }
  }
  .title {
    margin: 10px;
    font-size: 36px;
    font-weight: 300;
  }
  .subtitle {
    margin: 10px;
    font-size: 21px;
    font-weight: 400;
  }
  &__exit-btn {
    position: absolute;
    top: 36px;
    right: 44px;
  }

  &__content {
    font-size: 12px;
    max-width: 500px;
    margin: 0 auto;
  }
  &__tabs {
    margin: 0 auto;
    .el-tabs__header {
      text-align: center;
    }
  }
  &__setup {
    margin: 0 auto;
    .el-radio-group {
      width: 100%;
      .el-radio-button {
        width: 50%;
        .el-radio-button__inner {
          width: 100%;
        }
      }
    }
  }
}

.file-setup {
  .el-row {
    padding: 1rem;
    border-bottom: 1px solid $--color-medium-gray;
    color: $--color-gray;
  }
  &__value {
    text-align: right;
    color: $--text-dark;
  }
}
</style>
