<template>
  <el-popover
    v-model="isPopoverVisible"
    placement="top"
    width="460"
    trigger="click"
    popper-class="notifications-popover"
    @show="openPopover"
    @hide="resetPopover"
  >
    <div
      slot="reference"
      class="header-button notifications"
    >
      <el-badge :is-dot="showNotificationBadge">
        <i class="material-icons">
          notifications
        </i>
      </el-badge>
    </div>

    <div
      class="header"
      :class="{'header__empty': !notificationsList.length}"
    >
      <div class="title">
        {{ $t('NOTIFICATION.TITLE') }}
      </div>
      <el-button
        v-if="notificationsList.length"
        type="text"
        size="default"
        :loading="markAllAsReadLoading"
        @click="handleMarkAllAsRead"
      >
        {{ $t('NOTIFICATION.MARK_AS_READ') }}
      </el-button>
    </div>

    <div
      v-if="notificationsList.length"
      v-infinite-scroll="scrollFunction"
    >
      <NotificationItem
        v-for="(notif, index) in notificationsList"
        :key="index"
        class="item"
        :list-name="notif.name"
        :list-id="notif.list_id"
        :read="notif.read"
        :created-at="notif.created_at"
        :import-log-id="notif.import_log_id"
        @setAsRead="handleNotificationRead"
        @closePopover="isPopoverVisible = false"
      />
    </div>
    <div v-else>
      <NoData
        type="items"
        :text="$t('NOTIFICATION.EMPTY')"
        :height="200"
        :width="200"
      />
    </div>
  </el-popover>
</template>

<script>
  import NotificationItem from '../Parts/NotificationItem'
  import NoData from '../UI/NoData'
  import { isEqual } from 'lodash-es'
  export default {
    components: { NotificationItem, NoData },
    data () {
      return {
        isPopoverVisible: false,
        haveNotificationsBeenOpened: false,
        getNotificationsInterval: null,
        currentPage: 1,
        limit: 6,
        currentTotal: 0,
        total: 0,
        ackNewNotificationsStringify: null,
        showBadge: false,
        markAllAsReadLoading: false
      }
    },
    computed: {
      unReadNotifications () {
        return this.notificationsList.filter(n => !n.read)
      },
      noMore () {
        return this.currentTotal >= this.total
      },
      notificationsList () {
        return this.$store.getters['notifications/notificationsList'] || []
      },
      showNotificationBadge () {
        return (
          (!this.haveNotificationsBeenOpened && !!this.unReadNotifications.length) ||
          this.showBadge
        )
      }
    },
    created() {
      this.loadNewNotificationsLists()
      this.getNotificationsInterval = setInterval(() => {
        this.alertIfNewNotifications()
      }, 10000)
    },
    beforeDestroy () {
      clearInterval(this.getNotificationsInterval)
    },
    methods: {
      scrollFunction() {
        if (this.noMore) {
          return false
        }
        this.currentPage++
        this.loadNewNotificationsLists()
      },
      loadNewNotificationsLists(reset = false) {
        if (reset) {
          this.currentPage = 1
        }
        const params = {
          p: this.currentPage,
          l: this.limit
        }
        this.$store.dispatch('notifications/getNotificationsList', { params })
          .then(data => {
            this.total = data.meta.total
            this.currentTotal = this.notificationsList.length
          })
      },
      handleNotificationRead(notification) {
        if (!notification.read) {
          const { list_id, import_log_id } = notification
          this.$store.dispatch('notifications/postNotificationsList', { list_id, import_log_id })
        }
      },
      handleMarkAllAsRead() {
        this.markAllAsReadLoading = true
        this.$store.dispatch('notifications/postNotificationsList', { mark_all_as_read: true })
          .finally(() => {
            this.markAllAsReadLoading = false
          })
      },
      resetPopover() {
        this.currentTotal = 0
        this.total = 0
        this.currentPage = 1
        this.showBadge = false
        this.ackNewNotificationsStringify = null
      },
      openPopover() {
        this.haveNotificationsBeenOpened = true
        this.showBadge = false
        this.loadNewNotificationsLists(true)
      },
      alertIfNewNotifications() {
        if (this.isPopoverVisible) {
          return
        }
        const params = {
          p: 1,
          l: this.limit
        }
        const currentNotifications = this.notificationsList
        this.$store.dispatch('notifications/getNotificationsList', { params })
          .then((data) => {
            const payloadNotifications = data.objects
            const isCurrentNotificationsEmpty = !currentNotifications.length
            const isFirstPayloadNotificationInCurrentList = (
              payloadNotifications.length &&
              currentNotifications.find(n => payloadNotifications[0].import_log_id === n.import_log_id)
            )
            /**
             * show $notify if:
             * - the current notificationsList is empty and the payload got data
             * - the first payload notification is not equal to the first current notification
             *  and its import_log_id is not present in the current notificationsList
             */
            if (
              (isCurrentNotificationsEmpty && !!payloadNotifications.length) ||
              (
                !isEqual(payloadNotifications[0], currentNotifications[0]) &&
                !isFirstPayloadNotificationInCurrentList
              )
            ) {
              this.showBadge = true
              this.$notify({
                title: this.$t('MISC.WARNING'),
                message: this.$t('MISC.NEW_ERROR'),
                type: 'warning'
              })
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $--color-medium-gray;
  padding: 0 12px;
  &__empty {
    border-bottom: none;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $--color-primary;
    font-weight: bold;
  }
  ::v-deep .el-button {
    display: flex;
    align-items: center;
    :not(.el-icon-loading) {
      text-decoration: underline;
    }
    font-weight: normal;
    color: $--color-primary;
    .el-icon-loading {
      margin-right: 0;
    }
  }
}
.item:not(:last-child) {
  border-bottom: 1px solid $--color-medium-gray;
}
.notifications {
  cursor: pointer;
}
::v-deep .el-badge__content {
  background-color: red;
  border: none;
  &.is-dot {
    height: 8px;
    width: 8px;
  }
  &.is-fixed.is-dot {
    right: 9px;
    top: 5px;
  }
}
</style>
