import Vue from 'vue'
import Vuex from 'vuex'

import moduleActivities from './activities'
import moduleApps from './apps'
import moduleAuth from './auth'
import moduleClients from './clients'
import moduleContacts from './contacts'
import moduleExports from './exports'
import moduleFolders from './folders'
import moduleGroups from './groups'
import moduleLists from './lists'
import moduleRecipients from './recipients'
import moduleUsers from './users'
import moduleNotifications from './notifications'
import moduleSnapcall from './snapcall'

Vue.use(Vuex)

const storeModule = {
  apps: moduleApps,
  auth: moduleAuth,
  clients: moduleClients,
  folders: moduleFolders,
  groups: moduleGroups,
  lists: moduleLists,
  users: moduleUsers,
  activities: moduleActivities,
  exports: moduleExports,
  contacts: moduleContacts,
  recipients: moduleRecipients,
  notifications: moduleNotifications,
  snapcall: moduleSnapcall
}

// Store
const store = new Vuex.Store({
  modules: storeModule
})

export default store
