<template>
  <div
    class="header"
    :class="{'scrolled': scrolled}"
  >
    <div class="header-bar">
      <span class="header-left">
        <!-- LOGO -->
        <router-link
          to="/contacts"
          replace
        >
          <Logo
            :width="100"
            :height="36"
            :color="false"
          />
        </router-link>
      </span>

      <span class="header-middle">
        <!-- MENU -->
        <router-link
          to="/contacts"
          class="header-menu-link"
        >
          {{ $t('CONTACT_CARDS.TITLE') }}
        </router-link>

        <router-link
          :to="getRouterPath('lists')"
          class="header-menu-link"
        >
          {{ $t('CONTACT_LISTS.TITLE') }}
        </router-link>

        <router-link
          :to="getRouterPath('submission-lists')"
          class="header-menu-link"
        >
          {{ $t('SUBMISSION_LISTS.TITLE') }}
        </router-link>
      </span>

      <span class="header-right">

        <!-- SNAPCALL -->
        <SnapcallButton
          v-if="snapcallId"
          class="ml-1 mr-1"
          :is-active="showSnapcall"
          @update:is-active="launchSnapcall"
        />
        <!-- GATE SWITCH -->
        <NmpSwitch
          :clients="clients"
          :apps="apps"
          :current-switch-client="currentSwitchClient"
          :initial-client="initialClient"
          @update:currentSwitchClient="updateSwitchClient"
        />

        <!-- NOTIFICATIONS -->
        <NotificationsList />

        <!-- USER MENU -->
        <el-dropdown
          trigger="hover"
          class="header-account"
          @command="handleCommand"
        >
          <el-button
            class="header-account__button header-button el-dropdown-link"
            :class="{ 'display-client-logo': clientLogo }"
            type="text"
          >
            <img
              v-if="clientLogo"
              class="client-logo mr-2"
              :src="clientLogo"
              alt="Client logo"
            >
            <AvatarHolder
              :avatar-url="user.avatar_url || null"
              :display-name="getFullName(user)"
              :width="32"
              :height="32"
            />
          </el-button>
          <el-dropdown-menu
            slot="dropdown"
            class="header-account__menu"
          >
            <div class="header-account__menu__detail-container">
              <div class="header-account__menu__avatar-container">
                <AvatarHolder
                  :avatar-url="user.avatar_url || null"
                  :display-name="getFullName(user)"
                  :width="48"
                  :height="48"
                />
              </div>
              <div class="mb-1">
                {{ getFullName(user) }}
              </div>
              <div class="mb-1 header-account__menu__email">
                {{ user.email }}
              </div>
            </div>
            <div>
              <el-dropdown-item
                command="profile"
              >
                <i class="material-icons">perm_identity</i>
                {{ $t('BUTTON.PROFILE') }}
              </el-dropdown-item>
              <el-dropdown-item
                command="logout"
              >
                <i class="material-icons">arrow_forward</i>
                {{ $t('BUTTON.SIGN_OUT') }}
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </div>
  </div>
</template>

<script>
import { getInitials, getFullName } from '@/filters'
import Logo from './Logo'
import AvatarHolder from './AvatarHolder'
import NotificationsList from '../Containers/NotificationsList'
import { NmpSwitch, SnapcallButton } from '@team-crm-automation/nmp-components-vue2'

export default {
  name: 'AppHeader',
  components: { AvatarHolder, Logo, NotificationsList, NmpSwitch, SnapcallButton },
  props: {
    scrolled: { type: Boolean, required: true }
  },
  data() {
    return {
      currentSwitchClient: null,
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user'] || {}
    },
    client () {
      return this.$store.getters['clients/client'] || {}
    },
    clients () {
      return this.$store.getters['clients/clients'] || []
    },
    apps () {
      return this.$store.getters['apps/apps'].apps || []
    },
    initialClient () {
      return this.client
    },
    clientLogo () {
      const client = this.user.client
      if (!client || !client.configs || !client.configs.display_logo_in_app) {
        return null
      }
      return client.configs.interface_logo_url ?
        client.configs.interface_logo_url : client.logo_url
    },
    groupFilter () {
      return this.$store.getters['lists/groupFilter']
    },
    showSnapcall() {
      return this.$store.getters['snapcall/showSnapcall']
    },
    snapcallId() {
      return this.$store.getters['snapcall/snapcallId']
    }
  },
  async mounted () {
    if (this.initialClient) {
      await this.$store.dispatch('apps/getApps', this.initialClient['id'])
      this.currentSwitchClient = this.initialClient
    } 
  },
  methods: {
    handleCommand (command) {
      switch (command) {
        case 'logout':
          localStorage.removeItem('user')
          this.$router.push({ name: 'login' })
          this.$store.dispatch('auth/logout')
          break
        case 'profile':
          window.open('/profile', '_blank')
          break
      }
    },
    getRouterPath (tab) {
      const path = `/${tab}`
      if (this.groupFilter.length > 0) {
        const group = this.groupFilter.map(g => g.id).toString()
        return { path, query: { group } }
      }
      return { path }
    },
    getInitials: getInitials,
    getFullName: getFullName,
    launchSnapcall() {
      if (this.showSnapcall) {
        this.$store.dispatch('snapcall/endSnapcall')
      } else {
        this.$store.dispatch('snapcall/startSnapcall')
      }
    },
    async updateSwitchClient (client) {
      await this.$store.dispatch('apps/getApps', client ? client['id'] : null)
      this.currentSwitchClient = client
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.header {
  background-color: $--color-primary;
  margin: 0;
  padding: 0;
  height: $--header-height;
  position: fixed;
  top:0;
  left:0;
  right:0;
  color: $--color-header-text;
  z-index: 2000;
  &.scrolled {
    box-shadow: rgba($--color-black, 0.165) 0px 0px 6px 2px;
    transition: all 0.3s;
  }
  .header-bar {
    @include flexbox;
    @include align-items(center);
    padding: 0 0px;
    height: 100%;

    .header-left {
      margin-right: 10px;
    }

    .header-middle {
      flex-grow: 1;
      width: 100%;
      display: block;
    }

    .header-right {
      @include flexbox;
      @include align-items(center);
      .snapcall-button {
        min-width: 36px;
        ::v-deep i {
          margin-right: 0;
        }
      }
    }
  }
}

.header-button {
  background: transparent;
  border:0;
  outline:0;
  color: rgba($--color-white, 0.8);
  margin: 0 10px;

  &.display-client-logo {
    padding: 2px 10px 2px 5px;
    background: $--color-white;
    margin-right: 4px!important;
  }

  .client-logo {
    height: 38px;
    margin-left: -2px;
    margin-right: 12px;
    vertical-align: middle;
  }

  .avatar-holder {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
  }

  &:hover {
    color: $--color-white;
  }

  &:focus {
    color: $--color-white;
  }

  svg#sw-main-btn {
    fill: rgba($--color-white, 0.8);

    &:hover {
      fill: $--color-white;;
    }

    &:focus {
      fill: $--color-white;
    }
  }
}

.header-menu-link {
  margin: 0 20px;
  font-size: 14px;
  line-height: 32px;
  display: inline-block;
  font-weight: 600;
  color: rgba($--color-white, 0.8);
  transition: all .2s;
  text-decoration: none;
  &:hover {
    color: $--color-white;;
  }
}

.header-account__button {
  border: 0;
  background: none;
  text-decoration: none;
  padding: 0;
  height: 42px;
  min-width: unset;
  cursor: pointer;
  padding: 2px 10px;

  img {
    vertical-align: middle;
  }

  &:hover .hovered-img {
    box-shadow: 0 1px 3px 0 rgba($--color-black, 0.2),
      0 1px 1px 0 rgba($--color-black, 0.14),
      0 2px 1px -1px rgba($--color-black, 0.12);
  }
}

.header-account__menu {
  padding: 0;
  width: 240px;

  .el-dropdown-menu__item {
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-top: 1px solid $--color-dark-blue;
  }

  i {
    vertical-align: text-bottom;
    font-size: 18px;
    width: 30px;
    text-align: center;
  }

  .header-account__menu__detail-container {
    padding: 20px 10px 20px;
    text-align: center;
    font-size: 14px;
  }

  .header-account__menu__avatar-container {
    width: 48px;
    height: 48px;
    margin: 0 auto 10px;
  }

  .header-account__menu__email {
    color: $--color-gray;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .header .header-bar {
    .header-left {
      width: auto;
      flex-grow: 1;
      text-align: center;
      padding-left: 70px;
    }

    .header-middle {
      display: none;
    }

    .header-right {
      width: 70px;

      .gate-switch {
        display: none;
      }
    }
  }
}
</style>
