<template>
  <img
    :src="logoUrl"
    :style="styles"
    alt="Contactly logo"
  >
</template>

<script>
import colorLogo from '@/assets/img/color-logo.svg'
import whiteLogo from '@/assets/img/white-logo.svg'

export default {
  name: 'Logo',
  /**
   * Props the parent can use to manipulate this component.
   * Note: Components themselves should not mutate their own props.
   */
  props: {
    color: { type: Boolean, default: true },
    width: { type: [Number, null], default: null },
    height: { type: [Number, null], default: null },
    maxWidth: { type: [Number, null], default: 100 },
    maxHeight: { type: [Number, null], default: 36 },
    margin: { type: String, default: "10px" }
  },
  computed: {
    logoUrl () {
      return this.color ? colorLogo : whiteLogo
    },
    styles () {
      let css = ''
      let custom = false
      if (this.width) {
        css += `width: ${this.width}px;`
      }
      if (this.height) {
        css += `height: ${this.height}px;`
      }
      if (this.maxWidth) {
        css += `max-width: ${this.maxWidth}px;`
      }
      if (this.maxHeight) {
        css += `max-height: ${this.maxHeight}px;`
      }
      if (this.margin) {
        css += `margin: ${this.margin};`
      }
      if (this.width || this.height || this.margin) {
        custom = true
      }
      if (!custom) {
        css += 'max-width:200px;'
      }
      return css
    }
  }
}
</script>

<style lang="scss">

</style>
