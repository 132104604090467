// Load vue relative library
import Vue from 'vue'
import VueAxios from 'vue-axios'

// Load extern library
import axios from 'axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import tzData from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min.js'
import vueMoment from 'vue-moment'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

// Load intern component
import App from './App'
import store from './store'
import router from './router'
import i18n from './languages/i18n'
import '../theme/index.css'
import './assets/styles/app.scss'

window.moment = tzData

Vue.use(VueAxios, axios)
Vue.use(ElementUI, { locale })
Vue.use(Donut)

Vue.config.productionTip = false

const moment = require('moment')
Vue.use(vueMoment, {
  moment
})

// Interceptor to redirect on standard error, and intercept and store
// the token returned by the back-end
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    const redirectUrl = error.response.headers.redirect
    if (redirectUrl) {
      store.dispatch('auth/clearSession')
      window.location = redirectUrl
    }
  }
  return Promise.reject(error.response ? error.response : error)
})

// scroll to top when route changes
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

/* eslint-disable no-console */
console.info(`Version:%c ${process.env.VERSION}`, 'color: #454AD4;')
/* eslint-enable no-console */

if (process.env.SENTRY_ENV){
  const currentHost = window.location.hostname.split(".")

  if (currentHost.length == 4 && currentHost[1] == 'contactly') {
    currentHost.splice(0, 1)
    Sentry.init({
      Vue,
      dsn: 'https://236a66ca1ac9494fb9538cde0771d388@sentry.numberly.net/414',
      environment: process.env.SENTRY_ENV,
      release: process.env.VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [currentHost.join('.')],
        }),
      ],
      tracesSampleRate: 1.0,
    })
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
