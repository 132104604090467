import Vue from 'vue'
import Router from 'vue-router'
import * as Sentry from '@sentry/vue'

import {
  ContainerMain,
  ContainerHome,
  ContainerList,
  ContainerContact,
  HomeLists,
  HomeFolders,
  HomeSubmissionLists,
  HomeContactCards,
  Imports,
  Admin,
  ListRows,
  ListActivity,
  ContactContactability,
  ContactCampaigns,
  ContactData,
  ListExports
} from '@/components'

import store from '@/store'
import i18n from '@/languages/i18n'

Vue.use(Router)

const guardRoute = async function (to, from, next) {
  await store.dispatch('auth/getProfile')
    .then((res) => {
      if (res.data && res.data.lang) {
        i18n.locale = res.data.lang
        Vue.moment.locale(i18n.locale)
      }
      const promises = [
        store.dispatch('groups/getGroups'),
        store.dispatch('users/getUsers'),
        store.dispatch('clients/getClients')
      ]
      Promise.all(promises.map(p => p.catch(e => e))).then((res) => {
        const errors = res.filter(result => result.status && result.status >= 400)
        if (errors.length > 0) {
          Sentry.captureException(errors[0])
          window.location = '/502.html'
        }
        document.body.setAttribute("loaded", 1)
        next()
      })
    })
    .catch((err) => {
      if (err.status === 401) {
        // Call /login endpoint of lamapi to re-initiate the Oauth flow
        return window.location.href = '/login'
      }
      if (err.status === 403) {
        const redirectUrl = '/logout'
        store.dispatch('auth/clearSession')
        window.location = redirectUrl
      }
    })
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: ContainerMain,
      beforeEnter: guardRoute,
      children: [
        {
          path: '',
          component: ContainerHome,
          children: [{
            name: 'lists',
            path: '/lists',
            component: HomeLists
          },{
            name: 'folders',
            path: '/folders',
            component: HomeFolders
          },{
            name: 'submission-lists',
            path: '/submission-lists',
            component: HomeSubmissionLists
          },{
            name: 'contacts',
            path: '',
            alias: '/contacts',
            component: HomeContactCards
          }]
        },
        {
          name: 'lists-details',
          path: '/lists/:listId/details',
          component: ContainerList,
          children: [{
            name: 'rows',
            path: '/lists/:listId/details',
            component: ListRows,
            props: true
          },{
            name: 'activity',
            path: '/lists/:listId/activity',
            component: ListActivity
          },{
            name: 'exports',
            path: '/lists/:listId/exports',
            component: ListExports
          }]
        },
        {
          name: 'contacts-details',
          path: '/contacts/:channel/:contactId',
          component: ContainerContact,
          children: [{
            name: 'contactability',
            path: '/contacts/:channel/:contactId',
            component: ContactContactability
          },{
            name: 'data',
            path: '/contacts/:channel/:contactId/data',
            component: ContactData
          },{
            name: 'campaigns',
            path: '/contacts/:channel/:contactId/campaigns',
            component: ContactCampaigns
          }]
        },
        {
          name: 'imports',
          path: '/imports',
          component: Imports
        },
        {
          name: 'admin',
          path: '/admin',
          component: Admin
        }
      ]
    },
    // default route when none of the above matches
    {
      path: '*',
      redirect: '/contacts'
    }
  ]
})

// Reset filters on page change
router.beforeEach((to, from, next) => {

  const viewNames = ['submission-lists', 'lists']
  const routeNameFrom = from.name
  const routeNameTo = to.name
  const ToDetailPage = to.path && to.path.split('/').indexOf('details') !== -1
  const FromDetailPage = from.path && from.path.split('/').indexOf('details') !== -1
  const shouldResetFilters = !ToDetailPage && !FromDetailPage && viewNames.includes(routeNameFrom) && routeNameFrom !== routeNameTo

  if (shouldResetFilters) {
    store.dispatch('lists/resetFilters')
  }
  next()
})
export default router
