<!-- eslint-disable vue/no-v-html -->
<template>
  <el-dialog
    :visible="dialogVisible"
    :width="getWidth"
    :append-to-body="true"
    custom-class="dialog-modal"
    :show-close="true"
    @close="closeModal"
  >
    <div
      slot="title"
      class="dialog-modal__title"
    >
      <img
        :src="openBookLogo"
        alt="open-book-logo"
      >
      <div>
        {{ title }}
      </div>
    </div>
    <div class="dialog-modal__information">
      <!-- File pattern section -->
      <section
        v-for="(section, index) in sections"
        :key="index"
      >
        <h5 v-if="sections.length > 1">
          {{ section.title }}
        </h5>
        <p v-html="section.content" />
      </section>
      <el-alert
        v-if="tip"
        :title="tip"
        type="info"
        show-icon
        :closable="false"
      />
    </div>
  </el-dialog>
</template>

<script>
import openBook from '@/assets/img/open-book.svg'

export default {
  name: 'NeedHelpModal',
  props: {
    dialogVisible: { type: Boolean, default: false },
    width: { type: String, default: '30%'},
    title: { type: String, default: '', required: true },
    sections: { type: Array, default: () => [], required: true  },
    tip: { type: String, default: '', required: false }
  },
  data() {
    return {
      openBookLogo: openBook
    }
  },
  computed: {
    getWidth () {
      return this.width ? this.width : '30%'
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
::v-deep .dialog-modal {
  padding: 24px 24px;
  &__title {
    text-align: center;
    color: $--color-primary;
    font-weight: 600;
    svg {
      margin: 12px 0px;
    }
  }
  &__information {
    word-break: break-word;

    section + section {
      margin-top: 30px;
    }

    ul, .el-alert {
      margin-top: 30px;
      text-align: justify;
    }

    ul li {
      margin: 10px 0;
    }
  }
}
</style>
