<template>
  <router-link
    tag="div"
    :to="{ path: `/lists/${item.id}/details` }"
    class="simple-list-item"
    :class="{'simple-list-item--first': index === 0}"
  >
    <span class="simple-list-item__group">
      <el-tooltip
        effect="dark"
        :content="item.group.name"
        placement="top"
      >
        <AvatarHolder
          :avatar-url="item.group.logo_url || null"
          :display-name="item.group.name"
          :width="25"
          :height="25"
          :font-size="8"
        />
      </el-tooltip>
    </span>
    <span class="simple-list-item__info">
      <p>{{ item.name }}</p>
      <ListType :type-id="item.type_id" />
      <span class="text-tertiary">
        <i class="material-icons">people_outline</i>
        {{ item.contacts_count || 0 }}
      </span>
    </span>
    <span class="simple-list-item__arrow">
      <i class="material-icons">keyboard_arrow_right</i>
    </span>
  </router-link>
</template>

<script>
import { AvatarHolder, ListType } from '..'

export default {
  name: 'HomeListItem',
  components: { AvatarHolder, ListType },
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true }
  },
  data () {
    return { }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.simple-list-item {
  @include flexbox;
  border-top: 1px solid $--border-color-base;
  padding-top: 10px;
  margin-top: 10px;
  cursor: pointer;

  &.simple-list-item--first {
    margin-top: 0;
    padding-top: 0;
    border: 0;
  }

  .simple-list-item__group {
    padding: 10px;
  }

  .simple-list-item__info {
    display: inline-flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    font-size: 11px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;

    > p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-size: 12px;
      margin: 0 0 10px 0;
    }

    & > span {
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
    }

    ::v-deep i.material-icons {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .simple-list-item__arrow > i.material-icons {
    color: $--color-text-placeholder;
    margin-top: 50%;
  }
}
</style>
