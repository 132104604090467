<template>
  <div
    ref="setup-columns-list"
    class="setup-columns-list"
  >
    <div
      v-for="field in fields"
      :key="field.id"
      class="setup-columns-list__columns"
    >
      <SetupColumns
        :ref="'setup-field-' + field.id"
        :field="field"
        :values="values"
        @formChange="formChange"
      />
    </div>
  </div>
</template>

<script>
import {
  SetupColumns
} from '..'
export default {
  name: 'SetupColumnsList',
  components: { SetupColumns },
  props: {
    fields: { type: Array, required: true },
    listId: { type: Number, required: true },
    formChange: { type: Function, required: true }
  },
  data () {
    return {
      values: null
    }
  },
  created () {
    this.$emit('loading', true)
    this.$store.dispatch('lists/previewS3File', this.listId)
    .then((data) => {
      this.$emit('loading', false)
      this.values = data
    })
    .catch(() => {
      this.values = []
      this.$notify({
        title: this.$t('ERRORS.UNEXPECTED'),
        message: this.$t('ERRORS.UNEXPECTED_DESC'),
        type: 'error'
      })
    })
  },
  mounted () {
    this.$refs['setup-columns-list'].addEventListener('mousewheel', this.scrollHorizontally, false)
  },
  methods: {
    scrollHorizontally(e) {
        e = window.event || e
        var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)))
        this.$refs['setup-columns-list'].scrollLeft -= (delta*40) // Multiplied by 40
        e.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.setup-columns-list {
  width: inherit;
  padding: 12px 0px;
  // padding-right: 12px;
  overflow-x: auto;
  white-space: nowrap;
  display: inline-flex;
  background-color: $--color-light-gray;

  &__columns {
    padding: 0 8px;
  }

  &__columns:first-child {
    padding-left: 16px;
  }

  &__columns:last-child {
    padding-right: 16px;
  }
}
</style>
