<template>
  <div class="filters-bar">
    <div class="filters-bar__modifiers">
      <div
        ref="filters"
        class="filters-bar__modifiers__left"
        :class="{ 'show-all': showAll }"
      >
        <slot />
        <el-button
          v-if="!disableMoreFilter"
          type="text"
          class="mr-2 ml-1"
          @click="showAll = !showAll"
        >
          <span v-if="!showAll">{{ $t("FILTER.MORE_FILTERS") }}</span>
          <span v-else>{{ $t("FILTER.LESS_FILTERS") }}</span>
        </el-button>
      </div>
      <div class="filters-bar__modifiers__right">
        <slot name="rightSide" />
        <el-input
          v-if="searchEnabled"
          class="filter-search-custom"
          :value="search"
          :placeholder="searchPlaceholder"
          clearable
          @input="(e) => $emit('update-search', e)"
        >
          <i
            slot="prefix"
            class="material-icons md-24"
          >
            search
          </i>
        </el-input>
      </div>
    </div>
    <div
      v-if="selectedFilters.length > 0"
      class="filters-bar__tags"
    >
      <FilterTag
        v-for="(opt, index) in selectedFilters"
        :key="index"
        :label="opt.tag[0].name"
        :color="opt.color"
        @delete="$emit('unselect-filter', opt)"
      />
      <el-button
        type="text"
        @click="$emit('unselect-all')"
      >
        {{ $t('FILTER.CLEAR') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { FilterTag } from '@/components/Common/Form'

export default {
  name: 'Filters',
  components: {
    FilterTag
  },
  props: {
    selectedFilters: {
      type: Array,
      default: () => []
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: ""
    },
    searchPlaceholder: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      showAll: false,
      disableMoreFilter: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      // Disable expand button when less than 3 filter
      const filters = this.$refs['filters'].querySelectorAll('.filter-cascade')
      this.disableMoreFilter = filters.length <= 1

      // Empty options fall back text for cascader filters (Element UI no slots)
      const allPoppers = document.querySelectorAll('.el-cascader__dropdown')
      allPoppers.forEach(popper => {
        const emptyTextArea = popper.querySelector('.el-cascader-menu__empty-text')
        if (!emptyTextArea) return {}
        emptyTextArea.setAttribute('style', 'width: 100%;')
        emptyTextArea.innerHTML = this.$t('FILTER.NO_MATCHING_DATA')
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.filters-bar {
  .el-button--text {
    padding: 0;
    height: 26px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: $--color-info;
    &:hover {
      text-decoration: underline;
      color: $--color-info-light
    }
  }
  .filters-bar__modifiers {
    display: flex;
    justify-content: space-between;
  }
  .filters-bar__modifiers__left {
    display: flex;
    flex-wrap: wrap;
    .el-button {
      display: none;
    }
    .filter {
      margin: 0 10px 15px 0;
      &.date {
        .el-date-editor {
          border-radius: 2px;
        }
      }
    }
  }
  .filters-bar__modifiers__right {
    display: flex;
    margin-bottom: 15px;
    .el-dropdown {
      display: flex;
      cursor: pointer;
      background-color: $--color-white;
      border-radius: 2px;
      border: 1px solid $--color-medium-gray;
      color: $--color-text-regular;
      margin-left: 4px;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      .icon {
        font-size: 11px;
      }
    }
    ::v-deep .filter-search-custom {
      .el-input__inner {
        padding-right: 5px
      }
    }

    .filter {
      margin-left: 10px;
    }
  }
  .filters-bar__tags {
    display: flex;
    flex-wrap: wrap;
    .tag {
      margin: 0 10px 10px 0;
    }
  }

  @media only screen and (max-width: $--mobile-small-width) {

    .filters-bar__modifiers {
      display: flex;
      flex-direction: column;
    }

    .filters-bar__modifiers__left {
      position: relative;
      margin: auto;
      width: 300px;
      .filter {
        margin: 0 auto 15px auto;
      }
      &:not(.show-all)  {
        .filter:first-child {
          margin-left: calc((100% - 250px)/2);
        }
        .filter:nth-child(n + 2) {
          display: none;
        }
      }
      &.show-all  {
        .filter:first-child {
          margin: 0 calc((100% - 250px)/2) 15px;
        }
      }
      .el-button {
        position: absolute;
        top: 0px;
        right: calc((100% - 250px)/2);
        display: block;
        color: $--color-info;
        height: 40px;
        padding: 0;
        &:hover {
          text-decoration: underline;
          color: $--color-info-light
        }
      }
    }
    .filters-bar__modifiers__right {
      ::v-deep .filter-search-custom {
        margin: 0 5px 5px;
        .el-input__inner {
          width: 100%;
          padding-right: 5px
        }
      }
      .filter {
        margin-left: 10px;
      }
    }
  }
}
</style>
