<template>
  <div class="edit-columns">
    <!-- # ITEM ELEMENT # -->
    <div class="edit-columns__subtitle">
      {{ $t('LIST_DETAILS.EDIT_COLUMNS.SUBTITLE') }}
    </div>
    <draggable
      :list="fields"
      class="list-group"
      ghost-class="ghost"
      :move="changeOrder"
      v-bind="dragOptions"
      handle=".btn.drag"
      @start="dragging = true"
      @end="dragging = false"
    >
      <div
        v-for="column in fields"
        :key="column.id"
        class="edit-columns__item"
        :class="{ 'is-primary': column.primary_key }"
      >
        <div
          :ref="`column-${column.id}`"
          class="edit-columns__content"
          :class="{ 'is-primary': column.primary_key, 'is-hidden': !column.displayed }"
        >
          <div class="edit-columns__item-header">
            <!-- # NAME OF COLUMN # -->
            <div
              class="edit-columns__item-cell name"
              @click="toggleItem(column)"
            >
              {{ column.name_in_file }}
              <span
                v-show="column.optin_type_id"
                class="edit-columns__item-cell__contactability"
              >
                {{ displayContactabilityField(column) }}
              </span>
            </div>
            <!-- # PRIMARY STATUS # -->
            <div
              v-show="column.primary_key"
              class="edit-columns__item-cell primary"
              @click="toggleItem(column)"
            >
              <i class="el-icon-key" />
            </div>
            <!-- # TYPE OF ITEM # -->
            <div
              class="edit-columns__item-cell type"
              @click="toggleItem(column)"
            >
              {{ $t(dataTypes[column.datatype_id - 1].label) }}
            </div>
            <!-- # VISIBLE STATUS # -->
            <div class="edit-columns__item-cell visible">
              <button
                class="btn"
                :disabled="column.primary_key"
                @click="toggleItemVisible(column)"
              >
                <i
                  class="material-icons"
                  :class="{ 'is-hidden': !column.displayed }"
                >
                  remove_red_eye
                </i>
              </button>
            </div>
            <!-- # OPEN ITEM # -->
            <div class="edit-columns__item-cell btn">
              <button
                class="btn arrow"
                @click="toggleItem(column)"
              >
                <i class="el-icon-arrow-down" />
              </button>
            </div>
          </div>
          <div class="edit-columns__item-body">
            <el-row>
              <el-col :span="12">
                <el-checkbox
                  v-model="column.primary_key"
                  disabled
                >
                  {{ $t('FIELDS.PRIMARY_KEY') }}
                </el-checkbox>
                <el-checkbox
                  v-model="column.mandatory"
                  disabled
                >
                  {{ $t('FIELDS.MANDATORY') }}
                </el-checkbox>
              </el-col>
              <el-col
                v-if="column.optin_type_id"
                :span="12"
              >
                <div>{{ $t('FIELDS.CONTACT_FIELD') }} : {{ column.name_in_file }}</div>
                <div>
                  {{ $t('FIELDS.OPTIN') }} :
                  <span>{{ column.optin_type_value }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- # DRAGGABLE BUTTON # -->
        <el-button
          type="text"
          class="btn drag"
        >
          <i class="material-icons">drag_handle</i>
        </el-button>
      </div>
    </draggable>
    <div class="edit-columns__footer text-center">
      <el-button
        type="primary"
        size="medium"
        :loading="loading"
        @click="submit"
      >
        {{ $t('LIST_DETAILS.EDIT_LIST.APPLY') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import { orderBy, cloneDeep, find } from 'lodash-es'
import draggable from 'vuedraggable'

export default {
  name: 'EditColumnsPanel',
  components: { draggable },
  props: {
    list: { type: Object, required: true },
    visible: { type: Boolean, required: true }
  },
  data () {
    return {
      optinTypes: config.CHANNELS,
      dataTypes: config.REF_DATA_TYPES,
      loading: false,
      dragging: false,
      nbPrimaryKeys: 0,
      fields: null
    }
  },
  computed: {
    optins () {
      return this.$store.getters['groups/optins'] || {}
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // deep clone fields
      this.fields = cloneDeep(this.list.fields)
      // Count how many primary keys
      for (let i = 0; i < this.fields.length; i++) {
        const field = this.fields[i]
        if (field.primary_key) {
          this.nbPrimaryKeys++
        }
        // Get the optin type string value
        if (field.optin_type_id) {
          const optin = find(this.optins, function(o) {
            return o.id === field.optin_type_id
          })
          field.optin_type_value = optin.name
        }
      }
      this.orderFields()
    },
    displayContactabilityField(column) {
      return this.$t('SETUP.FIELDS.CONTACTABILITY_FIELD', {field: column.name_in_db.toLowerCase()})
    },
    orderFields () {
      this.fields = orderBy(this.fields, ['primary_key', 'displayed_order'], ['desc', 'asc'])
    },
    toggleItem (item) {
      var domId = 'column-' + item.id
      var domClass = this.$refs[domId][0].classList
      if (domClass.contains('is-opened')) {
        domClass.remove('is-opened')
      } else {
        domClass.add('is-opened')
      }
      this.$refs[domId][0].classList = domClass
    },
    toggleItemVisible (item) {
      item.displayed = item.displayed ? false : true
    },
    changeOrder (item) {
      let currentItemIndex = item.draggedContext.index
      let futureIndex = item.draggedContext.futureIndex
      let currentItem = this.fields[currentItemIndex]
      if (currentItem.primary_key) {
        // Cannot change primary order
        return false
      } else {
        if (futureIndex < this.nbPrimaryKeys) {
          return false
        } else {
          currentItem.displayed_order = futureIndex
        }
      }
    },
    submit () {
      this.loading = true
      // Loop every fields and add display order
      for (let i = 0; i < this.fields.length; i++) {
        const field = this.fields[i]
        field.displayed_order = i
      }
      let data = {
        'listId': this.list.id,
        'data': { 'fields': this.fields }
      }
      this.$store.dispatch('lists/patchList', data)
      .then(() => {
        this.orderFields()
        this.$emit('update:visible', false)
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.edit-columns {
  position: relative;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 120px;
  padding: 0 5px 120px 20px;
  .list-group {
    margin-bottom: 2rem;
  }
  &__subtitle {
    color: $--color-gray;
    margin: 2rem 0;
    font-size: 12px;
  }
  &__footer {
    background: $--color-white;
    padding: 2rem;
    position: fixed;
    bottom: 0;
    width: 430px;
    z-index: 10;
  }
  &__item {
    @include flexbox;
    @include align-items(baseline);
    .btn.drag {
      @include rotate(90);
      color: $--color-medium-gray;
      top: 5px;
      position: relative;
      padding: 0 5px;
    }
    &.is-primary {
      .btn {
        visibility: hidden;
      }
    }
    &.ghost {
      visibility: hidden;
    }
  }
  &__content {
    @include flex(1);
    font-size: 12px;
    background-color: $--color-white;
    border: 1px solid $--color-medium-gray;
    border-radius: 3px;
    margin-bottom: 15px;
    max-width: 409px;
    .edit-columns__item-body {
      overflow: hidden;
      max-height: 0;
      -webkit-transition: max-height 0.5s; /* Safari prior 6.1 */
      transition: max-height 0.5s;
      > div {
        padding: 15px;
        border-top: 1px solid $--color-medium-gray;
      }
    }
    &.is-hidden {
      background-color: $--color-light-gray;
    }
    &.is-primary {
      background-color: lighten($--color-tertiary, 49%);
      border: 1px solid lighten($--color-tertiary, 40%);
    }
    &.is-opened {
      .edit-columns__item-header {
        .el-icon-arrow-down::before {
          @include rotate(180);
          display: block;
        }
      }
      .edit-columns__item-body {
        display: block;
        max-height: 200px;
      }
    }
  }
  &__item-header {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    cursor: pointer;
  }
  &__item-body {
    ::v-deep .el-checkbox {
      &.is-disabled {
        .el-checkbox__label {
          color: $--text-dark;
          font-size: 12px;
        }
      }
    }
    ::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
      background: darken($--color-medium-gray, 15);
      width: 15px;
      height: 15px;
      border-radius: 4px;
      &::after {
        border-color: $--color-white;
      }
    }
  }
  &__item-cell {
    font-size: 12px;
    &__contactability {
        color: $--color-text-secondary;
    }
    &.name {
      @include flex(1);
      padding: 12px 10px;
      overflow: hidden;
      word-break: break-all;
      ::v-deep .el-input {
        &__inner {
          border-radius: 4px;
          &:disabled {
            background: red;
            border: 0;
            background: transparent;
            color: $--text-dark;
            font-weight: 600;
          }
        }
      }
    }
    &.visible {
      padding: 0;
      .is-hidden {
        position: relative;
        opacity: 0.3;
        &:after {
          @include transform(rotate(-50deg) scale(1.5));
          content: '|';
          font-weight: normal;
          display: block;
          position: absolute;
          top: 2px;
          left: 0;
          width: 100%;
          height: 100%;
          font-weight: normal;
        }
      }
      .btn {
        i {
          font-weight: normal;
          font-size: 1.2rem;
          padding-top: 5px;
        }
      }
    }
    &.primary {
      padding: 0 10px;
      i {
        @include rotate(90);
        color: $--color-tertiary;
        font-size: 1.3rem;
      }
    }
    &.type {
      width: 80px;
      padding: 0 5px;
      text-transform: uppercase;
    }
    &.drag-icon {
      @include rotate(90)
    }
    &.btn {
      padding: 0
    }
    .btn {
      width: 50px;
      height: 40px;
      cursor: pointer;
      background: transparent;
      padding: 0;
      border: 0;
      &:focus {
        outline: 0;
      }
      &.arrow {
        color: $--color-gray;
        i {
          font-weight: bold;
          font-size: 1rem;
        }
      }
      &.drag {
        @include rotate(90)
      }
    }
  }
}

  /* For mobile: */
@media only screen and (max-width: $--mobile-width) {
  .edit-columns {
    &__footer {
      width: 90%;
      padding: 2rem 0;
    }
  }
}
</style>
