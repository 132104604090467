<template>
  <div>
    <!-- Optins section -->
    <ContactabilityTable
      :expandable="true"
      :columns="optinsColumns"
      :data="optins"
      :total="optinsTotal"
      :loading="optinsLoading"
      :channel="defaultFilters.channel"
      :placeholder="$t('CONTACT_DETAILS.CONTACTABILITY.OPTINS_PLACEHOLDER')"
      :title="$t('CONTACT_DETAILS.CONTACTABILITY.OPTINS')"
      :subtitle="$t('CONTACT_DETAILS.CONTACTABILITY.OPTINS_SUBTITLE')"
      :no-data-text="$t('CONTACT_DETAILS.CONTACTABILITY.NO_OPTIN_DATA')"
      @refresh-data="refreshOptins"
    />

    <ContactabilityTable
      :columns="listsColumns"
      :data="lists"
      :total="listsTotal"
      :loading="listsLoading"
      :placeholder="$t('CONTACT_DETAILS.CONTACTABILITY.LISTS_PLACEHOLDER')"
      :title="$t('CONTACT_DETAILS.CONTACTABILITY.LISTS')"
      :subtitle="$t('CONTACT_DETAILS.CONTACTABILITY.LISTS_SUBTITLE')"
      :no-data-text="$t('CONTACT_DETAILS.CONTACTABILITY.NO_LIST_DATA')"
      @refresh-data="getLists"
    />
  </div>
</template>

<script>
import { ContactabilityTable } from '@/components'
export default {
  name: 'ContactContactability',
  components: { ContactabilityTable },
    props: {
      groupId: { type: String, default: null },
  },
  data() {
    return {
      optins: [],
      optinsTotal: 0,
      lists: [],
      listsTotal: 0,
      optinsColumns:[
        { column: 'name', width: 400, label: 'OPTIN_NAME', sortable: true },
        { column: 'is_optin', width: 120, label: 'IS_OPTIN' , sortable: true },
        { column: 'update_date', width: 150, label: 'ACTION_DATE', sortable: true },
        { column: 'unsubscribe_actions', width: 110 }
      ],
      listsColumns: [
        { column: 'group', width: 50 },
        { column: 'name', width: 250, label: 'LIST_NAME', sortable: true },
        { column: 'type_id', width: 130, label: 'LIST_TYPE', sortable: true },
        { column: 'optin_name', width: 200, label: 'OPTIN_NAME', sortable: true },
        { column: 'is_optin', width: 120, label: 'IS_OPTIN', sortable: true },
        { column: 'update_date', width: 150, label: 'ACTION_DATE', sortable: true },
        { column: 'go_to_list' , width: 150}
      ],
      defaultFilters: {
        page: 1,
        limit: 10,
        orderBy: 'name'
      }
    }
  },
  computed: {
    optinsLoading: function () {
      return this.$store.getters['contacts/optinsLoading']
    },
    listsLoading: function () {
      return this.$store.getters['contacts/listsLoading']
    },
  },
  watch: {
    groupId () {
      this.getOptins(this.defaultFilters)
      this.getLists(this.defaultFilters)
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getOptins(this.defaultFilters)
      this.getLists(this.defaultFilters)
    },
    refreshOptins (params) {
      this.getOptins(params)
      this.getLists(this.defaultFilters)
    },
    getOptins (params) {
      params.channel = this.$route.params.channel
      params.id = this.$route.params.contactId
      params.groupId = this.groupId

      this.$store.dispatch('contacts/getOptins', params)
      .then((res) => {
        this.optinsTotal = res.meta.total
        this.optins = res.objects
      })
      .catch(err => {
        if (err.status === 500) {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        }
      })
    },
    getLists (params) {
      params.channel = this.$route.params.channel
      params.id = this.$route.params.contactId
      params.groupId = this.groupId
      this.$store.dispatch('contacts/getLists', params)
        .then((res) => {
          this.listsTotal = res.meta.total
          this.lists = res.objects
        })
        .catch(err => {
          if (err.status === 500) {
            this.$notify({
              title: this.$t('ERRORS.UNEXPECTED'),
              message: this.$t('ERRORS.UNEXPECTED_DESC'),
              type: 'error'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
</style>
