<template>
  <div
    :class="{ 'is-primary': field.primary_key}"
    class="setup-columns"
  >
    <div class="setup-columns__fields-desc">
      <div
        v-show="field.primary_key"
        class="pull-right setup-columns__fields-desc__primary_key"
      >
        <img
          :src="keyLogo"
          alt="primary-key"
        >
      </div>
      <div class="setup-columns__fields-desc__title">
        <el-tooltip
          effect="dark"
          :content="field.name_in_db"
          placement="top"
        >
          <div class="setup-columns__fields-desc__title__name">
            {{ field.name_in_db }}
          </div>
        </el-tooltip>
        <el-tooltip
          v-if="field.name_in_db == 'COUNTRY_CODE'"
          effect="dark"
          :content="$t('SETUP.FIELDS.COUNTRY_CODE_CONFIG_INFO')"
          placement="top"
        >
          <i class="material-icons help">help_outline</i>
        </el-tooltip>
      </div>

      <el-form
        ref="setup-form"
        :model="field"
        :rules="rules"
        :hide-required-asterisk="true"
        class="setup-columns__fields-desc__form"
      >
        <el-form-item
          prop="datatype_id"
          size="small"
        >
          <el-cascader
            v-model="datatypeField"
            class="setup-columns__fields-desc__form__select-datatype"
            size="small"
            :disabled="lockedFields.indexOf(field.name_in_db) > -1"
            :options="datatypeOptions"
            @change="formChange('datatype_id')"
          />
        </el-form-item>
        <el-form-item
          prop="primary_key"
          size="small"
        >
          <el-checkbox
            v-model="field.primary_key"
            class="setup-columns__fields-desc__form__checkbox"
            :disabled="field.name_in_db == 'COUNTRY_CODE'"
            @change="updateMandatory"
          >
            {{ $t('FIELDS.PRIMARY_KEY') }}
          </el-checkbox>
        </el-form-item>
        <el-form-item
          prop="mandatory"
          size="small"
        >
          <el-tooltip
            effect="dark"
            :content="$t('FIELDS.MANDATORY_TOOLTIP_INFO')"
            placement="bottom"
            :disabled="!field.primary_key"
          >
            <el-checkbox
              v-model="field.mandatory"
              :disabled="field.primary_key || field.name_in_db == 'COUNTRY_CODE'"
              class="setup-columns__fields-desc__form__checkbox"
              @change="formChange"
            >
              {{ $t('FIELDS.MANDATORY') }}
            </el-checkbox>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </div>
    <div
      v-loading="values === null"
      class="setup-columns__fields-values"
      :class="{ 'is-primary': field.primary_key}"
    >
      <div v-if="fieldValues && fieldValues.length > 0">
        <div
          v-for="(val, index) in fieldValues"
          :key="index"
        >
          {{ val }}
        </div>
      </div>
      <div
        v-else
        class="setup-columns__fields-values__empty"
      >
        {{ $t('FIELDS.NO_VALUES') }}
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import keyLogo from '@/assets/img/key.svg'

export default {
  name: 'SetupColumns',
  components: { },
  props: {
    field: { type: Object, required: true },
    values: {
      required: true,
      validator: prop => prop === null || Array.isArray(prop)
    }
  },
  data () {
    return {
      datatypes: config.REF_DATA_TYPES,
      keyLogo: keyLogo,
      rules: {
        datatype_id: [
          { required: true, message: this.$t('ERRORS.FORM_FIELD_REQUIRED') }
        ]
      },
      lockedFields: ['EMAIL', 'MOBILE', 'COUNTRY_CODE'],
      datatypeField: [],
      datatypeOptions: []
    }
  },
  computed: {
    fieldValues: function () {
      var values = []
      if (this.field && this.values) {
        values = this.values.map((value)  => { return value[this.field.name_in_file] })
        values = values.filter((val) => { return val.length > 0 })
      }
      return values
    }
  },
  created () {
    this.datatypeOptions = []
    var optObj = null
    config.REF_DATA_TYPES.forEach(option => {
      optObj = Object.assign({}, option)
      optObj['label'] = this.$t(option['label'])
      this.datatypeOptions.push(optObj)
    })
    this.datatypeField = [this.field.datatype_id]
    if (this.field.value_format != null) {
      this.datatypeField.push(this.field.value_format)
    }
  },
  methods: {
    validate: function () {
      return new Promise ((resolve) => {
        this.$refs['setup-form'].validate((valid) => {
          return resolve(valid)
        })
      })
    },
    updateMandatory: function () {
      if (this.field.primary_key) {
        this.field.mandatory = true
      }
      this.formChange()
    },
    formChange: function (property) {
      this.$emit('formChange', this.field)
      if (property && property == 'datatype_id') {
        this.field.datatype_id = this.datatypeField[0]
        this.field.value_format = this.datatypeField[1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

$--columns-width: 240px;
$--columns-height: 320px;
$--description-height: 210px;
$--values-height: 86px;
.setup-columns {
  width: $--columns-width;
  height: $--columns-height;

  border: 1px solid $--color-medium-gray;
  border-radius: 3px;
  background-color: $--color-white;

  &.is-primary {
    border-color: lighten($--color-tertiary, 25%);
    background-color: lighten($--color-tertiary, 48%);
  }

  &__fields-desc {
    padding: 12px 12px 0px 12px;
    border-bottom: 1px solid $--color-medium-gray;
    height:$--description-height;
    &__title {
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      width: 150px;
      margin: 18px auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &__name {
        display:inline-block;
      }
      .material-icons.help {
        font-size: 1rem;
        position: relative;
        top: 4px;
        margin-left: 5px;
        color: $--color-gray;
      }
    }
    ::v-deep .el-form-item {
      margin-bottom: 0px;
    }

    &__form {
      width: 200px;
      margin: 24px auto 12px auto;

      &__select-datatype {
        margin-bottom: 18px;
      }
      &__checkbox {
        ::v-deep .el-checkbox__label {
          font-size: 12px;
          color: $--color-dark-gray;
          font-weight: 400;
        }
      }
    }
  }
  &__fields-values {
    position: relative;
    height:$--values-height;
    margin: 12px 19px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.is-primary {
      ::v-deep .el-loading-mask {
        background-color: lighten($--color-tertiary, 48%);
      }
    }
    &__empty {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%,-50%);
      font-size: 12px;
      color: $--color-gray;
    }
  }

}
</style>
